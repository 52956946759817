/** @format */
import httpClient from "fetchers";

export const responseEnabledUserModules = token =>
  httpClient(
    "UiConfiguration/GetEnabledUserModules",
    true,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(response => response.json())
    .catch(error => console.log(error));
