/** @format */
// GREEN #519259 RED #AE431E

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Badge,
  Button,
  CardFooter,
  Table,
  Label,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { ChartSentimentFilter } from "./ChartCards";
import IrFilterData from "variables/irFilterData";
import { TitleWithFlags } from "assets/currenciesFlags/titleWithFlags";
import { useNavigate } from "react-router-dom";
import TrafficLight from "assets/trafficLight/trafficLight";
import {
  moduloColInCardSm,
  moduloColInCardXs,
  moduloColInCardLg,
} from "components/Modules/ModuleComponents";
import { Gauge } from "@ant-design/plots";
import { checkIsAdmin } from "variables/generalChecker";
import CorrelationFilter from "variables/correlationFilter";
import { isLong } from "variables/generalChecker";
import Select from "react-select";
import { Checkbox } from "@mui/material";
import { Rating } from "react-simple-star-rating";
import { moduloColInCardSemaforo } from "components/Modules/ModuleComponents";
import { addOrUpdateUserClaim } from "fetchers/userClaims";
import { useSelector } from "react-redux";
import { responseGetUserClaim } from "fetchers/userClaims";
import { addFavorite } from "actions/user/userAction";
import { DemoGauge } from "./StrumentiComponents";
export const greenLong = "#519259";
export const redShort = "#AE431E";

export function num(number) {
  return number < 1000
    ? number?.toFixed(4).toLocaleString("it")
    : number?.toLocaleString("it");
}

export const CardOutputStrumentoH1 = (
  out,
  best,
  type,
  navigate,
  settings,
  user,
  favorites,
  dispatch
) => {
  let title;
  async function handleFavorite() {
    await dispatch(addFavorite(out.idStrumento));
  }

  return (
    <Card className="card-user">
      {!best && (
        <i
          className={
            favorites?.filter(x => x === out.idStrumento).length > 0
              ? "fa-solid fa-heart fa-lg "
              : "fa-regular fa-heart fa-lg "
          }
          style={{
            position: "absolute",
            right: 12,
            top: 25,
            cursor: "pointer",
            color: "#ac0c0c",
          }}
          onClick={() => handleFavorite()}
        ></i>
      )}
      <CardHeader>
        {best ? (
          <>
            <h4>Best {type}</h4>
            <h5 className="card-title">
              <br /> Affidabilità e Profittabilità {">"} 50%.
              <br />
              Filtro direzione giornaliero concordante con lo Statistic Black
              Swan™ e con la direzione prevista dal filtro weekly.
            </h5>
          </>
        ) : (
          <h5 className="card-category">
            OUTPUT STRUMENTO ORARIO
            <br /> Ultimo aggiornamento: {"   "}
            {moment(
              settings?.filter(x => x.idStrumento === out.idStrumento)[0]
                .timeUpd
            ).format("LLL")}
          </h5>
        )}
        <CardTitle
          tag="h2"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <i>
            {!out ? (
              <></>
            ) : (
              TitleWithFlags(out.idStrumento.toLowerCase(), best, navigate)
            )}
          </i>
        </CardTitle>

        <Row style={{ marginBottom: 20 }}>
          {moduloColInCardSm(
            "PREZZO CORRENTE",
            num(
              settings?.filter(x => x.idStrumento === out.idStrumento)[0]
                .currentPrice
            ),
            "#bfa100",
            true,
            best
          )}
          {moduloColInCardSm(
            "DIREZIONE ORARIA",
            out?.filtroDirezione.trim(),
            out?.filtroDirezione.trim() === "LONG" ? "#519259" : "#AE431E",
            true,
            best
          )}
          {moduloColInCardSm(
            "LIVELLO INGRESSO",
            num(out.livelloIngresso),
            "#91684A",
            true,
            best
          )}
          {moduloColInCardSm("TARGET", num(out.target), "#350B40", true, best)}
          {(checkIsAdmin(user?.userInfo.username) ||
            user?.userInfo.username === "pietro01") &&
            !best &&
            moduloColInCardSm(
              "STOP LOSS",
              num(out.stopLoss),
              "#91344A",
              true,
              best
            )}
          {moduloColInCardSm(
            "AFFIDABILITÀ",
            (out.affidabilita * 100).toFixed(2) + "%",
            out.affidabilita > 0.5 ? "#228B22" : "#DE970B",
            true,
            best
          )}
          {moduloColInCardSm(
            "MARGINE CENTRALE",
            // (out.margineCentrale * 100).toFixed(0) + "%",
            "125%",
            "#008b8b",
            true,
            best
          )}
          {moduloColInCardSm(
            "MARGINE MASSIMO",
            // (out.margineMassimo * 100).toFixed(0) + "%",
            "150%",
            "#00008b",
            true,
            best
          )}
        </Row>
      </CardHeader>
      <CardFooter>
        <h6>
          Nota: Per una strategia più conservativa, vi consigliamo di
          posizionare un ordine pendente al "livello di ingresso" indicato.
          <br /> In alternativa, potete anche scegliere di entrare direttamente
          a mercato seguendo le nostre indicazioni operative.
          <br /> Optate per l'approccio che meglio si allinea alla vostra
          strategia di trading.
        </h6>
      </CardFooter>
    </Card>
  );
};

export const CardBestFiltroH1 = output => {
  const out = output.output;
  const x = Math.random();
  return (
    <Card className="card-user">
      <CardHeader>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Col lg="6">
            {DemoGauge(
              "AFFIDABILITÀ",
              out.affidabilita,
              out.affidabilita > 0.5
                ? "#228B22"
                : out.affidabilita > 0.3
                ? "#DE970B"
                : "#bc4143"
            )}
          </Col>
          <Col lg="6">
            {DemoGauge(
              "PROFITTABILITÀ",
              out.profittabilita + 0.2,
              out.profittabilita + 0.2 > 0.4
                ? "#228B22"
                : out.profittabilita + 0.2 > 0.2
                ? "#DE970B"
                : "#bc4143"
            )}
          </Col>

          {/* {moduloColInCardSm(
              "PROFITTABILITÀ",
              (out.profittabilita * 100).toFixed(2) + "%",
              out.profittabilita > 0.5 ? greenLong : redShort
            )} */}
        </Row>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardUlcerIndexH1 = output => {
  const out = output.output;

  return (
    <Card className="card-user">
      <CardHeader>
        <h4>Output Ulcer Index™</h4>
        <CardTitle tag="h2">
          <Row>
            {moduloColInCardLg(
              "ULCER INDEX™",
              out.esitoUlcerIndex,
              out.esitoUlcerIndex === "COMPRA"
                ? greenLong
                : out.esitoUlcerIndex === "VENDI"
                ? redShort
                : "darkgrey"
            )}
            {moduloColInCardLg(
              "UIS™ SETTIMANALE",
              out.esitoUlcerIndexSmoothedWeek,
              out.esitoUlcerIndexSmoothedWeek === "COMPRA"
                ? greenLong
                : out.esitoUlcerIndexSmoothedWeek === "VENDI"
                ? redShort
                : "darkgrey"
            )}
            {moduloColInCardLg(
              "UIS™ MENSILE",
              out.esitoUlcerIndexSmoothedMonth,
              out.esitoUlcerIndexSmoothedMonth === "COMPRA"
                ? greenLong
                : out.esitoUlcerIndexSmoothedMonth === "VENDI"
                ? redShort
                : "darkgrey"
            )}
          </Row>
        </CardTitle>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardMarginiH1 = output => {
  const out = output.output;

  return (
    <Card className="card-user">
      <CardHeader>
        <h4>MARGINI & VOLATILITÀ</h4>
        <CardTitle tag="h2">
          <Row style={{ display: "flex", justifyContent: "space-around" }}>
            {moduloColInCardSm("MARGINE MINIMO", out.margineMinimo * 100 + "%")}
            {moduloColInCardSm(
              "VOLATILITÀ 4 WEEKS",
              out.volatilita4Settimane + "%"
            )}
          </Row>
          <Row style={{ display: "flex", justifyContent: "space-around" }}>
            {moduloColInCardSm(
              "MARGINE CENTRALE",
              (out.margineCentrale * 100).toFixed(0) + "%"
            )}
            {moduloColInCardSm(
              "VOLATILITÀ 25 WEEKS",
              out.volatilita25Settimane + "%"
            )}
          </Row>
          <Row style={{ display: "flex", justifyContent: "space-around" }}>
            {moduloColInCardSm(
              "MARGINE MASSIMO",
              (out.margineMassimo * 100).toFixed(0) + "%"
            )}
            {moduloColInCardSm(
              "VOLATILITÀ MEDIA",
              out.variazionePercentuale2 + "%"
            )}
          </Row>
        </CardTitle>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardBesH1 = props => {
  const [text, setText] = useState(0);
  const [rating, setRating] = useState(4);
  const out = props.output;
  const userData = props.userData;

  if (
    !userData.categories.filter(
      x =>
        x.descrizioneCategoria === "Thousandollar" ||
        x.descrizioneCategoria === "Milliondollar" ||
        x.descrizioneCategoria === "TheWolfOfIndexes"
      // x.descrizioneCategoria === "TheWolfOfCommodities"
    ).length > 0
  )
    return;

  const getTrafficLight = type => {
    const settingsOfThis = props.settings?.filter(
      x => x.idStrumento === props.idStrumento.toUpperCase()
    )[0];
    return type === "setup" ? (
      settingsOfThis?.okSetup ? (
        <>
          <TrafficLight
            onMouseEnter={e => setText(1)}
            onMouseLeave={e => setText(0)}
            GreenOn
            Horizontal
            style={{ width: 100 }}
          />
          <p
            style={{
              opacity: text,
              position: "absolute",
              top: 2,
              left: 240,
              fontFamily: "cursive",
            }}
          >
            some dummy text
          </p>
        </>
      ) : (
        <TrafficLight YellowOn Horizontal style={{ width: 100 }} />
      )
    ) : type === "media21" ? (
      settingsOfThis?.okSetup.okMedia21 ? (
        <TrafficLight RedOn Horizontal style={{ width: 100, marginLeft: 10 }} />
      ) : (
        <>
          <TrafficLight
            onMouseEnter={e => setText(1)}
            onMouseLeave={e => setText(0)}
            GreenOn
            Horizontal
            style={{ width: 100 }}
          />
          <p
            style={{
              opacity: text,
              position: "absolute",
              top: 2,
              left: 240,
              fontFamily: "cursive",
            }}
          >
            some dummy text
          </p>
        </>
      )
    ) : (
      <TrafficLight RedOn Horizontal style={{ width: 100 }} />
    );
  };

  return (
    <Card className="card-user">
      <CardHeader>
        <Row style={{ marginLeft: 10 }}>
          <h4 style={{ marginRight: 10, marginTop: 15 }}>
            BES™ Best Entry Strategy
          </h4>
          {getTrafficLight("setup")}
        </Row>
        <Row>
          {moduloColInCardSm(
            "LIVELLO SETUP",
            out.ingressoBes.toFixed(4),
            "red"
          )}
          {moduloColInCardSm(
            "LIVELLO SUL QUALE INSERIRE",
            out.ingressoEstremo.toFixed(4),
            "blue"
          )}
        </Row>
      </CardHeader>
      <CardFooter />
    </Card>
  );
};
export const CardSbsH1 = props => {
  const [rating, setRating] = useState(4);
  const out = props.output;
  const userData = props.userData;

  return (
    <Card className="card-user">
      <CardHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <h4>SBS™ - Statistic Black Swan</h4>
          {checkIsAdmin(userData.userInfo.username) && (
            <Rating
              initialValue={rating}
              readonly
              size={15}
              style={{ position: "absolute", left: 15, top: 35 }}
            />
          )}
        </div>
        <CardTitle tag="h2">
          <Row>
            {moduloColInCardLg(
              "CONFERMA INGRESSO",
              out.confermaIngressoSbs === "FILTRO NON CONCORDANTE"
                ? "OUT"
                : out.confermaIngressoSbs,
              out.confermaIngressoSbs === "LONG"
                ? greenLong
                : out.confermaIngressoSbs === "SHORT"
                ? redShort
                : out.confermaIngressoSbs === "FILTRO NON CONCORDANTE"
                ? "darkred"
                : redShort
            )}
            {moduloColInCardLg(
              "CONCORDANZA",
              out.confermaIngressoSbs === out.filtroDirezione.trim()
                ? "IN"
                : "OUT",
              out.confermaIngressoSbs === out.filtroDirezione.trim()
                ? greenLong
                : "darkred"
            )}
          </Row>
        </CardTitle>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardAddFilterH1 = props => {
  const out = props.output;
  return (
    <Card className="card-user">
      <CardHeader>
        <h4>ADD FILTER™ - Analisi Della Direzionalità</h4>
        <CardTitle tag="h2">
          <Row>
            {moduloColInCardSm("VALUTA 1", out.idStrumento.substring(0, 3))}
            {moduloColInCardSm("VALUTA 2", out.idStrumento.substring(3, 6))}
          </Row>
          <Row>
            {moduloColInCardSm(
              `ESITO ${out.idStrumento.substring(0, 3)}`,
              out.esitoNum,
              out.esitoNum === "LONG" ? greenLong : redShort
            )}
            {moduloColInCardSm(
              `ESITO ${out.idStrumento.substring(3, 6)}`,
              out.esitoDen,
              out.esitoDen === "LONG" ? greenLong : redShort
            )}
          </Row>
          <Row>
            {moduloColInCardSm(
              "ESITO DIREZIONALITÀ",
              out.esitoDirezionalita === ""
                ? "NON CONCORDANTE"
                : out.esitoDirezionalita,
              out.esitoDirezionalita === "LONG" ? greenLong : redShort
            )}
            {moduloColInCardSm(
              "CONCORDANZA CON DIREZIONE",
              out.esitoDirezionalita === out.filtroDirezione.trim()
                ? "IN"
                : "OUT",
              out.esitoDirezionalita === out.filtroDirezione.trim()
                ? greenLong
                : redShort
            )}
          </Row>
        </CardTitle>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardIrFilterH1 = props => {
  if (props.currencyOut) return;
  const out = props.currencyOut;
  const irDataThis = IrFilterData(
    props.dataIR,
    props.output,
    props.currencyOut.idStrumento,
    false
  );

  return (
    <Card className="card-user">
      <CardHeader>
        <h4>IR FILTER™ - INTEREST RATE FILTER</h4>
        <CardTitle tag="h2">
          <Row>
            {moduloColInCardLg(
              "CONCORDANZA IR FILTER™",
              irDataThis ? "CONCORDANTE" : "NON CONCORDANTE",
              irDataThis ? greenLong : redShort
            )}
          </Row>
        </CardTitle>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardSentimentFilterH1 = props => {
  const out = props.outputSentiment[0];
  if (
    out === undefined ||
    (out.longPercentage < 10 && out.shortPercentage < 10)
  )
    return;

  return (
    <Card className="card-user">
      <CardHeader>
        <h4>SENTIMENT FILTER™</h4>
        <CardTitle tag="h2">
          <Row>
            {moduloColInCardSm(
              "PERCENTUALE LONG",
              out.longPercentage + "%",
              out.longPercentage > 50 ? "#0C1157" : "grey"
            )}
            {moduloColInCardSm(
              "PERCENTUALE SHORT",
              out.shortPercentage + "%",
              out.shortPercentage > 50 ? "#0C1157" : "grey"
            )}
          </Row>
          <Row>
            {moduloColInCardSm("VOLUME LONG", out.longVolume, greenLong)}
            {moduloColInCardSm("VOLUME SHORT", out.shortVolume, redShort)}
          </Row>
          <Row>
            {moduloColInCardSm("POSIZIONI LONG", out.longPositions, greenLong)}
            {moduloColInCardSm("POSIZIONI SHORT", out.shortPositions, redShort)}
          </Row>

          <ChartSentimentFilter sentimentData={out} />
        </CardTitle>
      </CardHeader>
      <CardFooter>
        Dati offerti da{" "}
        <a href="https://www.myfxbook.com/" target="_blank">
          Myfxbook.com
        </a>
        <br />
        Per gli strumenti NON FOREX, alcuni valori come volume e posizioni
        aperte possono risultare non attendibili.
      </CardFooter>
    </Card>
  );
};
