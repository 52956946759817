/**
 * /*!
 *
 * =========================================================
 * Black Dashboard React v1.2.1
 * =========================================================
 *
 * Product Page: https://www.creative-tim.com/product/black-dashboard-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)
 * Licensed under MIT (https://github.com/creativetimofficial/black-dashboard-react/blob/master/LICENSE.md)
 *
 * Coded by Creative Tim
 *
 * =========================================================
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * @format
 */

/*eslint-disable*/
import React from "react";
import { NavLink, Link, useLocation, Outlet } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";

// reactstrap components
import {
  Button,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavLink as ReactstrapNavLink,
  Row,
} from "reactstrap";
import {
  BackgroundColorContext,
  backgroundColors,
} from "contexts/BackgroundColorContext";
import routes2 from "routes2";
import { currenciesFlags } from "assets/currenciesFlags/currenciesFlags";
import { useSelector } from "react-redux";
import {
  okGoldMiner,
  okDax,
  okCurrencies,
  okOilExtractor,
} from "variables/activeTools";
import { okIndex } from "variables/activeTools";
import { okGas } from "variables/activeTools";
import { okSilver } from "variables/activeTools";
import { okNasdaq } from "variables/activeTools";
import { okDowJones } from "variables/activeTools";
import { okSp500 } from "variables/activeTools";
import { okCommodities } from "variables/activeTools";
import { checkFromIdStrumentoCommodities } from "variables/generalChecker";
import { sidebarHeader } from "variables/generalChecker";
import { routesCurrencies } from "variables/generalChecker";
import { sidebarEtf } from "variables/generalChecker";
import { checkFromIdStrumentoEtf } from "variables/generalChecker";
import { okAntipirla } from "variables/activeTools";
import { okAntipirla5x } from "variables/activeTools";
import { okAntipirla10x } from "variables/activeTools";
import { okRoiProp } from "variables/activeTools";
import { ThemeContext, themes } from "contexts/ThemeContext";
import { checkEsitiSidebar } from "variables/generalChecker";
import { okRoiDiscovery } from "variables/activeTools";
import { okH1 } from "variables/activeTools";
import { okD1 } from "variables/activeTools";
import { okRoiScalpingForex } from "variables/activeTools";
import { okRoiScalpingCommodities } from "variables/activeTools";
import { okRoiScalpingIndexes } from "variables/activeTools";
import { checkFromIdStrumentoCommoditiesH1 } from "variables/generalChecker";
import { checkFromIdStrumentoIndexes } from "variables/generalChecker";
import { checkFromIdStrumentoIndexesH1 } from "variables/generalChecker";
import { okPrintMoney } from "variables/activeTools";

var ps;

function Sidebar(props) {
  const location = useLocation();
  const sidebarRef = React.useRef(null);
  const userData = useSelector(state => state.user);
  const output = useSelector(state => state.output);

  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(sidebarRef.current, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (pathLayout, pathName) => {
    return location.pathname === pathLayout + "/" + pathName ? "active" : "";
  };
  const navItem = (key, prop, hasDoubleFlag, isIndex) => {
    return (
      <li className={activeRoute(prop.layout, prop.path)} key={key}>
        <NavLink
          to={prop.layout + "/" + prop.path}
          className="nav-link"
          activeclassname={
            prop.layout + "/" + prop.path === location.pathname ? "active" : ""
          }
          onClick={props.toggleSidebar}
          style={{
            display: "flex",
            opacity: 30,
          }}
        >
          <Row
            style={{
              marginLeft: 2,
            }}
          >
            {hasDoubleFlag ? (
              <i>{currenciesFlags(prop.path)}</i>
            ) : (
              <img
                alt="MT4"
                src={require(`assets/img/${prop.path}${
                  isIndex ? "_etf" : ""
                }.png`)}
                width={25}
                height={25}
              />
            )}
            <p
              style={{
                fontSize: 16,
                marginLeft: !hasDoubleFlag && 14,
                marginTop: hasDoubleFlag && 10,
              }}
            >
              {prop.name}
            </p>
          </Row>
        </NavLink>
      </li>
    );
  };
  return (
    <ThemeContext.Consumer>
      {({ changeTheme, theme }) => (
        <BackgroundColorContext.Consumer>
          {({ color }) => (
            <div
              className="sidebar"
              data={color}
              style={{ marginTop: window.outerWidth < 991 ? 0 : 120 }}
            >
              <div className="sidebar-wrapper" ref={sidebarRef}>
                {/** SIDEBAR HEADERS */}
                <Nav>
                  {sidebarHeader(userData, output)
                    .filter(x => okRoiProp(userData) || x.path !== "roiprop")
                    .filter(
                      x => okPrintMoney(userData) || x.path !== "printmoney"
                    )
                    .filter(
                      x => okRoiDiscovery(userData) || x.path !== "audiocorsi"
                    )
                    .filter(
                      x => okD1(output) || okH1(output) || x.path !== "esiti"
                    )
                    .map((prop, key) => {
                      return (
                        <li
                          className={
                            activeRoute(prop.path) +
                            (prop.pro ? " active-pro" : "")
                          }
                          key={key}
                        >
                          <NavLink
                            to={prop.layout + "/" + prop.path}
                            className="nav-link"
                            activeclassname="active"
                            onClick={props.toggleSidebar}
                            style={{ display: "flex" }}
                          >
                            <img
                              alt="MT4"
                              src={require(`assets/img/${
                                prop.path === "storebacktesth1"
                                  ? "storebacktest"
                                  : prop.path
                              }.png`)}
                              width={prop.path === "roiprop" ? 30 : 25}
                              height={prop.path === "roiprop" ? 30 : 25}
                            />
                            <p
                              style={{
                                marginLeft: prop.path === "roiprop" ? 15 : 20,
                                fontSize: 16,
                              }}
                            >
                              {prop.name}
                            </p>
                          </NavLink>
                        </li>
                      );
                    })}
                </Nav>

                {/** FAVORITES */}

                <Nav>
                  {routes2
                    .filter(x => x.path === "admin")[0]
                    .children.filter(x => x.path == "strumento")[0]
                    .children.filter(
                      x => props.favorites?.filter(y => y === x.name).length > 0
                    )
                    .map((prop, key) => {
                      if (prop.redirect) return null;
                      return (
                        <div key={prop + key}>
                          {key === 0 ? (
                            <li
                              className="nav-link"
                              style={{
                                marginTop: 10,
                                color: theme === "" ? "darkgrey" : "#012222",
                                display: "flex",
                                backgroundColor:
                                  theme === "" ? "#012222" : "lightgrey",
                                justifyContent: "center",
                                fontWeight: "bold",
                              }}
                            >
                              PREFERITI
                            </li>
                          ) : (
                            <></>
                          )}
                          {navItem(key, prop, true, false)}
                        </div>
                      );
                    })}
                </Nav>

                {/* INDEXES */}
                {okIndex(userData) ||
                okDax(userData) ||
                okRoiScalpingIndexes(userData) ? (
                  <li
                    className="nav-link"
                    style={{
                      marginTop: 10,
                      color: theme === "" ? "darkgrey" : "#012222",
                      display: "flex",
                      backgroundColor: theme === "" ? "#012222" : "lightgrey",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    INDEXES
                  </li>
                ) : (
                  <></>
                )}
                <Nav>
                  {routes2
                    .filter(x => x.path === "admin")[0]
                    .children.filter(x => x.path == "strumento")[0]
                    .children.filter(x => x.type === "index")
                    .filter(
                      x =>
                        checkFromIdStrumentoIndexes(x.path, userData) ||
                        checkFromIdStrumentoIndexesH1(x.path, userData)
                    )
                    .map((prop, key) => {
                      if (prop.redirect) return null;
                      return navItem(key, prop);
                    })}
                </Nav>
                {/* COMMODITIES */}
                {okCommodities(userData) ||
                okGoldMiner(userData) ||
                okOilExtractor(userData) ||
                okGas(userData) ||
                okSilver(userData) ||
                okRoiScalpingCommodities(userData) ? (
                  <li
                    className="nav-link"
                    style={{
                      marginTop: 10,
                      color: theme === "" ? "darkgrey" : "#012222",
                      display: "flex",
                      backgroundColor: theme === "" ? "#012222" : "lightgrey",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    COMMODITIES
                  </li>
                ) : (
                  <></>
                )}
                <Nav>
                  {routes2
                    .filter(x => x.path === "admin")[0]
                    .children.filter(x => x.path == "strumento")[0]
                    .children.filter(
                      x =>
                        checkFromIdStrumentoCommodities(x.path, userData) ||
                        checkFromIdStrumentoCommoditiesH1(x.path, userData)
                    )
                    .map((prop, key) => {
                      if (prop.redirect) return null;
                      return navItem(key, prop, false, false);
                    })}
                </Nav>
                {/* CURRENCIES */}
                {!okCurrencies(userData) && !okRoiScalpingForex(userData) ? (
                  <></>
                ) : (
                  <li
                    className="nav-link"
                    style={{
                      marginTop: 10,
                      color: theme === "" ? "darkgrey" : "#012222",
                      display: "flex",
                      backgroundColor: theme === "" ? "#012222" : "lightgrey",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    CURRENCIES
                  </li>
                )}
                <Nav>
                  {routesCurrencies(userData).map((prop, key) => {
                    if (prop.redirect) return null;
                    return navItem(key, prop, true, false);
                  })}
                </Nav>
                {/* ETF */}
                {/** ETF */}
                {okAntipirla(userData) ||
                okAntipirla5x(userData) ||
                okAntipirla10x(userData) ? (
                  <li
                    className="nav-link"
                    style={{
                      marginTop: 10,
                      color: theme === "" ? "darkgrey" : "#012222",
                      display: "flex",
                      backgroundColor: theme === "" ? "#012222" : "lightgrey",
                      justifyContent: "center",
                      fontWeight: "bold",
                    }}
                  >
                    ETF
                  </li>
                ) : (
                  <></>
                )}
                {/* <Nav onClick={() => setEtfOpen(etfOpen)}>
                  {sidebarEtf(userData)
                    .filter(x => checkFromIdStrumentoEtf(x.path, userData))
                    ?.map((prop, key) => {
                      return navItem(key, prop, false, true);
                    })}
                </Nav> */}
              </div>
            </div>
          )}
        </BackgroundColorContext.Consumer>
      )}
    </ThemeContext.Consumer>
  );
}

Sidebar.propTypes = {
  // if true, then instead of the routes[i].name, routes[i].rtlName will be rendered
  // insde the links of this component
  rtlActive: PropTypes.bool,
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the text of the logo
    text: PropTypes.node,
    // the image src of the logo
    imgSrc: PropTypes.string,
  }),
};

export default Sidebar;
