/** @format */

// ##############################
// // // Chart variables
// #############################

// #########################################
// // // used inside src/views/Dashboard.js
// #########################################

// #########################################
// // // used inside src/views/Strumenti-Dashboard
// #########################################

const chartMonths = props => {
  return {
    labels: [
      "GENNAIO",
      "FEBBRAIO",
      "MARZO",
      "APRILE",
      "MAGGIO",
      "GIUGNO",
      "LUGLIO",
      "AGOSTO",
      "SETTEMBRE",
      "OTTOBRE",
      "NOVEMBRE",
      "DICEMBRE",
    ],
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    },
    datasets: [
      {
        label: "Prezzo",
        fill: true,
        borderColor: "#52ACE4",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "white",
        pointBorderColor: "white",
        pointHoverBackgroundColor: "#00d6b4",
        pointBorderWidth: 0.5,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 2,
        data: props.monthValues.map(x => x.value),
      },
    ],
  };
};

const chartYears = props => {
  return {
    labels: props.yearValues.map(x => x.year),
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    },
    datasets: [
      {
        label: "Prezzo",
        fill: true,
        borderColor: "#52ACE4",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "white",
        pointBorderColor: "white",
        pointHoverBackgroundColor: "#00d6b4",
        pointBorderWidth: 0.5,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 2.5,
        data: props.yearValues.map(x => x.value),
      },
    ],
  };
};

const chartDays = props => {
  return {
    labels: props.dayValues.map(x => x.day),
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    },
    datasets: [
      {
        label: "Prezzo",
        fill: true,
        borderColor: "#52ACE4",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "white",
        pointBorderColor: "white",
        pointHoverBackgroundColor: "#00d6b4",
        pointBorderWidth: 0.5,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 2.5,
        data: props.dayValues.map(x => x.value),
      },
    ],
  };
};

const chartHours = props => {
  return {
    labels: props.map(x => x.hour + ":00"),
    options: {
      maintainAspectRatio: false,
      legend: {
        display: false,
      },
    },
    datasets: [
      {
        label: "Prezzo",
        fill: true,
        borderColor: "#52ACE4",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "white",
        pointBorderColor: "white",
        pointHoverBackgroundColor: "#00d6b4",
        pointBorderWidth: 0.5,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 2.5,
        data: props.map(x => x.currentPrice),
      },
    ],
  };
};

const chartCorrelation = res => {
  return {
    labels: [""],
    options: {
      scales: {
        y: {
          ticks: {
            // min: entry > target ? target - target / 10 : entry - entry / 10,
            // max: entry > target ? entry + entry / 10 : target + target / 10,
            min: 0,
            max: 100,
            // stepSize: 0.001,
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
      },
    },

    datasets: [
      {
        label: res.esito,
        fill: true,
        borderColor: "#0C1157",
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "#0C1157",
        pointBorderColor: "#0C1157",
        pointHoverBackgroundColor: "#00d6b4",
        pointBorderWidth: 0.5,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 5,
        pointRadius: 2.5,
        data: [res.coefficiente * 100],
      },
    ],
  };
};

const graficoATortaVideocorsi = color => {
  return {
    labels: ["Già visto", "Da guardare"],
    datasets: [
      {
        label: "# of Votes",
        data: [70, 30],
        backgroundColor: ["#bf9843", "#63571c"],
        borderColor: ["white", "white"],
        borderWidth: 1,
      },
    ],
  };
};

module.exports = {
  chartDays,
  chartMonths,
  chartYears,
  chartHours,
  graficoATortaVideocorsi,
  chartCorrelation,
};
