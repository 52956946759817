/** @format */

import { combineReducers } from "redux";
import { setDataCharts } from "./dataCharts/reducerDataChart";
import { setLoginValuesReducer } from "./login/reducerLogin";
import { setFinancialOutput } from "./output";
import { setSignupValuesReducer } from "./signup";
import { setTokenFromCookieReducer } from "./token";
import { setUserInfo } from "./user/userReducer";

const rootreducer = combineReducers({
  login: setLoginValuesReducer,
  signup: setSignupValuesReducer,
  token: setTokenFromCookieReducer,
  output: setFinancialOutput,
  user: setUserInfo,
  dataCharts: setDataCharts,
});

export default rootreducer;
