/** @format */

import { RoiPropCardLinkFacebook } from "components/RoiPropComponents/RoiPropComponents";
import { RoiPropCardLinkExpert } from "components/RoiPropComponents/RoiPropComponents";
import { RoiProp2 } from "components/RoiPropComponents/RoiPropComponents";
import { RoiPropCardLinkTelegram } from "components/RoiPropComponents/RoiPropComponents";
import { RoiProp3 } from "components/RoiPropComponents/RoiPropComponents";
import { RoiProp1 } from "components/RoiPropComponents/RoiPropComponents";
import { RoiPropHeader } from "components/RoiPropComponents/RoiPropComponents";
import { RoiPropCardLinkMasterClass } from "components/RoiPropComponents/RoiPropComponents";
import React from "react";
import { useSelector } from "react-redux";
import { displayPropImage } from "variables/generalChecker";

// reactstrap components
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from "reactstrap";
import { RoiPropCardLinkIndicatori } from "components/RoiPropComponents/RoiPropComponents";
import { okRoiPropDiamond } from "variables/activeTools";
import { okRoiPropLessons } from "variables/activeTools";

export default function RoiProp() {
  const user = useSelector(state => state.user);
  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12" md="8">
            <RoiPropHeader />
            {/* <RoiPropCardLinkMasterClass user={user} /> */}
          </Col>
          <Col xs="12" md="4">
            <RoiPropCardLinkFacebook />
            <RoiPropCardLinkExpert />
            <RoiPropCardLinkTelegram />
            {okRoiPropDiamond(user) || okRoiPropLessons(user) ? (
              <RoiPropCardLinkIndicatori />
            ) : (
              <></>
            )}
          </Col>
          <Col xs="12">
            <RoiProp1 />
            <RoiProp2 />
            <RoiProp3 />
          </Col>
        </Row>
      </div>
    </>
  );
}
