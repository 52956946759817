/** @format */

import { useSelector } from "react-redux";
import { ReactComponent as Arrow } from "assets/img/arrow_curved.svg";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import ReactCardFlip from "react-card-flip";
import { useState } from "react";
import { ThemeContext } from "contexts/ThemeContext";
import { useNavigate } from "react-router-dom";
import {
  greenLong,
  redShort,
} from "components/StrumentiComponents/StrumentiComponents";
import { moduloColInCardSm } from "components/Modules/ModuleComponents";
import { num } from "components/StrumentiComponents/StrumentiComponents";
import { moduloColInCardXs } from "components/Modules/ModuleComponents";

export const StrumentoFrontCard = props => {
  const out = props.output;
  const [isFlipped, setIsFlipped] = useState(false);

  function handleClick() {
    setIsFlipped(!isFlipped);
  }

  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
      {FrontCard(handleClick, out)}
      {BackCard(handleClick, out)}
    </ReactCardFlip>
  );
};

export const FrontCard = (handleClick, out) => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardHeader>
        <CardTitle
          className="h3"
          onClick={() =>
            navigate(`/admin/strumento/${out.idStrumento.toLowerCase()}`)
          }
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          {out.idStrumento}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          {moduloColInCardSm(
            "Direzione daily",
            out.filtroDirezione,
            out.filtroDirezione === "LONG" ? greenLong : redShort,
            true,
            false,
            false
          )}

          {moduloColInCardSm(
            "Livello ingresso",
            out.livelloIngresso.toFixed(4),
            "#91684A",
            true,
            false,
            false
          )}

          {moduloColInCardSm(
            "Target",
            out.target.toFixed(4),
            "#350B40",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "LIVELLO SETUP",
            num(out.ingressoBes),
            "darkRed",
            true
          )}
          {moduloColInCardSm(
            "Affidabilità",
            (out.affidabilita * 100).toFixed(2) + "%",
            out.affidabilita > 0.5 ? "#228B22" : "#DE970B",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "ROI atteso",
            (out.roi * 100).toFixed(2) + "%",
            "#E6BD1A",
            true,
            false,
            false
          )}
        </Row>
        <img
          alt=">"
          src={require("assets/img/triangle_gold.png")}
          onClick={handleClick}
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: 20,
            height: 20,
            rotate: "-90deg",
            cursor: "pointer",
          }}
        />
      </CardBody>
      <CardFooter />
    </Card>
  );
};
export const BackCard = (handleClick, out) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader>
        <CardTitle
          className="h3"
          onClick={() =>
            navigate(`/admin/strumento/${out.idStrumento.toLowerCase()}`)
          }
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          {out.idStrumento}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          {moduloColInCardSm(
            "Direzione daily",
            out.filtroDirezione,
            out.filtroDirezione === "LONG" ? greenLong : redShort,
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Direzione weekly",
            out.esitoUlcerIndexW1,
            out.esitoUlcerIndexW1 === "COMPRA" ? greenLong : redShort,
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Statistic Black Swan",
            out.confermaIngressoSbs,
            out.confermaIngressoSbs === "LONG" ? greenLong : redShort,
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Conferma ingresso D/W",
            out.esitoUlcerIndexW1 === "VENDI" && out.filtroDirezione === "SHORT"
              ? "IN"
              : out.esitoUlcerIndexW1 === "COMPRA" &&
                out.filtroDirezione === "LONG"
              ? "IN"
              : "OUT",
            out.esitoUlcerIndexW1 === "VENDI" && out.filtroDirezione === "SHORT"
              ? redShort
              : out.esitoUlcerIndexW1 === "COMPRA" &&
                out.filtroDirezione === "LONG"
              ? greenLong
              : "red",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Affidabilità",
            (out.affidabilita * 100).toFixed(2) + "%",
            out.affidabilita > 0.5 ? "#228B22" : "#DE970B",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Profittabilità",
            (out.profittabilita * 100).toFixed(2) + "%",
            out.profittabilita > 0.5 ? "#228B22" : "#DE970B",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "ROI atteso",
            (out.roi * 100).toFixed(2) + "%",
            "#E6BD1A",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Livello ingresso medio",
            out.livelloIngresso.toFixed(4),
            "#91684A",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Livello ingresso intraday",
            out.livelloIngresso.toFixed(4),
            "#bfa100",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Leva massima",
            out.leva,
            "#bfa100",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Target",
            out.target.toFixed(4),
            "#350B40",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Margine Medio",
            (out.margineCentrale * 100).toFixed(2) + "%",
            "#008b8b",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Margine Massimo",
            (out.margineMassimo * 100).toFixed(2) + "%",
            "#00008b",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Indicazioni  di uscita",
            out.confermaDiUscita,
            out.confermaDiUscita === "LASCIAR CORRERE" ? "#519259" : "#AE431E",
            true,
            false,
            true
          )}
        </Row>
        <img
          alt=">"
          src={require("assets/img/triangle_gold.png")}
          onClick={handleClick}
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: 20,
            height: 20,
            rotate: "90deg",
            cursor: "pointer",
          }}
        />
      </CardBody>
      <CardFooter />
    </Card>
  );
};

export const BadgeValue = props => {
  const copy = async value => {
    await navigator.clipboard.writeText(value);
    alert("Valore copiato");
  };

  return (
    <Col lg="6" md="12">
      <ThemeContext.Consumer>
        {({ theme }) => (
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <div
              style={{
                fontSize: 15,
                color: theme === "" ? "white" : "black",
                fontWeight: "bold",
                marginLeft: 20,
              }}
            >
              {props.text}
            </div>
            <Badge
              onClick={() => copy(props.value)}
              style={{
                width: "auto",
                height: 30,
                fontSize: 20,
                marginLeft: 20,
                backgroundColor:
                  props.value === "LONG" || props.value === "IN"
                    ? greenLong
                    : props.value === "SHORT"
                    ? redShort
                    : props.text === "ROI atteso"
                    ? "#bfa100"
                    : props.text === "Target"
                    ? "#350B40"
                    : props.text === "Livello ingresso medio"
                    ? "#91684A"
                    : props.text === "Ingresso"
                    ? "#91684A"
                    : props.text === "Livello ingresso intraday"
                    ? "#ED9133"
                    : props.text === "Probabilità di profitto" &&
                      parseFloat(props.value) > 0.5
                    ? greenLong
                    : props.text === "Probabilità di profitto" &&
                      parseFloat(props.value) < 0.5
                    ? redShort
                    : props.text === "ROI atteso"
                    ? "#bfa100"
                    : props.value === "OUT"
                    ? "#8b0000"
                    : props.value === "CHIUDERE ENTRO LE 19"
                    ? "#CC5500"
                    : props.value === "LASCIAR CORRERE"
                    ? greenLong
                    : "grey",
                color: "white",
                cursor: "copy",
              }}
            >
              {props.value}
            </Badge>
          </div>
        )}
      </ThemeContext.Consumer>
    </Col>
  );
};
export const BadgeValueLg = props => {
  const copy = async value => {
    await navigator.clipboard.writeText(value);
    alert("Valore copiato");
  };

  return (
    <Col lg="12" md="12">
      <ThemeContext.Consumer>
        {({ theme }) => (
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <div
              style={{
                fontSize: 15,
                color: theme === "" ? "white" : "black",
                fontWeight: "bold",
                marginLeft: 20,
              }}
            >
              {props.text}
            </div>
            <Badge
              onClick={() => copy(props.value)}
              style={{
                width: "auto",
                height: 30,
                fontSize: 18,
                marginLeft: 20,
                backgroundColor:
                  props.value === "CHIUDERE ENTRO LE 19"
                    ? "#CC5500"
                    : props.value === "LASCIAR CORRERE"
                    ? greenLong
                    : "grey",
                color: "white",
                cursor: "copy",
              }}
            >
              {props.value}
            </Badge>
          </div>
        )}
      </ThemeContext.Consumer>
    </Col>
  );
};
