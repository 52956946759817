/** @format */

import ReactPlayer from "react-player";
import { useState, useRef, useCallback, useEffect } from "react";
import { Badge, Button, Col, Row } from "reactstrap";
import { checkIsAdmin } from "variables/generalChecker";
import { useSelector } from "react-redux";
import {
  responsePostUserVideoData,
  responseGetPostUserVideoData,
} from "fetchers/userVideoData";
import { responseResources } from "fetchers/resources";
import { mapIdIntoVideoTitle } from "views/Videocorsi";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
// import { Row, Col } from "reactstrap";

export function PlayerComponent({ risorsa, url, isOnDb, isPlaylist, titolo }) {
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const [playedPercent, setPlayedPercent] = useState(0);
  const [rendered, setRendered] = useState(false);
  const [flagView, setFlagView] = useState(false);
  let [duration, setDuration] = useState(0);
  const durationInMinutes = (duration / 60).toFixed(2);
  // eslint-disable-next-line
  const [isPlaying, setIsPlaying] = useState("false");
  const [isReady, setIsReady] = useState(false);
  const userData = useSelector(state => state.user);
  const playerRef = useRef();

  // ...
  //GET
  useEffect(() => {
    async function getUserVideoData() {
      const res = await responseGetPostUserVideoData(userData.userInfo.idUser);
      if (res.length > 0) {
        //initialize videos data from db
        setPlayedPercent(
          res.filter(x => x.idRisorsa === risorsa.idRisorsa)[0]?.playedPercent
        );
        setPlayedSeconds(
          res.filter(x => x.idRisorsa === risorsa.idRisorsa)[0]?.playedSeconds
        );
      }
    }

    rendered && getUserVideoData();

    const timer = setTimeout(() => {
      setFlagView(true);
    }, 500);

    return () => clearTimeout(timer);
  }, [rendered]);

  const loadingBar = () => {};

  //POST
  async function InsertDataAsync() {
    await responsePostUserVideoData({
      idUser: userData?.userInfo.idUser,
      idRisorsa: risorsa.idRisorsa,
      duration: duration,
      playedPercent: playedPercent,
      playedSeconds: playedSeconds,
    });
  }
  const onReady = useCallback(() => {
    if (!isReady) {
      setDuration(playerRef.current.getDuration());
      setIsReady(true);
    }
  }, [isReady]);
  const onStart = () => {
    playerRef.current.seekTo(playedSeconds, "seconds");
  };
  return (
    <>
      {flagView ? (
        <Row style={{ marginBottom: 30, paddingLeft: 10 }}>
          <Col
            lg="2"
            md="4"
            sm="6"
            xs="12"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around",
                alignItems: "start",
              }}
            >
              <h2>{titolo || mapIdIntoVideoTitle(risorsa?.idRisorsa)}</h2>
              <Badge>
                {duration > 60
                  ? (duration / 60).toFixed(2) + " minuti"
                  : duration.toFixed(0) + " secondi"}
              </Badge>
            </span>
          </Col>
          <Col
            lg="1"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              alt=">"
              src={require("assets/img/triangle_gold.png")}
              style={{
                width: 20,
                height: 20,
                marginRight: 20,
                marginBottom: 20,
                rotate: window.innerWidth < 768 ? "-45deg" : "225deg",
              }}
            />
          </Col>
          <Col lg="5" md="4" sm="6" xs="12">
            {!rendered ? (
              <>
                {/* <div
                  className="player-wrapper"
                  style={{
                    position: "absolute",
                    height: "100%",
                    width: "96%",
                    backgroundColor: "grey",
                    opacity: "40%",
                    zIndex: 1,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                ></div> */}
                <Button
                  style={{
                    variant: "info",
                    zIndex: 2,
                    width: 200,
                    height: 50,
                    borderRadius: 10,
                    position: "absolute",
                    margin: "auto",
                    top: 0,
                    bottom: 0,
                    left: 0,
                    right: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={() => {
                    setRendered(true);
                  }}
                >
                  Carica contenuto
                </Button>
              </>
            ) : (
              <></>
            )}
            <ReactPlayer
              controls
              ref={playerRef}
              onReady={onReady}
              isplaying={isPlaying}
              // onPause={async () => InsertDataAsync()}
              onStart={onStart}
              width="100%"
              height="330px"
              url={url}
              onProgress={progress => {
                if (progress.played + progress.playedSeconds > 2) {
                  setPlayedSeconds(progress.playedSeconds);
                  setPlayedPercent(progress.played);
                } else {
                  setPlayedSeconds(playedSeconds);
                  setPlayedPercent(playedPercent);
                }
              }}
              style={{ marginBottom: 20, opacity: !rendered && "30%" }}
              config={{
                youtube: {
                  playerVars: {
                    start: playedSeconds,
                  },
                },
              }}
            />
          </Col>

          {checkIsAdmin(userData?.userInfo.username) && isOnDb ? (
            <Col
              lg="4"
              md="4"
              sm="12"
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Button
                color="info"
                disabled={!rendered}
                style={{
                  borderRadius: 100,
                  fontSize: 20,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={async () => await InsertDataAsync()}
              >
                ↻ Aggiorna
              </Button>
              {/* <p style={{ textAlign: "end" }}>
                Tempo trascorso: <br />
                {playedSeconds > 60
                  ? (playedSeconds / 60)?.toFixed(2) + " minuti"
                  : playedSeconds?.toFixed(0) + " secondi"}
                <br /> */}
              <h4>Percentuale completamento: </h4>
              <Box sx={{ width: "100%" }}>
                <LinearProgress
                  variant={isReady ? "determinate" : "indeterminate"}
                  value={(playedPercent * 100).toFixed(1)}
                  style={{ height: 20 }}
                />
              </Box>
              {(playedPercent * 100).toFixed(1)} % <br />
            </Col>
          ) : (
            <Col>
              {isPlaylist ? (
                <>
                  <p>
                    {"*(Premere sull'icona: "}
                    <img
                      alt=">"
                      src={require("assets/img/playlist.png")}
                      style={{
                        marginLeft: 20,
                        marginRight: 20,
                        width: 25,
                        height: 20,
                      }}
                    />
                    {" all'interno dell'anteprima per espandere la playlist)"}
                  </p>
                </>
              ) : (
                <></>
              )}
            </Col>
          )}
        </Row>
      ) : (
        <></>
      )}
    </>
  );
}
