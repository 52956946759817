/** @format */

export function TitleWithFlags(idStrumento, best, navigate) {
  if (
    idStrumento === "de30" ||
    idStrumento === "crudeoil" ||
    idStrumento === "xauusd" ||
    idStrumento === "gas" ||
    idStrumento === "dowjones" ||
    idStrumento === "sp500" ||
    idStrumento === "nasdaq" ||
    idStrumento === "silver"
  )
    return (
      <div style={{ borderRadius: 0.9, marginTop: 6, display: "flex" }}>
        <img
          alt=""
          src={require(`assets/img/${idStrumento}.png`)}
          width={40}
          height={40}
        />
        <div
          style={{
            marginTop: 7,
            fontWeight: "bold",
            marginLeft: 10,
            marginRight: 10,
            cursor: "pointer",
          }}
          onClick={() => best && navigate(`/admin/strumento/${idStrumento}`)}
        >
          {idStrumento.toUpperCase()}
        </div>
      </div>
    );

  return (
    <div style={{ display: "flex" }}>
      <img
        alt=""
        src={require(`assets/img/${idStrumento.substring(0, 3)}.png`)}
        width={40}
        height={40}
      />
      <div
        style={{
          marginTop: 5,
          fontWeight: "bold",
          marginLeft: 10,
          marginRight: 10,
          cursor: "pointer",
        }}
        onClick={() => best && navigate(`/admin/strumento/${idStrumento}`)}
      >
        {idStrumento.toUpperCase()}
      </div>
      <img
        alt=""
        src={require(`assets/img/${idStrumento.substring(3, 6)}.png`)}
        width={40}
        height={40}
      />
    </div>
  );
}
