/** @format */

import {
  redShort,
  greenLong,
} from "components/StrumentiComponents/StrumentiComponents";

export const addFilterChart = (output, idStrumento, current) => {
  const dataAdd = calculateDataAdd(output, idStrumento, current);
  return {
    labels: ["LONG", "SHORT"],
    options: {
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        y: {
          suggestedMax: 1,
          ticks: {
            beginAtZero: true,
            format: {
              style: "percent",
            },
          },
        },
      },
    },
    datasets: [
      {
        fill: true,
        backgroundColor: [greenLong, redShort],
        borderColor: [greenLong, redShort],
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "#0C1157",
        pointBorderColor: "#0C1157",
        pointHoverBackgroundColor: "#00d6b4",
        pointBorderWidth: 0.5,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 2.5,
        data: [
          dataAdd.LONG / (dataAdd.LONG + dataAdd.SHORT),
          dataAdd.SHORT / (dataAdd.LONG + dataAdd.SHORT),
        ],
      },
    ],
  };
};

const calculateDataAdd = (output, thisIdStrumento, current) => {
  const first = thisIdStrumento.substring(0, 3);
  const last = thisIdStrumento.substring(3);
  let counLongFirst = 0;
  let counShortFirst = 0;
  let counLongLast = 0;
  let counShortLast = 0;

  output.map(x => {
    const id = x.idStrumento.toLowerCase();
    if (id.substring(0, 3) === first && x.filtroDirezione === "LONG")
      counLongFirst++;
    else if (id.substring(0, 3) === first && x.filtroDirezione === "SHORT")
      counShortFirst++;
    else if (id.substring(3) === last && x.filtroDirezione === "LONG")
      counLongLast++;
    else if (id.substring(3) === last && x.filtroDirezione === "SHORT")
      counShortLast++;
  });

  if (current === first) return { LONG: counLongFirst, SHORT: counShortFirst };
  else return { LONG: counLongLast, SHORT: counShortLast };
};
