/** @format */
import serviceCardImg from "assets/serviceCardImg/serviceCardImg";
import { PlayerComponent } from "components/ReactPlayer/PlayerComponent";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { okRoiPropDiamond } from "variables/activeTools";
import { displayPropImage } from "variables/generalChecker";

export const PrintMoneyHeader = props => {
  return (
    <Row className="mb-5">
      <Col
        lg="12"
        xs="12"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img alt={"logo"} src={require(`assets/img/printmoney.png`)} />
      </Col>

      {/* <Col lg="8" xs="12">
        <a
          href="https://www.tradingstatistico.it/area-riservata/signup/m8xR1ohr"
          target="_blank"
        >
          <img
            alt={"roiprop"}
            src={require(`assets/img/roiprop_image1.jpg`)}
            width={"100%"}
            height={"100%"}
          />
        </a>
      </Col> */}
    </Row>
  );
};

export const PrintMoneyCardLinkTelegram = props => {
  return (
    <Card>
      <a href="https://t.me/+xGrKLOywQTBiN2Q0" target="_blank" rel="noreferrer">
        <CardHeader>
          <CardTitle style={{ display: "flex" }}>
            <img
              alt={"telegram"}
              src={require(`assets/img/telegram.png`)}
              width={100}
              height={100}
              style={{ alignSelf: "center" }}
            />
            <div style={{ marginLeft: 30 }}>
              <h4>SALA SEGNALI PRINT MONEY™</h4>
              <h6>
                Accesso lifetime al Gruppo Telegram esclusivo dove verranno
                rilasciati i migliori segnali!
              </h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const PrintMoneyCardLinkIndicatori = props => {
  return (
    <Card>
      <a
        href="https://drive.google.com/drive/folders/1Qw3KULS8oahzvq8SjTeX891NoLBvcNrc?usp=drive_link"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex" }}>
            <img
              alt={"facebook"}
              src={require(`assets/img/roiprop_strategy.png`)}
              width={100}
              height={80}
              style={{ alignSelf: "center" }}
            />
            <div style={{ marginLeft: 30 }}>
              <h4>INDICATORI PRINT MONEY™</h4>
              <h6>
                Indicatori di trend e order block per strategia PRINT MONEY™
              </h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const PrintMoneyCardLinkMasterClass = props => {
  return (
    <Row className="mb-5">
      <a href="https://streamyard.com/watch/A8yZ6FRfduDx" target="_blank">
        <img
          alt={"roiprop_master"}
          src={require(`assets/img/roiprop_masterclass.png`)}
          width={"100%"}
          height={"100%"}
        />
      </a>
    </Row>
  );
};
export const PrintMoneyCardConsulenzaOneToOne = () => {
  return (
    <Card>
      <CardHeader>
        <CardTitle
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <img
            alt="Teletrade"
            src={require("assets/img/consulenzaonetoone.png")}
            width={100}
            height={100}
          />
          <div style={{ marginLeft: 30 }}>
            <h4>Consulenza One to One con il Dott. Angelo Nisticò</h4>

            <h6>
              Due sessioni personalizzate incluse con Angelo Nisticò, per
              ricevere consigli su misura e migliorare le tue competenze di
              trading.{" "}
            </h6>
          </div>
        </CardTitle>
      </CardHeader>
    </Card>
  );
};
