/** @format */

import { createContext } from "react";

export const studies = {
  d1: "daily",
  h1: "hourly",
};

export const StatisticsContext = createContext({
  statistics: studies.d1,
  changeStudies: () => {},
});
