/** @format */

import { useRoutes } from "react-router-dom";
import routes2 from "routes2";

export default function App() {
  const routing = useRoutes(routes2);

  return <div>{routing}</div>;
  // <Routes>
  //   <Route path="/" element={<Navigate replace to="/login" />} />
  //   <Route path="/login" element={<Login />} />
  //   <Route path="/admin" element={<Admin />} />
  // </Routes>
}
