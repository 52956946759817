/** @format */

import { Row, Col, Badge } from "reactstrap";
import { ThemeContext } from "contexts/ThemeContext";
import { redShort } from "components/StrumentiComponents/StrumentiComponents";
import { greenLong } from "components/StrumentiComponents/StrumentiComponents";

// above 991 sidebar auto toggle off

export const moduloColInCardSm = (
  key,
  value,
  color,
  isCardOutput,
  isBest,
  manyChar
) => {

  const copy = async value => {
    console.log(color)
    await navigator.clipboard.writeText(value);
    alert("Valore copiato");
  };
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <Col
          lg={isCardOutput ? "4" : "6"}
          md={isCardOutput ? "6" : "6"}
          sm={isCardOutput ? "6" : "6"}
          xs={isCardOutput ? "6" : "6"}
          style={{
            marginTop: 25,
          }}
        >
          <Col
            style={{
              height: 80,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                height: "50%",
                fontSize:
                  window.innerWidth > 600
                    ? 15
                    : window.innerWidth < 400
                    ? 12
                    : 13,
                color: theme === "" ? "white" : "black",
                fontWeight: "bold",
                textAlign: "center",
                marginBottom: 5,

                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {key}
            </div>
            <div style={{ height: "40%" }}>
              <Badge
                style={{
                  height: 25,
                  fontSize:
                    window.innerWidth > 600
                      ? 15
                      : window.innerWidth < 400
                      ? 13
                      : 14,
                  width: value?.length > 12 ? 165 : 120,
                  backgroundColor: color || colorBadge(value),
                  color: "white",
                  cursor: "copy",
                }}
                onClick={() => copy(value)}
              >
                {value
                  ? !isNaN(parseFloat(value))
                    ? formatNumber(value)
                    : value
                  : "-"}
              </Badge>
            </div>
          </Col>
        </Col>
      )}
    </ThemeContext.Consumer>
  );
};

export const moduloColInCardLg = (key, value, color) => {
  const copy = async value => {
    await navigator.clipboard.writeText(value);
    alert("Valore copiato");
  };

  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <Col lg="12">
          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <Col
              md="6"
              style={{
                fontSize:
                  window.innerWidth > 600
                    ? 15
                    : window.innerWidth < 400
                    ? 12
                    : 13,
                color: theme === "" ? "white" : "black",
                marginBottom: 5,
                fontWeight: "bold",
                textAlign: window.innerWidth < 700 ? "center" : "right",
              }}
            >
              {key}
            </Col>
            <Col
              md="6"
              style={{
                display: "flex",
                justifyContent: window.innerWidth < 700 ? "center" : "left",
              }}
            >
              <Badge
                style={{
                  height: 25,
                  fontSize:
                    window.innerWidth > 600
                      ? 15
                      : window.innerWidth < 400
                      ? 12
                      : 13,
                  width: 170,
                  backgroundColor: color ? color : "grey",
                  color: "white",
                  cursor: "copy",
                }}
                onClick={() => copy(value)}
              >
                {value
                  ? !isNaN(parseFloat(value))
                    ? formatNumber(value)
                    : value
                  : "-"}
              </Badge>
            </Col>
          </Row>
        </Col>
      )}
    </ThemeContext.Consumer>
  );
};

export const moduloColInCardXs = (key, value, color) => {
  const copy = async value => {
    await navigator.clipboard.writeText(value);
    alert("Valore copiato");
  };

  return (
    <ThemeContext.Consumer key={key + value + color}>
      {({ theme }) => (
        <Col
          lg={
            window.innerWidth > 1400
              ? "2"
              : window.innerWidth > 1120
              ? "3"
              : "4"
          }
          sm="4"
          xs="4"
        >
          <Col
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <div
              style={{
                fontSize: 13,
                color: theme === "" ? "white" : "black",
                marginTop: 2,
                fontWeight: "bold",
                textAlign: window.innerWidth < 768 ? "center" : "right",
              }}
            >
              {key}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: window.innerWidth < 768 ? "center" : "left",
              }}
            >
              <Badge
                style={{
                  height: 25,
                  width: 80,
                  fontSize: 14,
                  backgroundColor: color ? color : "grey",
                  color: "white",
                  cursor: "copy",
                }}
                onClick={() => copy(value)}
              >
                {value
                  ? !isNaN(parseFloat(value))
                    ? formatNumber(value)
                    : value
                  : "-"}
              </Badge>
            </div>
          </Col>
        </Col>
      )}
    </ThemeContext.Consumer>
  );
};

export const moduloColInCardSemaforo = (getTrafficLight, key, value, color) => {
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <Col lg="3" md="6" sm="6">
          {key}
          {getTrafficLight("setup")}
        </Col>
      )}
    </ThemeContext.Consumer>
  );
};

export const colorBadge = value => {
  switch (value) {
    case "SHORT":
    case "VENDI":
      return redShort;
    case "LONG":
    case "COMPRA":
    case "IN":
      return greenLong;
    case "OUT":
      return "red";
    default:
      return "darkGrey";
  }
};

export const formatNumber = num => {
  return num.toString().includes("%")
    ? num
    : num.toString().includes(".") && num.toString().includes(",")
    ? parseFloat(num.replace(".", "")).toFixed(2).toString().replace(".", ",")
    : num.toString().includes(".")
    ? parseFloat(num).toFixed(4).toString().replace(".", ",")
    : num;
};
