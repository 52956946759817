/** @format */

import { createContext } from "react";

export const backgroundColors = {
  primary: "primary",
  secondary: "secondary",
  red: "red",
  green: "green",
};

export const BackgroundColorContext = createContext({
  color: backgroundColors.primary,
  changeColor: color => {},
});
