/** @format */

const SET_DATACHARTS = "SET_DATACHARTS";

const initialstate = {
  strumenti: [
    // { idStrumento: "AUDCAD", dataCharts: {} },
    // { idStrumento: "AUDCHF", dataCharts: {} },
    // { idStrumento: "AUDJPY", dataCharts: {} },
    // { idStrumento: "AUDNZD", dataCharts: {} },
    // { idStrumento: "AUDUSD", dataCharts: {} },
    // { idStrumento: "CADCHF", dataCharts: {} },
    // { idStrumento: "CADJPY", dataCharts: {} },
    // { idStrumento: "CHFJPY", dataCharts: {} },
    // { idStrumento: "CRUDEOIL", dataCharts: {} },
    // { idStrumento: "EURAUD", dataCharts: {} },
    // { idStrumento: "GBPCAD", dataCharts: {} },
    // { idStrumento: "GBPCHF", dataCharts: {} },
    // { idStrumento: "GBPJPY", dataCharts: {} },
    // { idStrumento: "GBPNZD", dataCharts: {} },
    // { idStrumento: "GBPUSD", dataCharts: {} },
    // { idStrumento: "NZDCAD", dataCharts: {} },
    // { idStrumento: "NZDCHF", dataCharts: {} },
    // { idStrumento: "NZDJPY", dataCharts: {} },
    // { idStrumento: "NZDUSD", dataCharts: {} },
    // { idStrumento: "USDCAD", dataCharts: {} },
    // { idStrumento: "USDCHF", dataCharts: {} },
    // { idStrumento: "USDJPY", dataCharts: {} },
    // { idStrumento: "XAUUSD", dataCharts: {} },
    // { idStrumento: "DE30", dataCharts: {} },
    // { idStrumento: "NASDAQ", dataCharts: {} },
    // { idStrumento: "SP500", dataCharts: {} },
    // { idStrumento: "DOWJONES", dataCharts: {} },
    // { idStrumento: "SILVER", dataCharts: {} },
    // { idStrumento: "BTCUSD", dataCharts: {} },
    // { idStrumento: "GAS", dataCharts: {} },
  ],
};

export const setDataCharts = (state = initialstate, action) => {
  switch (action.type) {
    case SET_DATACHARTS: //for one

      return {
        ...state,
        strumenti: [...initialstate.strumenti, action.payload],
      };
    default:
      return state;
  }
};
