/** @format */

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Badge,
  ButtonGroup,
  Button,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import { useState, useRef } from "react";
import classNames from "classnames";
import { Bar, Line } from "react-chartjs-2";
import { chartDays } from "variables/charts";
import { chartMonths } from "variables/charts";
import { chartYears } from "variables/charts";
import moment from "moment/moment";
import "moment/locale/it";
import { addFilterChart } from "variables/addFilterChart";
import { sentimentFilterChart } from "variables/sentimentFilterChart";
import { chartHours } from "variables/charts";

export const YymmddChart = props => {
  const [bigChartData, setbigChartData] = useState("years");
  const data = props.dataCharts;
  const setBgChartData = name => {
    setbigChartData(name);
  };

  return (
    <Card className="card-chart">
      <CardHeader>
        <Row>
          <Col className="text-left" sm="6">
            <h5 className="card-category">
              Grafico andamento prezzo giornaliero
            </h5>
            <CardTitle tag="h2">{props.idStrumento} D1</CardTitle>
          </Col>
          <Col sm="6">
            <ButtonGroup
              className="btn-group-toggle float-right"
              data-toggle="buttons"
            >
              <Button
                tag="label"
                className={classNames("btn-simple", {
                  active: bigChartData === "days",
                })}
                color="primary"
                id="0"
                size="sm"
                onClick={() => setBgChartData("days")}
              >
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  DAYS
                </span>
                <span className="d-block d-sm-none">D</span>
              </Button>
              <Button
                color="primary"
                id="1"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: bigChartData === "month",
                })}
                onClick={() => setBgChartData("month")}
              >
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  MONTH
                </span>
                <span className="d-block d-sm-none">M</span>
              </Button>
              <Button
                color="primary"
                id="2"
                size="sm"
                tag="label"
                className={classNames("btn-simple", {
                  active: bigChartData === "years",
                })}
                onClick={() => setBgChartData("years")}
              >
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  YEARS
                </span>
                <span className="d-block d-sm-none">Y</span>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart-area">
          {bigChartData === "days" ? (
            <Line
              data={chartDays(props.dataChart)}
              options={chartDays(props.dataChart).options}
            />
          ) : bigChartData === "month" ? (
            <Line
              data={chartMonths(props.dataChart)}
              options={chartMonths(props.dataChart).options}
            />
          ) : bigChartData === "years" ? (
            <Line
              data={chartYears(props.dataChart)}
              options={chartYears(props.dataChart).options}
            />
          ) : (
            <></>
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export const HHChart = props => {
  const [bigChartData, setbigChartData] = useState("hours");
  const data = props.dataCharts;
  const setBgChartData = name => {
    setbigChartData(name);
  };

  return (
    <Card className="card-chart">
      <CardHeader>
        <Row>
          <Col className="text-left" sm="6">
            <h5 className="card-category">Grafico andamento prezzo orario</h5>
            <CardTitle tag="h2">{props.idStrumento} H1</CardTitle>
          </Col>
          <Col sm="6">
            <ButtonGroup
              className="btn-group-toggle float-right"
              data-toggle="buttons"
            >
              <Button
                tag="label"
                className={classNames("btn-simple", {
                  active: bigChartData === "hours",
                })}
                color="primary"
                id="0"
                size="sm"
                onClick={() => setBgChartData("hours")}
              >
                <span className="d-none d-sm-block d-md-block d-lg-block d-xl-block">
                  HOURS
                </span>
                <span className="d-block d-sm-none">D</span>
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <div className="chart-area">
          {bigChartData === "hours" && (
            <Line
              data={chartHours(props.dataChart)}
              options={chartHours(props.dataChart).options}
            />
          )}
        </div>
      </CardBody>
    </Card>
  );
};

export const CurrentPriceChart = (updated, currentPrice, color, dataChart) => {
  return (
    <Card className="card-chart">
      <CardHeader>
        <h5 className="card-category">Livello attuale - {updated}</h5>
        <CardTitle tag="h3">
          <i className="tim-icons icon-bell-55 text-info" />
          {currentPrice}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className="chart-area">
          <Bar
            data={chartDays(dataChart)}
            options={chartDays(dataChart).options}
            color={color}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export const ChartAddFilter = props => {
  const [current, setCurrent] = useState(props.idStrumento.substring(0, 3));
  return (
    <Card className="card-chart">
      <CardHeader>
        <h5 className="card-category">
          Grafico Analisi Della Direzionalità ADD™
        </h5>
        <CardTitle
          tag="h3"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            onClick={() => setCurrent(props.idStrumento.substring(0, 3))}
            style={{
              display: "flex",
              flexDirection: "column",
              fontWeight: "bold",
              cursor: "pointer",
              opacity: current === props.idStrumento.substring(0, 3) ? 1 : 0.5,
            }}
          >
            <img
              alt=""
              src={
                props.idStrumento === "xauusd"
                  ? require(`assets/img/xauusd.png`)
                  : require(`assets/img/${props.idStrumento.substring(
                      0,
                      3
                    )}.png`)
              }
              width={40}
              height={40}
              style={{ marginLeft: 3 }}
            />
            {props.idStrumento.substring(0, 3).toUpperCase()}
          </div>{" "}
          <img
            alt=""
            src={require(`assets/img/versus.png`)}
            width={50}
            height={50}
            style={{ margin: 30, marginTop: 8 }}
          />
          <div
            onClick={() => setCurrent(props.idStrumento.substring(3))}
            style={{
              display: "flex",
              flexDirection: "column",
              fontWeight: "bold",
              cursor: "pointer",
              opacity: current === props.idStrumento.substring(3) ? 1 : 0.5,
            }}
          >
            {props.idStrumento.substring(3).toUpperCase()}
            <img
              alt=""
              src={require(`assets/img/${props.idStrumento.substring(3)}.png`)}
              width={40}
              height={40}
              style={{ marginLeft: 5 }}
            />
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className="chart-area">
          <Bar
            data={addFilterChart(props.output, props.idStrumento, current)}
            options={
              addFilterChart(props.output, props.idStrumento, current).options
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export const ChartAddFilterH1 = props => {
  const [current, setCurrent] = useState(props.idStrumento.substring(0, 3));
  const currentHour = moment().hour();
  return (
    <Card className="card-chart">
      <CardHeader>
        <h5 className="card-category">
          Grafico Analisi Della Direzionalità ADD™
        </h5>
        <CardTitle
          tag="h3"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div
            onClick={() => setCurrent(props.idStrumento.substring(0, 3))}
            style={{
              display: "flex",
              flexDirection: "column",
              fontWeight: "bold",
              cursor: "pointer",
              opacity: current === props.idStrumento.substring(0, 3) ? 1 : 0.5,
            }}
          >
            <img
              alt=""
              src={
                props.idStrumento === "xauusd"
                  ? require(`assets/img/xauusd.png`)
                  : require(`assets/img/${props.idStrumento.substring(
                      0,
                      3
                    )}.png`)
              }
              width={40}
              height={40}
              style={{ marginLeft: 3 }}
            />
            {props.idStrumento.substring(0, 3).toUpperCase()}
          </div>{" "}
          <img
            alt=""
            src={require(`assets/img/versus.png`)}
            width={50}
            height={50}
            style={{ margin: 30, marginTop: 8 }}
          />
          <div
            onClick={() => setCurrent(props.idStrumento.substring(3))}
            style={{
              display: "flex",
              flexDirection: "column",
              fontWeight: "bold",
              cursor: "pointer",
              opacity: current === props.idStrumento.substring(3) ? 1 : 0.5,
            }}
          >
            {props.idStrumento.substring(3).toUpperCase()}
            <img
              alt=""
              src={require(`assets/img/${props.idStrumento.substring(3)}.png`)}
              width={40}
              height={40}
              style={{ marginLeft: 5 }}
            />
          </div>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <div className="chart-area">
          <Bar
            data={addFilterChart(props.output, props.idStrumento, current)}
            options={
              addFilterChart(props.output, props.idStrumento, current).options
            }
          />
        </div>
      </CardBody>
    </Card>
  );
};

export const ChartSentimentFilter = props => {
  return (
    <div className="chart-area">
      <Bar
        data={sentimentFilterChart(props.sentimentData)}
        options={sentimentFilterChart(props.sentimentData).options}
      />
    </div>
  );
};
// export const AltroChart2 = () => {
//   return (
//     <Card className="card-chart">
//       <CardHeader>
//         <h5 className="card-category">Completed Tasks</h5>
//         <CardTitle tag="h3">
//           <i className="tim-icons icon-send text-success" /> 12K
//         </CardTitle>
//       </CardHeader>
//       <CardBody>
//         <div className="chart-area">
//           <Line data={chartMonths} options={chartMonths.options} />
//         </div>
//       </CardBody>
//     </Card>
//   );
// };

export const ChartArcGauge = props => {};
