/** @format */

import React from "react";
import { Bar } from "react-chartjs-2";

const BarChart = ({ data, votanti }) => {
  const chartData = {
    labels: data.map(item => item.idStrumento),
    datasets: [
      {
        label: `Votanti ${votanti}`,
        data: data.map(item => (item.value * 100).toFixed()),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            return context.parsed.y + "%"; // Append "%" to the tooltip label
          },
        },
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return value + "%"; // Append "%" to the tick values
          },
        },
      },
    },
  };

  return <Bar data={chartData} options={chartOptions} />;
};

export default BarChart;
