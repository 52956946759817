/** @format */

import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import moment from "moment/moment";
import "moment/locale/it";
import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { useDispatch, useSelector } from "react-redux";
import routes2 from "routes2";
import Footer from "components/Footer/Footer";
import { getCookie } from "cookieCreator";
import { setOutput } from "actions/output";
import { responseRefreshLogin } from "fetchers/refreshLogin";
import { responseOutputStrumenti } from "fetchers/outputStrumenti";
import { setLoginEmpty } from "actions/login/actionsLogin";
import { responseEnabledUserModules } from "fetchers/categorieAttive";
import { setUserCategories, setUserInfo } from "actions/user/userAction";
import { responseUserInfo } from "fetchers/userInfo";
import { ToastContainer, toast } from "react-toastify";
import { responseResources } from "fetchers/resources";
import ScrollToTop from "react-scroll-to-top";
import { responseGetUserClaim } from "fetchers/userClaims";
import { setFavorites } from "actions/user/userAction";
import { StatisticsContext } from "contexts/StatisticsContext";
import { responseOutputStrumentiH1 } from "fetchers/outputStrumenti";
import { setOutputH1 } from "actions/output";
import { okH1 } from "variables/activeTools";
var ps;

export default function Admin() {
  const [userClaims, setUserClaims] = useState([]);
  moment.locale("it");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const output = useSelector(state => state.output);
  const user = useSelector(state => state.user);
  const location = useLocation();
  const favorites = useSelector(state => state.user.favorites);
  const mainPanelRef = React.useRef(null);

  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  );

  useEffect(() => {
    // Notify("tipMarketing");
  }, []);

  useEffect(() => {
    var token = getCookie("token");
    var refreshToken = getCookie("refreshToken");
    async function getResponse() {
      const responseUser = await responseUserInfo(token);
      const responseOutput = await responseOutputStrumenti(token);
      const responseOutputH1 = await responseOutputStrumentiH1(token);
      const responseModules = await responseEnabledUserModules(token);
      let groupedById = {};
      // Itera sull'array e raggruppa gli oggetti per idProdotto
      responseModules.forEach(obj => {
        const { idProdotto } = obj;
        if (!groupedById[idProdotto]) {
          groupedById[idProdotto] = obj;
        } else {
          // Se c'è già un oggetto con lo stesso idProdotto, unisci le proprietà
          Object.assign(groupedById[idProdotto], obj);
        }
      });
      // Ottieni i valori dell'oggetto raggruppato per creare un array unificato
      let unifiedArray = Object.values(groupedById);

      const responseUserClaim = await responseGetUserClaim(responseUser.idUser);
      const resources = await responseResources(token);
      if (
        responseOutput?.length > 0 &&
        responseOutputH1?.length > 0 &&
        responseUser != null &&
        responseModules?.length > 0
      ) {
        await dispatch(setOutput(responseOutput, user));
        await dispatch(setOutputH1(responseOutputH1, user));
        await dispatch(setUserInfo(responseUser));
        await dispatch(setUserCategories(unifiedArray));

        await dispatch(
          setFavorites(responseUserClaim?.filter(x => x.idClaim == 1)[0]?.value)
        );
        setUserClaims(responseUserClaim);
      } else {
        const tokensBody = { accessToken: token, refreshToken };
        const response = await responseRefreshLogin(tokensBody);
        if (!response?.length > 0) {
          setLoginEmpty();
          alert(
            "SESSIONE SCADUTA, EFFETTUA NUOVAMENTE L'ACCESSO PER CONTINUARE"
          );
          navigate("/login");
        }
      }
    }

    getResponse();
  }, []);

  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
    setsidebarOpened(!sidebarOpened);
  };

  const getBrandText = path => {
    for (let i = 0; i < routes2.length; i++) {
      if (
        location.pathname.indexOf(routes2[i].layout + routes2[i].path) !== -1
      ) {
        return routes2[i].name;
      }
    }
    return "Brand";
  };

  const handleTouchEnd = e => {
    if (sidebarOpened) toggleSidebar();
  };
  return (
    <StatisticsContext.Consumer>
      {({ statistics, changeStudies }) => {
        return (
          <div className={`page ${statistics}`}>
            <div className="wrapper">
              <Sidebar
                routes={routes2}
                logo={{
                  outterLink: "",
                  text: "MTplatform",
                  imgSrc: logo,
                }}
                toggleSidebar={toggleSidebar}
                output={output}
                sidebarOpened={sidebarOpened}
                favorites={favorites}
              />

              <div
                className="main-panel"
                ref={mainPanelRef}
                data=""
                onTouchEnd={handleTouchEnd}
              >
                <AdminNavbar
                  brandText={getBrandText(location.pathname)}
                  toggleSidebar={toggleSidebar}
                  sidebarOpened={sidebarOpened}
                  handleStatClick={changeStudies}
                  currentStat={statistics}
                  onClick={() => changeStudies("hourly")}
                />
                <div style={{ height: 40 }}></div>
                <Outlet />
              </div>
            </div>

            {/* <FixedPlugin bgColor={color} handleBgClick={changeColor} /> */}
            <Footer fluid />
            <ScrollToTop
              smooth
              component={
                <img alt=">" src={require("assets/img/scrolltotop.png")} />
              }
              style={{ backgroundColor: "transparent" }}
            />
            <div>
              <ToastContainer />
            </div>
          </div>
        );
      }}
    </StatisticsContext.Consumer>
  );
}
