/** @format */

import { checkIsAdmin } from "./generalChecker";

//GLOABAL H1-D1
export const okH1 = outputReducer => {
  return outputReducer?.strumentiH1?.length > 0;
};
export const okD1 = outputReducer => {
  return outputReducer.strumenti.length > 0;
};

//D1
export const okRoibooster = userData => {
  return (
    userData.categories.filter(e => e.descrizioneCategoria === "RoiBooster")
      .length > 0
  );
};
export const okThousandollar = userData => {
  return (
    userData.categories.filter(e => e.descrizioneCategoria === "Thousandollar")
      .length > 0
  );
};
export const okMilliondollar = userData => {
  return (
    userData.categories.filter(e => e.descrizioneCategoria === "Milliondollar")
      .length > 0
  );
};

//H1
export const okRoiScalpingForex = userData => {
  return (
    userData.categories.filter(
      e => e.descrizioneCategoria === "RoiScalpingForex"
    ).length > 0
  );
};
export const okRoiScalpingGold = userData => {
  return (
    userData.categories.filter(
      e => e.descrizioneCategoria === "RoiScalpingGold"
    ).length > 0
  );
};
export const okRoiScalpingIndexes = userData => {
  return (
    userData.categories.filter(
      e => e.descrizioneCategoria === "RoiScalpingIndexes"
    ).length > 0
  );
};
export const okRoiScalpingCommodities = userData => {
  return (
    userData.categories.filter(
      e => e.descrizioneCategoria === "RoiScalpingCommodities"
    ).length > 0
  );
};

//commodities
export const okOilExtractor = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "OilExtractor" ||
      e.descrizioneCategoria === "RoiTrading" || //da assegnare modulo a roitrading sul db
      e.moduli.filter(x => x.moduleName === "OilExtractor").length > 0
  ).length > 0;
export const okGoldMiner = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "GoldMiner" ||
      e.descrizioneCategoria === "RoiTrading" ||
      e.moduli.filter(x => x.moduleName === "GoldMiner").length > 0
  ).length > 0;
export const okDax = userData =>
  userData.categories?.filter(
    e =>
      e.descrizioneCategoria === "Dax" ||
      e.descrizioneCategoria === "RoiTrading" ||
      e.moduli.filter(x => x.moduleName === "De30").length > 0
  ).length > 0;
export const okBtcusd = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "Btcusd" ||
      e.moduli.filter(x => x.moduleName === "Btcusd").length > 0
  ).length > 0;
export const okGas = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "Gas" ||
      e.moduli.filter(x => x.moduleName === "Gas").length > 0
  ).length > 0;
export const okNasdaq = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "Nasdaq" ||
      e.moduli.filter(x => x.moduleName === "Nasdaq").length > 0
  ).length > 0;
export const okDowJones = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "Dowjones" ||
      e.moduli.filter(x => x.moduleName === "Dowjones").length > 0
  ).length > 0;
export const okSp500 = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "Sp500" ||
      e.moduli.filter(x => x.moduleName === "Sp500").length > 0
  ).length > 0;
export const okSilver = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "Silver" ||
      e.moduli.filter(x => x.moduleName === "Silver").length > 0
  ).length > 0;

// -----------------------------------------------------------------------------------------------  H1
export const okOilExtractorH1 = userData =>
  userData.categories.filter(
    e => e.moduli.filter(x => x.moduleName === "CrudeoilH1").length > 0
  ).length > 0;
export const okGoldMinerH1 = userData =>
  userData.categories.filter(
    e => e.moduli.filter(x => x.moduleName === "GoldH1").length > 0
  ).length > 0;
export const okDaxH1 = userData =>
  userData.categories?.filter(
    e => e.moduli.filter(x => x.moduleName === "De30H1").length > 0
  ).length > 0;
export const okGasH1 = userData =>
  userData.categories.filter(
    e => e.moduli.filter(x => x.moduleName === "GasH1").length > 0
  ).length > 0;
export const okNasdaqH1 = userData =>
  userData.categories.filter(
    e => e.moduli.filter(x => x.moduleName === "NasdaqH1").length > 0
  ).length > 0;
export const okDowJonesH1 = userData =>
  userData.categories.filter(
    e => e.moduli.filter(x => x.moduleName === "DowjonesH1").length > 0
  ).length > 0;
export const okSp500H1 = userData =>
  userData.categories.filter(
    e => e.moduli.filter(x => x.moduleName === "Sp500H1").length > 0
  ).length > 0;
export const okSilverH1 = userData =>
  userData.categories.filter(
    e => e.moduli.filter(x => x.moduleName === "SilverH1").length > 0
  ).length > 0;
export const okBtcusdH1 = userData =>
  userData.categories.filter(
    e => e.moduli.filter(x => x.moduleName === "BtcusdH1").length > 0
  ).length > 0;
//-----------------------------------------ETF
export const okSp500Etf = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "Antipirla" ||
      e.descrizioneCategoria === "Antipirla5x" ||
      e.descrizioneCategoria === "Antipirla10x"
  ).length > 0;
export const okNasdaqEtf = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "Antipirla" ||
      e.descrizioneCategoria === "Antipirla5x" ||
      e.descrizioneCategoria === "Antipirla10x"
  ).length > 0;
export const okMsciEtf = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "Antipirla" ||
      e.descrizioneCategoria === "Antipirla5x" ||
      e.descrizioneCategoria === "Antipirla10x"
  ).length > 0;

export const okGoldEtf = userData =>
  userData.categories.filter(
    e =>
      e.descrizioneCategoria === "Antipirla5x" ||
      e.descrizioneCategoria === "Antipirla10x"
  ).length > 0;
export const okEthEtf = userData =>
  userData.categories.filter(e => e.descrizioneCategoria === "Antipirla10x")
    .length > 0;
export const okBtcEtf = userData =>
  userData.categories.filter(e => e.descrizioneCategoria === "Antipirla10x")
    .length > 0;
export const okBatteryEtf = userData =>
  userData.categories.filter(e => e.descrizioneCategoria === "Antipirla10x")
    .length > 0;
export const okBlockchainEtf = userData =>
  userData.categories.filter(e => e.descrizioneCategoria === "Antipirla10x")
    .length > 0;
//-----------------------------------------CURRENCIES
export const okCurrencies = userData => {
  return (
    userData.categories.filter(
      e =>
        e.descrizioneCategoria === "Thousandollar" ||
        e.descrizioneCategoria === "Milliondollar" ||
        e.descrizioneCategoria === "RoiBooster" ||
        e.descrizioneCategoria === "RoiTrading"
    ).length > 0
  );
};
export const okIndex = userData => {
  return (
    userData.categories.filter(
      e => e.descrizioneCategoria === "TheWolfOfIndexes"
    ).length > 0
  );
};
export const okCommodities = userData => {
  return (
    userData.categories.filter(e => e.descrizioneCategoria === "Commodities")
      .length > 0
  );
};
export const okCrypto = userData => {
  return (
    userData.categories.filter(
      e => e.descrizioneCategoria === "NomeCategoriaAssegnatoACrypto"
    ).length > 0
  );
};
//ANTIPIRLA
export const okAntipirla = userData => {
  return (
    userData.categories.filter(e => e.descrizioneCategoria === "Antipirla")
      .length > 0
  );
};
export const okAntipirla5x = userData => {
  return (
    userData.categories.filter(e => e.descrizioneCategoria === "Antipirla5x")
      .length > 0
  );
};
export const okAntipirla10x = userData => {
  return (
    userData.categories.filter(e => e.descrizioneCategoria === "Antipirla10x")
      .length > 0
  );
};
//ROIPROP
export const okRoiProp = userData => {
  return (
    userData.categories.filter(e => e.descrizioneCategoria === "Roiprop")
      .length > 0
  );
};
export const okRoiPropBasic = userData => {
  return userData.categories.filter(e => e.idProdotto === 263).length > 0;
};
export const okRoiPropPremium = userData => {
  return userData.categories.filter(e => e.idProdotto === 262).length > 0;
};
export const okRoiPropDiamond = userData => {
  return userData.categories.filter(e => e.idProdotto === 260).length > 0;
};
export const okRoiPropLessons = userData => {
  return userData.categories.filter(e => e.idProdotto === 264).length > 0;
};

//ROIDISCOVERY
export const okRoiDiscovery = userData => {
  return userData.categories.filter(e => e.idProdotto === 261).length > 0;
};

//PRINT MONEY
export const okPrintMoney = userData => {
  return (
    userData.categories.filter(e => e.descrizioneCategoria === "PrintMoney")
      .length > 0
  );
};
export const okPrintMoneyGold = userData => {
  return userData.categories.filter(e => e.idProdotto === 327).length > 0;
};
