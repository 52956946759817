/** @format */

import React, { useEffect, useRef, useState } from "react";
import { Table, Pagination, Input } from "antd";
import { Card, CardBody, CardHeader, CardTitle, Col, Row } from "reactstrap";
import "antd/dist/antd.css";
import { greenLong } from "components/StrumentiComponents/StrumentiComponents";
import { redShort } from "components/StrumentiComponents/StrumentiComponents";
import moment from "moment";
import { Checkbox } from "@mui/material";
import { cookieCreator } from "cookieCreator";
import Select from "react-select";
import { getCookie } from "cookieCreator";

const { Search } = Input;

const textColor = value => {
  switch (value) {
    case "LONG":
    case "COMPRA":
      return greenLong;

    case "BASSA":
      return "#988e75";

    case "MEDIA":
      return "#c3aa5b";

    case "ALTA":
      return "#ebc834";

    case "ALTISSIMA":
      return "#ffd700";
    default:
      return redShort;
  }
};

const concordanzaConvert = (a, b, c, d, e) => {
  let countLong = 0;
  let countShort = 0;
  if (a === "SHORT") countShort++;
  else if (a === "LONG") countLong++;
  if (b === "SHORT") countShort++;
  else if (b === "LONG") countLong++;
  if (c === "SHORT") countShort++;
  else if (c === "LONG") countLong++;
  if (d === "SHORT") countShort++;
  else if (d === "LONG") countLong++;
  if (e === "SHORT") countShort++;
  else if (e === "LONG") countLong++;

  return countLong > countShort
    ? countLong === 5
      ? "ESTREMA"
      : countLong === 4
      ? "ALTISSIMA"
      : countLong === 3
      ? "ALTA"
      : countLong === 2
      ? "MEDIA"
      : "BASSA"
    : countShort === 5
    ? "ESTREMA"
    : countShort === 4
    ? "ALTISSIMA"
    : countShort === 3
    ? "ALTA"
    : countShort === 2
    ? "MEDIA"
    : "BASSA";
};
const concordanzaConvertEtf = (a, b, c, d, e) => {
  let countCompra = 0;
  if (a === "COMPRA") countCompra++;
  if (b === "COMPRA") countCompra++;
  if (c === "COMPRA") countCompra++;
  if (d === "COMPRA") countCompra++;
  if (e === "COMPRA") countCompra++;

  const concordanza =
    countCompra === 5
      ? "ESTREMA"
      : countCompra === 4
      ? "ALTISSIMA"
      : countCompra === 3
      ? "ALTA"
      : countCompra === 2
      ? "MEDIA"
      : "BASSA";

  return concordanza;
};

const filters = [
  "UI™ Daily",
  "SBS™ Daily",
  "UI™ Weekly",
  "UIS™ Weekly",
  "UIS™ Monthly",
  "BES™",
  "Seasonality Mens.",
  "Seasonality Trim.",
];

const headers = [
  "Data",
  "UI™ Daily",
  "UI™ Weekly",
  "SBS™ Daily",
  "UIS™ Weekly",
  "UIS™ Monthly",
  "Concordanza",
];

const TableCalendar = props => {
  const [filteredData, setFilteredData] = useState(props.calendarData);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [monthsSelected, setMonthsSelected] = useState([]);
  const [concordanzaSelected, setConcordanzaSelected] = useState([]);

  React.useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        tableRef.current.style.maxHeight = `${window.innerHeight}px`;
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    // Al caricamento del componente, leggi i valori dei cookie e imposta i selezionati
    const monthsCookie = getCookie("monthsSelected");
    const concordanzaCookie = getCookie("concordanzaSelected");

    if (monthsCookie) {
      setMonthsSelected(monthsCookie.split(","));
    }
    if (concordanzaCookie) {
      setConcordanzaSelected(concordanzaCookie.split(","));
    }
  }, []);

  useEffect(() => {
    // Ogni volta che i valori selezionati cambiano, salvali nei cookie
    cookieCreator("monthsSelected", monthsSelected);
    cookieCreator("concordanzaSelected", concordanzaSelected);
    refreshTable();
  }, [monthsSelected, concordanzaSelected]);

  function refreshTable() {
    const filtered = props.calendarData.filter(customFilter);

    setFilteredData(filtered);
    setCurrentPage(1);
  }
  const customFilter = item => {
    // Verifica se l'elemento corrente soddisfa i criteri di filtro
    const concordanza =
      props.type === "etf"
        ? concordanzaConvertEtf(
            item.valueDaily,
            item.valueWeekly,
            item.valueSbs,
            item.valueUISWeekly,
            item.valueUISMonthly
          )
        : concordanzaConvert(
            item.valueDaily,
            item.valueWeekly,
            item.valueSbs,
            item.valueUISWeekly,
            item.valueUISMonthly
          );

    return (
      (monthsSelected.length === 0 ||
        monthsSelected.includes(
          moment(item.date).format("MMMM").toUpperCase()
        )) &&
      (concordanzaSelected.length === 0 ||
        concordanzaSelected.includes(concordanza))
    );
  };

  const handleSearch = value => {
    const searchValues = value.trim().toLowerCase().split(" ");
    const filtered = props.calendarData.filter(item => {
      const date = moment(item.date).format("LL").toLowerCase();
      const concordanza = concordanzaConvert(
        item.valueDaily,
        item.valueSbs,
        item.valueWeekly,
        item.valueUISWeekly,
        item.valueUISMonthly
      ).toLowerCase();
      return searchValues.some(searchValue => {
        return date.includes(searchValue) || concordanza.includes(searchValue);
      });
    });
    setFilteredData(filtered);
    setCurrentPage(1);
  };

  const handlePaginationChange = (page, size) => {
    setCurrentPage(page);
    setPageSize(size);
  };

  const pagination = {
    current: currentPage,
    pageSize: pageSize,
    total: filteredData.length,
    onChange: handlePaginationChange,
    showSizeChanger: true,
    pageSizeOptions: ["10", "20", "50", "100", "500"],
  };
  const tableRef = React.useRef(null);

  const keys = [
    "date",
    "valueDaily",
    "valueWeekly",
    "valueSbs",
    "valueUISWeekly",
    "valueUISMonthly",
    "concordanza",
  ];
  const concordanzaOrder = {
    ESTREMA: 5,
    ALTISSIMA: 4,
    ALTA: 3,
    MEDIA: 2,
    BASSA: 1,
  };
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "white" : "black",
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "red",
    }),
  };
  return (
    <Card>
      <CardHeader style={{ textAlign: "center" }}>
        <CardTitle tag="h2">CALENDARIO</CardTitle>
        <Row>
          {/* PRIMA SELECT */}

          <Col lg="6" xs="6">
            <Select
              value={monthsSelected.map(opt => ({
                label: opt,
                value: opt,
              }))}
              isMulti
              name="colors"
              placeholder="Seleziona i mesi da filtrare"
              onChange={selectedOptions => {
                const selectedValues = selectedOptions.map(
                  option => option.label
                );
                setMonthsSelected(selectedValues);
              }}
              options={months.map(opt => ({
                label: opt.toUpperCase(),
                value: opt,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              styles={customStyles}
            />
          </Col>
          {/* SECONDA SELECT */}
          <Col lg="6" xs="6">
            <Select
              value={concordanzaSelected.map(opt => ({
                label: opt,
                value: opt,
              }))}
              isMulti
              name="colors"
              placeholder="Seleziona le concordanze da filtrare"
              onChange={selectedOptions => {
                const selectedValues = selectedOptions.map(
                  option => option.label
                );
                setConcordanzaSelected(selectedValues);
              }}
              options={concordanzeinOrdine.map(opt => ({
                label: opt,
                value: opt,
              }))}
              className="basic-multi-select"
              classNamePrefix="select"
              styles={customStyles}
            />
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {/* <div className="table-header">
          <h5>Campo ricerca in fase di test...</h5>
          <Search
            placeholder="Filtra per Data o Concordanza (Puoi inserire più concordanze)"
            onSearch={handleSearch}
            style={{ width: "100%", marginBottom: 16, color: "black" }}
          />
        </div> */}
        <div
          ref={tableRef}
          style={{
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            maxHeight: "500px",
            backgroundColor: "black",
            borderRadius: 5,
          }}
        >
          <Table
            scroll={true}
            className="highlighted-row"
            rowClassName="custom-row"
            columns={headers.map((header, index) => {
              const key = keys[index];
              return {
                title: header,
                dataIndex: key,
                key: key,
                sorter: (a, b) => {
                  if (key === "date") {
                    return moment(a[key]).unix() - moment(b[key]).unix();
                  } else if (key === "concordanza") {
                    return concordanzaOrder[a[key]] - concordanzaOrder[b[key]];
                  } else {
                    return a[key].localeCompare(b[key]);
                  }
                },
              };
            })}
            dataSource={filteredData.map(item => ({
              ...item,
              key: item.date,
              date: moment(item.date).format("LL"),
              concordanza:
                props.type === "etf"
                  ? concordanzaConvertEtf(
                      item.valueDaily,
                      item.valueWeekly,
                      item.valueSbs,
                      item.valueUISWeekly,
                      item.valueUISMonthly
                    )
                  : concordanzaConvert(
                      item.valueDaily,
                      item.valueWeekly,
                      item.valueSbs,
                      item.valueUISWeekly,
                      item.valueUISMonthly
                    ),
            }))}
            pagination={pagination}
          />
        </div>
      </CardBody>
    </Card>
  );
};

export default TableCalendar;

const concordanzeinOrdine = ["BASSA", "MEDIA", "ALTA", "ALTISSIMA", "ESTREMA"];
const months = [
  "GENNAIO",
  "FEBBRAIO",
  "MARZO",
  "APRILE",
  "MAGGIO",
  "GIUGNO",
  "LUGLIO",
  "AGOSTO",
  "SETTEMBRE",
  "OTTOBRE",
  "NOVEMBRE",
  "DICEMBRE",
];
