/** @format */

import { Divider } from "@mui/material";
import YoutubeLinksDialog from "components/Dialogs/YoutubeLinksDialog";
import { PlayerComponent } from "components/ReactPlayer/PlayerComponent";
import { responseResources } from "fetchers/resources";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card, CardHeader, CardBody, CardTitle } from "reactstrap";
import { okRoiProp } from "variables/activeTools";
import { okAntipirla } from "variables/activeTools";
import { checkVideocorsiForex } from "variables/generalChecker";
import { okAntipirla5x } from "variables/activeTools";
import { okAntipirla10x } from "variables/activeTools";
import { RoiPropCardPlaylist } from "components/VideocorsiComponents/VideocorsiComponents";
import { AntipirlaCardPlaylist } from "components/VideocorsiComponents/VideocorsiComponents";
import { okRoiDiscovery } from "variables/activeTools";
import { RoiDiscoveryCardPlaylist } from "components/VideocorsiComponents/VideocorsiComponents";
import { RoiboosterCardPlaylist } from "components/VideocorsiComponents/VideocorsiComponents";
import { DaZeroATraderCardPlaylist } from "components/VideocorsiComponents/VideocorsiComponents";
import { okRoibooster } from "variables/activeTools";
import { okThousandollar } from "variables/activeTools";
import { okMilliondollar } from "variables/activeTools";
import { ThousandollarCardPlaylist } from "components/VideocorsiComponents/VideocorsiComponents";
import { MilliondollarCardPlaylist } from "components/VideocorsiComponents/VideocorsiComponents";
import { checkLibroExcel } from "variables/generalChecker";
import { okH1 } from "variables/activeTools";
import { RoiscalpingCardPlaylist } from "components/VideocorsiComponents/VideocorsiComponents";
import { checkMasterclassRoiProp } from "variables/generalChecker";
import { RoiPropMasterclassCardPlaylist } from "components/VideocorsiComponents/VideocorsiComponents";
function Videocorsi() {
  const [flagDialog, setFlagDialog] = useState(false);
  const [flagResources, setFlagResources] = useState(false);
  const [resources, setResources] = useState([]);
  const user = useSelector(state => state.user);
  const output = useSelector(state => state.output);

  useEffect(() => {
    async function GetRisorse() {
      const res = await responseResources();
      if (res.length > 0) {
        setResources(res);
        setFlagResources(true);
      }
    }
    GetRisorse();
  }, []);
  console.log(user);

  const videoRow = (risorsa, url, titolo, isOnDb, isPlaylist) => {
    return (
      <PlayerComponent
        risorsa={risorsa}
        url={url}
        isOnDb={isOnDb}
        isPlaylist={isPlaylist}
      />
    );
  };
  return (
    <>
      <div className="content">
        {flagResources ? (
          <>
            <Card>
              <CardHeader>
                <CardTitle tag="h1">Videocorsi</CardTitle>
                <span
                  onClick={() => setFlagDialog(!flagDialog)}
                  style={{
                    display: "flex",
                    cursor: "pointer",
                    color: "darkgray",
                  }}
                >
                  <img
                    alt=""
                    src={require("assets/img/info.png")}
                    width={20}
                    height={20}
                  />
                  <h5 style={{ marginLeft: 4 }}>
                    Non riesci a visualizzare i video?
                  </h5>
                </span>
              </CardHeader>
              <CardBody>
                {checkVideocorsiForex(user) &&
                  resources
                    .filter(x => x.tipo === "Vid" && x.idRisorsa != 26)
                    .map(x => {
                      return (
                        <div key={x.idRisorsa} style={{ marginBottom: 30 }}>
                          {videoRow(
                            x,
                            x.path,
                            mapIdIntoVideoTitle(x.idRisorsa),
                            true //isOnDBb
                          )}
                          <Divider light variant="inset" />
                        </div>
                      );
                    })}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle tag="h1">Playlist</CardTitle>
              </CardHeader>
              <CardBody>
                <div style={{ marginBottom: 30 }}>
                  {(okAntipirla(user) ||
                    okAntipirla5x(user) ||
                    okAntipirla10x(user)) && <AntipirlaCardPlaylist />}
                  {okRoiProp(user) && <RoiPropCardPlaylist />}
                  {okThousandollar(user) && <ThousandollarCardPlaylist />}
                  {okMilliondollar(user) && <MilliondollarCardPlaylist />}
                  {okRoiDiscovery(user) && <RoiDiscoveryCardPlaylist />}
                  {okRoibooster(user) && <RoiboosterCardPlaylist />}
                  {okH1(output) && <RoiscalpingCardPlaylist />}
                  {checkMasterclassRoiProp(user) && (
                    <RoiPropMasterclassCardPlaylist />
                  )}
                  <DaZeroATraderCardPlaylist />

                  <Divider light variant="inset" />
                </div>
              </CardBody>
            </Card>
            <YoutubeLinksDialog
              open={flagDialog}
              setModalOpen={setFlagDialog}
            />
          </>
        ) : (
          <>
            <YoutubeLinksDialog
              open={flagDialog}
              setModalOpen={setFlagDialog}
            />
          </>
        )}
      </div>
    </>
  );
}

export const links = {
  PrimoUtilizzoThousand:
    "https://www.youtube.com/playlist?list=PL8EVV1m3hI6k-YGtvWa5WuNRCVV8_wsPi",
  PrimoUtilizzoMillion:
    "https://www.youtube.com/playlist?list=PL8EVV1m3hI6mKIiMCNZyq9mGOLM2UEzim",
  PrimoUtilizzoRoibooster:
    "https://www.youtube.com/playlist?list=PL8EVV1m3hI6nuGBX70DxSjoauATspSO1v",
};

export const mapIdIntoVideoTitle = id => {
  switch (id) {
    case 23:
      return "Presentazione MTplatform™";
    case 24:
      return "Caricare e rimuovere l'Expert Advisor";
    case 25:
      return "Calcolo margine richiesto";
    case 26:
      return "Simulazione inserimento operazione";
    case 27:
      return "Come eseguire un back-test";
    case 28:
      return "Come installare l'Expert Advisor";
    case 100:
      return `  PLAYLIST 
          Pacchetto ANTIPIRLA™
       `;
    case 101:
      return `  PLAYLIST 
            Pacchetto ROIPROP™
         `;
    case 102:
      return `  PLAYLIST 
            Pacchetto ROIDISCOVERY™
         `;
    case 103:
      return `  PLAYLIST 
                Da ZERO a TRADER STATISTICO
             `;
    case 104:
      return `  PLAYLIST 
                    Pacchetto ROIBOOSTER™
                 `;
  }
};
export default Videocorsi;
