/** @format */

import httpClient from "fetchers";

export const responseRevoke = token =>
  httpClient("Login/revoke", true, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(response => response.json())
    .catch(error => console.log(error));
