/** @format */

import React, { useEffect, useState } from "react";

// reactstrap components
import { Card, CardHeader, CardTitle, Table, Badge } from "reactstrap";
import Select from "react-select";
import { routesFinancialTools } from "variables/generalChecker";
import moment from "moment";
import { useSelector } from "react-redux";
import { num } from "components/StrumentiComponents/StrumentiComponents";
import { greenLong } from "components/StrumentiComponents/StrumentiComponents";
import { redShort } from "components/StrumentiComponents/StrumentiComponents";
import { responseStoreBackTestH1Data } from "fetchers/storeBackTestData";

export default function StoreBacktestH1() {
  const [storeData, setStoreData] = useState([]);
  const [idStrumento, setIdStrumento] = useState("AUDCAD");
  const userData = useSelector(state => state.user.userInfo.username);
  useEffect(() => {
    async function getData() {
      const resStore = await responseStoreBackTestH1Data(idStrumento);
      if (resStore.length > 0) {
        setStoreData(resStore.filter((x, i) => i > resStore.length - 100));
      }
    }
    getData();
  }, [idStrumento]);

  function isProfit(openPrice, closePrice, direzione) {
    return direzione === "SHORT" && closePrice < openPrice
      ? true
      : direzione === "LONG" && closePrice > openPrice
      ? true
      : false;
  }

  return (
    <>
      <div className="content">
        {storeData.length > 0 ? (
          <>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Digita o seleziona il nome dello strumento
                </CardTitle>

                <Select
                  options={routesFinancialTools().map(opt => ({
                    label: opt.name,
                    value: opt.name,
                  }))}
                  placeholder={idStrumento}
                  onChange={opt => {
                    setIdStrumento(opt.label);
                  }}
                  styles={{
                    option: base => ({
                      ...base,
                      color: `black`,
                      height: "100%",
                    }),
                  }}
                />
              </CardHeader>
            </Card>
            <Card>
              <Table className="tablesorter" responsive>
                <thead className="text-primary">
                  <tr>
                    <th className="text-center">Data</th>
                    <th className="text-center">Ora</th>
                    <th className="text-center">Direzione</th>
                    <th className="text-center">
                      Livello Ingresso <p style={{ fontSize: 10 }}>Previsto</p>
                    </th>
                    {/* <th className="text-center">
                      Livello Setup <p style={{ fontSize: 10 }}>Previsto</p>
                    </th>
                    <th className="text-center">
                      Target <p style={{ fontSize: 10 }}>Previsto</p>
                    </th> */}
                    <th className="text-center">
                      Apertura giornaliera{" "}
                      <p style={{ fontSize: 10 }}>Effettiva</p>
                    </th>
                    <th className="text-center">
                      Chiusura giornaliera{" "}
                      <p style={{ fontSize: 10 }}>Effettiva</p>
                    </th>
                    <th className="text-center">Affidabilità</th>
                    <th className="text-center">Profitto</th>
                  </tr>
                </thead>
                <tbody>
                  {storeData.map(obj => {
                    return (
                      <tr key={obj.idRecord}>
                        <td className="text-center">
                          {moment(obj.periodDate).format("DD/MM/YY")}
                        </td>
                        <td className="text-center">{obj.hour}</td>
                        <td className="text-center">{obj.direzione}</td>
                        <td className="text-center">
                          {num(obj.livelloIngresso)}
                        </td>
                        {/* <td className="text-center">{num(obj.livelloSetup)}</td>
                        <td className="text-center">{num(obj.target)}</td> */}
                        <td className="text-center">{num(obj.openPrice)}</td>
                        <td className="text-center">{num(obj.closePrice)}</td>
                        <td className="text-center">{num(obj.affidabilita)}</td>
                        <td className="text-center">
                          {isProfit(
                            obj.openPrice,
                            obj.closePrice,
                            obj.direzione
                          ) ? (
                            <Badge style={{ backgroundColor: greenLong }}>
                              PROFIT
                            </Badge>
                          ) : (
                            <Badge style={{ backgroundColor: redShort }}>
                              LOSS
                            </Badge>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}
