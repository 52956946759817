/** @format */

import { currenciesFlags } from "assets/currenciesFlags/currenciesFlags";
import { moduloColInCardXs } from "components/Modules/ModuleComponents";
import { redShort } from "components/StrumentiComponents/StrumentiComponents";
import { greenLong } from "components/StrumentiComponents/StrumentiComponents";
import React, { useEffect } from "react";
import { Line } from "react-chartjs-2";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { assetsClasses } from "variables/correlationFilter";
import { checkType } from "variables/generalChecker";
import { isLong } from "variables/generalChecker";

export default function CorrelationTab(
  outputStrumenti,
  setSelected,
  setAsset,
  setPositionSelected
) {
  const [activeTab, setActiveTab] = React.useState("USD");
  const [position, setPosition] = React.useState(true);

  useEffect(() => {
    setSelected(selected(activeTab));
    setPositionSelected(position);
    setAsset(activeTab);
  }, [activeTab, position]);
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const selected = asset =>
    outputStrumenti
      .filter(
        x =>
          x.idStrumento.substring(0, 3) === asset ||
          x.idStrumento.substring(3) === asset ||
          checkType(x.idStrumento) === asset
      )
      .filter(x =>
        asset === "INDEX" || asset === "COMMODITIES" || asset === "JPY"
          ? x
          : position
          ? x.idStrumento.substring(0, 3) === asset
          : x.idStrumento.substring(3) === asset
      );

  const _usdTable = asset => {
    return (
      <Row>
        {selected(asset).map(output => (
          <Col key={output.idStrumento} lg="6" md="6">
            <Row style={{ padding: 20 }}>
              <Col
                lg="1"
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  // backgroundColor: "green",
                }}
              >
                {currenciesFlags(output.idStrumento.toLowerCase())}
              </Col>
              <Col
                lg="4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // backgroundColor: "yellow",
                }}
              >
                <p
                  style={{
                    fontSize: 13,
                    fontWeight: "bold",
                    marginTop: 8,
                  }}
                >
                  {output.idStrumento}
                </p>
              </Col>

              <Col
                lg="4"
                style={{
                  display: "flex",
                  justifyContent: "start",
                  // backgroundColor: "blue",
                  alignItems: "center",
                }}
              >
                <Badge
                  style={{
                    fontSize: 12,
                    backgroundColor: isLong(output.filtroDirezione)
                      ? greenLong
                      : redShort,
                    color: "white",
                    cursor: "copy",
                  }}
                >
                  {output.filtroDirezione
                    ? output.filtroDirezione
                    : "NON RILEVATO"}
                </Badge>
              </Col>
            </Row>
          </Col>
        ))}
      </Row>
    );
  };

  return (
    <div>
      <i>
        Clicca due volte sulla scheda per alternare la posizione della valuta.
      </i>
      <Nav tabs style={{ cursor: "pointer" }}>
        {assetsClasses.map(x => {
          return (
            <NavItem key={x}>
              <NavLink
                className={activeTab === x ? "active" : ""}
                style={{ color: "white" }}
                onClick={() => {
                  toggle(x);
                  setPosition(!position);
                }}
              >
                {x}
              </NavLink>
            </NavItem>
          );
        })}
      </Nav>
      <TabContent activeTab={activeTab} style={{ marginTop: 30 }}>
        {assetsClasses.map(x => {
          return (
            <TabPane tabId={x} key={x}>
              {_usdTable(x)}
            </TabPane>
          );
        })}
      </TabContent>
    </div>
  );
}

{
  /* <Row style={{ paddingRight: 20, paddingLeft: 20 }}>
                <Line
                  width={60}
                  height={30}
                  data={chartUSDCorrelation(output)}
                  options={chartUSDCorrelation(output).options}
                />
              </Row> */
}
