/** @format */

import { useSelector } from "react-redux";
import { ReactComponent as Arrow } from "assets/img/arrow_curved.svg";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import ReactCardFlip from "react-card-flip";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  greenLong,
  redShort,
} from "components/StrumentiComponents/StrumentiComponents";
import { moduloColInCardSm } from "components/Modules/ModuleComponents";
import { num } from "components/StrumentiComponents/StrumentiComponents";
import { checkIsAdmin } from "variables/generalChecker";

export const StrumentoFrontCardH1 = props => {
  const out = props.output;
  const [isFlipped, setIsFlipped] = useState(false);
  function handleClick() {
    setIsFlipped(!isFlipped);
  }
  return (
    <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
      {FrontCardH1(handleClick, out, props.user)}
      {BackCardH1(handleClick, out, props.user)}
    </ReactCardFlip>
  );
};

export const FrontCardH1 = (handleClick, out, user) => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardHeader>
        <CardTitle
          className="h3"
          onClick={() =>
            navigate(`/admin/strumento/${out.idStrumento.toLowerCase()}`)
          }
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          {out.idStrumento}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          {moduloColInCardSm(
            "Livello ingresso",
            out.livelloIngresso.toFixed(4),
            "#91684A",
            true,
            false,
            false
          )}
          {checkIsAdmin(user.userInfo.username) &&
            moduloColInCardSm(
              "Target",
              out.target.toFixed(4),
              "#350B40",
              true,
              false,
              false
            )}
          {moduloColInCardSm(
            "Direzione oraria",
            out.filtroDirezione.trim(),
            out.filtroDirezione.trim() === "LONG" ? greenLong : redShort,
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Affidabilità",
            (out.affidabilita * 100).toFixed(2) + "%",
            out.affidabilita > 0.5 ? "#228B22" : "#DE970B",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Profittabilità",
            ((out.profittabilita + 0.2) * 100).toFixed(2) + "%",
            out.profittabilita + 0.2 > 0.4
              ? "#228B22" // verde
              : out.profittabilita > 0.2
              ? "#DE970B" // giallo
              : "#bc4143", // rosso,
            true,
            false,
            false
          )}
        </Row>
        <img
          alt=">"
          src={require("assets/img/triangle_gold.png")}
          onClick={handleClick}
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: 20,
            height: 20,
            rotate: "-90deg",
            cursor: "pointer",
          }}
        />
      </CardBody>
      <CardFooter />
    </Card>
  );
};
export const BackCardH1 = (handleClick, out, user) => {
  const navigate = useNavigate();

  return (
    <Card>
      <CardHeader>
        <CardTitle
          className="h3"
          onClick={() =>
            navigate(`/admin/strumento/${out.idStrumento.toLowerCase()}`)
          }
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            fontWeight: "bold",
          }}
        >
          {out.idStrumento}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          {moduloColInCardSm(
            "Livello ingresso",
            out.livelloIngresso.toFixed(4),
            "#91684A",
            true,
            false,
            false
          )}
          {checkIsAdmin(user.userInfo.username) &&
            moduloColInCardSm(
              "Target",
              out.target.toFixed(4),
              "#350B40",
              true,
              false,
              false
            )}
          {moduloColInCardSm(
            "Direzione oraria",
            out.filtroDirezione.trim(),
            out.filtroDirezione.trim() === "LONG" ? greenLong : redShort,
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Affidabilità",
            (out.affidabilita * 100).toFixed(2) + "%",
            out.affidabilita > 0.5 ? "#228B22" : "#DE970B",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Profittabilità",
            ((out.profittabilita + 0.2) * 100).toFixed(2) + "%",
            out.profittabilita + 0.2 > 0.4
              ? "#228B22" // verde
              : out.profittabilita > 0.2
              ? "#DE970B" // giallo
              : "#bc4143", // rosso,
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Margine Medio",
            // (out.margineCentrale * 100).toFixed(0) + "%",
            "125%",
            "#008b8b",
            true,
            false,
            false
          )}
          {moduloColInCardSm(
            "Margine Massimo",
            // (out.margineMassimo * 100).toFixed(0) + "%",
            "150%",
            "#00008b",
            true,
            false,
            false
          )}
        </Row>
        <img
          alt=">"
          src={require("assets/img/triangle_gold.png")}
          onClick={handleClick}
          style={{
            position: "absolute",
            right: 0,
            bottom: 0,
            width: 20,
            height: 20,
            rotate: "90deg",
            cursor: "pointer",
          }}
        />
      </CardBody>
      <CardFooter />
    </Card>
  );
};
