/** @format */

export default function serviceCardImg(img1, img2) {
  switch (img1) {
    case "Facebook ThousanDollarInvestor":
    case "Facebook ThousanDollaroInvestor":
    case "Facebook MillionDollarInvestor":
    case "Thousandollar":
      img1 = "Thousandollar";
      break;
    case "Milliondollar":
      img1 = "Milliondollar";
      break;
    case "Facebook RoiTrading":
    case "RoiTrading":
      img1 = "RoiTrading";
      break;
    case "Facebook RoiBooster":
    case "RoiBooster":
      img1 = "RoiBooster";
      break;
    case "roilottery":
      img1 = "roilottery";
      break;
    case "TheWolfOfIndexes":
      img1 = "TheWolfOfIndexes";
      break;
    default:
      img1 = img1;
      break;
  }
  return (
    <>
      <img
        alt={img1}
        src={require(`assets/img/${img1}.png`)}
        width={"20%"}
        height={"20%"}
        style={{ alignSelf: "center" }}
      />
      <img
        alt={img2}
        src={require(`assets/img/${img2}.png`)}
        width={img2 === "whatsapp" ? 65 : 35}
        height={img2 === "whatsapp" ? 65 : 35}
        style={{
          position: "absolute",
          left: img2 === "whatsapp" ? -25 : -10,
          top: img2 === "whatsapp" ? -25 : -10,
          borderRadius: "100%",
        }}
      />
    </>
  );
}
