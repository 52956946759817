/** @format */
import httpClient from "fetchers";

export const responseOutputStrumenti = token =>
  httpClient("OutputStrumenti", true, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(response => response.json())
    .catch(error => console.log(error));

    export const responseOutputStrumentiH1 = token =>
  httpClient("OutputStrumentiH1", true, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(response => response.json())
    .catch(error => console.log(error));

    export const responseOutputStrumentoH1_all = (token,idStrumento) =>
  httpClient(`OutputStrumentiH1/all/${idStrumento}`, true, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(response => response.json())
    .catch(error => console.log(error));

    export const responseOutputStrumentoH1_current = (token,idStrumento)  =>
    httpClient(`OutputStrumentiH1/current/${idStrumento}`, true, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then(response => response.json())
      .catch(error => console.log(error));
  
