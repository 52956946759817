/** @format */

import { AudioComponent } from "components/ReactPlayer/AudioComponent";
import React, { useState } from "react";
// reactstrap components
import { Card, CardHeader, CardBody, Row, Col, CardTitle } from "reactstrap";

function Audiocorsi() {
  const audioRow = (risorsa, url, titolo) => {
    return (
      <AudioComponent key={url} risorsa={risorsa} url={url} titolo={titolo} />
    );
  };

  return (
    <>
      <div className="content">
        <Card>
          <CardHeader>
            <CardTitle tag="h1">Audiocorsi</CardTitle>
          </CardHeader>
          <CardBody>
            <h3>ROIDISCOVERY™️</h3>
            <h4>
              <a
                href="https://drive.google.com/drive/folders/1uVzhoNv-qo3ONnOLrBwpRC7GbXPqADpq?usp=sharing"
                target="_blank"
              >
                Vai ai file
              </a>
            </h4>
          </CardBody>
        </Card>
      </div>
    </>
  );
}

export const mapIdIntoVideoTitle = id => {
  switch (id) {
  }
};
export default Audiocorsi;

export const soundcloud_links = [
  "https://drive.google.com/file/d/1sqwo5kocQcE5jQCcXJ-dtrgdbnCegH-d/view?usp=drive_link",
];
export const audioTitles = [
  "ROIDISCOVERY 01 - [Analisi Fondamentale]",
  "ROIDISCOVERY 02 - [Come Utilizzare La Statistical Ai™️]",
  "ROIDISCOVERY 03 - [Da povero a milionario]",
  "ROIDISCOVERY 04 - [Francesca Bianchini Esperta Di Economia E Finanza]",
  "ROIDISCOVERY 05 - [MONEYMANAGEMENT]",
];
