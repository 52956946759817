/** @format */

export const setUserCategories = categories => {
  return {
    type: "SET_CATEGORIES",
    payload: categories,
  };
};

export const setUserInfo = info => {
  return {
    type: "SET_USER_INFO",
    payload: info,
  };
};

export const setFavorites = favorites => {
  return {
    type: "SET_FAVORITES",
    payload: favorites,
  };
};

export const addFavorite = favorite => {
  return {
    type: "ADD_FAVORITE",
    payload: favorite,
  };
};
