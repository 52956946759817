/** @format */
import { cookieCreator } from "cookieCreator";
import httpClient from "fetchers";

export const responseLogin = credentials => {
  return httpClient("Login", false, {
    method: "POST",
    body: JSON.stringify(credentials),
  })
    .then(response => response.json())
    .then(response => {
      cookieCreator("token", response.token);
      cookieCreator("refreshToken", response.refreshToken);
      return response;
    })
    .catch(error => console.log("Error detected: " + error));
};
