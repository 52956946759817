/** @format */
import httpClient from "fetchers";

export const responseRefreshLogin = tokensBody =>
  httpClient("Login/refresh", false, {
    method: "POST",
    headers: {
      accept: "text/plain",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(tokensBody),
  })
    .then(response => response.json())
    .catch(error => console.log(error));
