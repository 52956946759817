/** @format */

import React from "react";
import { useSelector } from "react-redux";

// reactstrap components
import { Row, Col } from "reactstrap";
import { RoiPropCardLinkIndicatori } from "components/RoiPropComponents/RoiPropComponents";
import { PrintMoneyCardLinkTelegram } from "components/PrintMoneyComponents/PrintMoneyComponents";
import { PrintMoneyHeader } from "components/PrintMoneyComponents/PrintMoneyComponents";
import { okPrintMoneyGold } from "variables/activeTools";
import { PrintMoneyCardLinkMasterClass } from "components/PrintMoneyComponents/PrintMoneyComponents";
import { CardContoTeletrade } from "components/DashboardComponents/DashboardComponents";
import { PrintMoneyCardContoTeletrade } from "components/PrintMoneyComponents/PrintMoneyComponents";
import { PrintMoneyCardLinkIndicatori } from "components/PrintMoneyComponents/PrintMoneyComponents";
import { PrintMoneyCardConsulenzaOneToOne } from "components/PrintMoneyComponents/PrintMoneyComponents";

export default function PrintMoney() {
  const user = useSelector(state => state.user);
  return (
    <>
      <div className="content">
        <Row>
          <Col xs="12" md="6">
            <PrintMoneyHeader />
          </Col>
          <Col xs="12" md="6">
            <PrintMoneyCardLinkTelegram />
            <PrintMoneyCardLinkIndicatori />
            <PrintMoneyCardConsulenzaOneToOne />
          </Col>
        </Row>
      </div>
    </>
  );
}
