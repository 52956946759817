/** @format */

import { useState } from "react";
import Papa from "papaparse";

export const IrFilterData = (dataIR, output, idStrumento, general) => {
  const addEsiti = output.filter(
    x => x.esitoDirezionalita === x.filtroDirezione
  );
  const irCurrenciesVsUSD = dataIR.filter((x, i) => i > 32 && i < 41);

  const concordantiConDirezione = addEsiti.filter(e => {
    const num = e.idStrumento.substring(0, 3);
    const den = e.idStrumento.substring(3);
    const objNumDaIr = irCurrenciesVsUSD.filter(
      x => x["Banca Centrale"] === num
    )[0];
    const objDenDaIr = irCurrenciesVsUSD.filter(
      x => x["Banca Centrale"] === den
    )[0];
    const esitoNumDaIr = objNumDaIr ? objNumDaIr["Ultimo Cambio"] : "";
    const esitoDenDaIr = objDenDaIr ? objDenDaIr["Ultimo Cambio"] : "";
    const esitoThis = e.filtroDirezione;

    return esitoThis === "LONG"
      ? esitoNumDaIr === "LONG" && esitoDenDaIr === "SHORT"
      : esitoNumDaIr === "SHORT" && esitoDenDaIr === "LONG";
  });

  if (general) return concordantiConDirezione;
  else
    return (
      concordantiConDirezione.filter(x => x.idStrumento === idStrumento)
        .length > 0
    );
};
export default IrFilterData;
