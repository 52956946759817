/** @format */

import { useState } from "react";
import "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";

const API_KEY = "sk-proj-LYG2EMWeSFHaLSrmmyvjT3BlbkFJtvqjhSUKFgT8ZTIH1WH0";

function ChatGPTSection(props) {
  const [messages, setMessages] = useState([
    {
      message:
        "Ciao! Sono l'assistente virtuale della MTplatform, per adesso mi occuperò esclusivamente degli strumenti finanziari legati al dollaro USD! Come posso essere utile?",
      sentTime: "just now",
      sender: "ChatGPT",
    },
  ]);

  const outputCompact = props?.output.strumenti
    .filter(
      x =>
        x.idStrumento.substring(3) === "USD" ||
        x.idStrumento.substring(0, 3) === "USD"
    )
    .filter(x => x.idStrumento != "BTCUSD")
    .map(x => {
      return {
        name: x.idStrumento,
        esito: x.filtroDirezione,
        today: x.today,
        affidabilita: x.affidabilita,
        currentPrice: x.currentPrice,
        direzionalita: x.esitoDirezionalita,
        ulcerIndex: x.esitoUlcerIndex,
        idStrumento: x.idStrumento,
        livelloSetup: x.ingressoBes,
        leva: x.leva,
        ingresso: x.livelloIngresso,
        roi: x.roi,
        target: x.target,
      };
    });

  const systemMessage = {
    //  Explain things like you're talking to a software professional with 5 years of experience.
    role: "system",
    content:
      "Sei l'intelligenza artificiale collegata alla mente di Manuel Trotta, ti chiami Manuel Trotta, un esperto di statistica legata ai mercati finanziari, esperto trader." +
      " Di seguito allegherò in formato stringa, un array di oggetti che descrivono i risultati statistici di alcuni strumenti finanziari legati al dollaro. Risponderai alle domande in base ai dati contenuti all'interno di ogni oggetto. Non devi mai menzionare che tu stia leggendo i dati da un array che ti ho fotnito io, ma devi solamente dire che leggi i dati dal data center di MTplatform. Non dire mai frasi tipo 'contenuti nell'array:' o cose del genere. Quando parli del ROI, moltiplicalo per 100. Questo è l'array di oggetti: " +
      JSON.stringify(outputCompact),
  };
  const [isTyping, setIsTyping] = useState(false);
  const handleSend = async message => {
    const newMessage = {
      message,
      direction: "outgoing",
      sender: "user",
    };

    const newMessages = [...messages, newMessage];

    setMessages(newMessages);

    // Initial system message to determine ChatGPT functionality
    // How it responds, how it talks, etc.
    setIsTyping(true);
    await processMessageToChatGPT(newMessages);
  };

  async function processMessageToChatGPT(chatMessages) {
    // messages is an array of messages
    // Format messages for chatGPT API
    // API is expecting objects in format of { role: "user" or "assistant", "content": "message here"}
    // So we need to reformat

    let apiMessages = chatMessages.map(messageObject => {
      let role = "";
      if (messageObject.sender === "ChatGPT") {
        role = "assistant";
      } else {
        role = "user";
      }
      return {
        role: role,
        content: messageObject.message, // + outputStrumenti,
      };
    });

    // Get the request body set up with the model we plan to use
    // and the messages which we formatted above. We add a system message in the front to'
    // determine how we want chatGPT to act.
    const apiRequestBody = {
      model: "gpt-3.5-turbo",
      messages: [
        systemMessage, // The system message DEFINES the logic of our chatGPT
        ...apiMessages, // The messages from our chat with ChatGPT
      ],
    };

    await fetch("https://api.openai.com/v1/chat/completions", {
      method: "POST",
      headers: {
        Authorization: "Bearer " + API_KEY,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(apiRequestBody),
    })
      .then(data => {
        return data.json();
      })
      .then(data => {
        // console.log(data);
        setMessages([
          ...chatMessages,
          {
            message: data.choices[0].message.content,
            sender: "ChatGPT",
          },
        ]);
        setIsTyping(false);
      });
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>STATISTICAL AI</CardTitle>
      </CardHeader>
      <CardBody>
        <div style={{ position: "relative", height: 500, marginBottom: 30 }}>
          <MainContainer>
            <ChatContainer>
              <MessageList
                scrollBehavior="smooth"
                typingIndicator={
                  isTyping ? (
                    <TypingIndicator content="MTplatform sta scrivendo..." />
                  ) : null
                }
              >
                {messages.map((message, i) => {
                  // console.log(message);
                  return <Message key={i} model={message} />;
                })}
              </MessageList>
              <MessageInput
                placeholder="Type message here"
                onSend={handleSend}
              />
            </ChatContainer>
          </MainContainer>
        </div>
      </CardBody>
    </Card>
  );
}

export default ChatGPTSection;
