/** @format */

import { setRefreshToken } from "actions/token";
import { setTokenFromCookie } from "actions/token";
import { responseLogin } from "fetchers/login";
// import { useRef } from "react";
import { cookieCreator } from "../../cookieCreator";

export function setLoginValues(credentials, remember) {

}

export const setLoginEmpty = () => {
  cookieCreator("token", "");
  cookieCreator("refreshToken", "");
  return async dispatch => {
    dispatch({
      type: "SET_LOGIN_EMPTY",
    });
  };
};
