/** @format */

import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import React, { useEffect, useState } from "react";
import { CardOutputStrumento } from "components/StrumentiComponents/StrumentiComponents";

// reactstrap components
import { Row, Col } from "reactstrap";

import { CardUlcerIndex } from "components/StrumentiComponents/StrumentiComponents";
import { useDispatch, useSelector } from "react-redux";
import { YymmddChart } from "components/StrumentiComponents/ChartCards";
import { useLocation, useNavigate } from "react-router-dom";
import { CardBestFiltro } from "components/StrumentiComponents/StrumentiComponents";
import { CardSeasonality } from "components/StrumentiComponents/StrumentiComponents";
import { CardMargini } from "components/StrumentiComponents/StrumentiComponents";
import { CardBes } from "components/StrumentiComponents/StrumentiComponents";
import { responseBigChartData } from "fetchers/bigChartData";
import { CardAddFilter } from "components/StrumentiComponents/StrumentiComponents";
import { ThemeContext } from "contexts/ThemeContext";
import { CardSbs } from "components/StrumentiComponents/StrumentiComponents";
import { ChartAddFilter } from "components/StrumentiComponents/ChartCards";
import { responseSentimentFilter } from "fetchers/sentimentFilter";
import { responseCalendarData } from "fetchers/calendarData";
import { CardSentimentFilter } from "components/StrumentiComponents/StrumentiComponents";
import { CardIrFilter } from "components/StrumentiComponents/StrumentiComponents";
import Papa from "papaparse";
import { checkActiveWithPath } from "variables/generalChecker";
import { checkWhichStrumentHasAddFilter } from "variables/generalChecker";
import { responseSettings } from "fetchers/settings";
import TableCalendar from "components/Tables/TableCalendar";
import moment from "moment";
import { okH1 } from "variables/activeTools";
import { CardOutputStrumentoH1 } from "components/StrumentiComponents/StrumentiComponentsH1";
import { CardSbsH1 } from "components/StrumentiComponents/StrumentiComponentsH1";
import { CardAddFilterH1 } from "components/StrumentiComponents/StrumentiComponentsH1";
import { CardIrFilterH1 } from "components/StrumentiComponents/StrumentiComponentsH1";
import { CardBesH1 } from "components/StrumentiComponents/StrumentiComponentsH1";
import { CardBestFiltroH1 } from "components/StrumentiComponents/StrumentiComponentsH1";
import { CardMarginiH1 } from "components/StrumentiComponents/StrumentiComponentsH1";
import { checkFromIdStrumento } from "variables/generalChecker";
import { checkFromIdStrumentoH1 } from "variables/generalChecker";
import { Switch } from "@mui/material";
import { okD1 } from "variables/activeTools";
import { HHChart } from "components/StrumentiComponents/ChartCards";
import Loader from "components/Loader/Loader";

function StrumentoTemplate(props) {
  const dispatch = useDispatch();
  const [flagOutputD1, setFlagOutputD1] = useState(false);
  const [flagOutputH1, setFlagOutputH1] = useState(false);
  const [flagChart, setFlagChart] = useState(false);
  const [flagCalendar, setFlagCalendar] = useState(false);
  const [flagSentiment, setFlagSentiment] = useState(false);
  const [dataIR, setDataIR] = useState([]);
  const [dataChart, setDataChart] = useState([]);
  const [sentimentData, setSentimentData] = useState([]);
  const [calendarData, setCalendarData] = useState(null);
  const [settings, setSettings] = useState(null);
  const [checkedd1h1, setcheckedd1h1] = React.useState(false); //false per d1

  const idStrumento = useLocation().pathname.split("/")[3];
  const navigate = useNavigate();
  const output = useSelector(state => state.output);
  const user = useSelector(state => state.user);

  useEffect(() => {
    async function setFlags() {
      setFlagOutputD1(false);
      setFlagOutputH1(false);
      if (checkFromIdStrumento(idStrumento, user)) {
        setFlagOutputD1(true);
      }
      if (checkFromIdStrumentoH1(idStrumento, user)) {
        setFlagOutputH1(true);
        if (!flagOutputD1) setcheckedd1h1(true);
      }
      if (
        !checkFromIdStrumento(idStrumento, user) &&
        !checkFromIdStrumentoH1(idStrumento, user)
      )
        navigate("/admin");
    }
    setFlags();
  }, [idStrumento]);

  useEffect(() => {
    async function getResponse() {
      const responseChart = await responseBigChartData(idStrumento);
      const responseSettingss = await responseSettings();

      if (responseSettingss?.length > 0) {
        setSettings(responseSettingss);
      }

      if (responseChart?.yearValues?.length > 0) {
        setDataChart(responseChart);
        setFlagChart(true);
        // await dispatch(setDataCharts(idStrumento, responseChart));
      }
    }
    getResponse();
  }, [idStrumento]);

  useEffect(() => {
    async function getResponse() {
      const responseSentiment = await responseSentimentFilter();
      const responseCalendar = await responseCalendarData(
        idStrumento.toLocaleUpperCase()
      );

      //CHECK CHART

      //CHECK CALENDAR
      if (responseCalendar?.finalCalendarList.length > 0) {
        setCalendarData(responseCalendar);
        setFlagCalendar(true);
      } else setFlagCalendar(false);
      //CHECK SENTIMENT
      if (!responseSentiment.error) {
        setSentimentData(
          responseSentiment.symbols.filter(x =>
            idStrumento === "de30"
              ? x.name === "GER30"
              : idStrumento === "crudeoil"
              ? x.name === "XTIUSD"
              : idStrumento === "silver"
              ? x.name === "XAGUSD"
              : idStrumento === "nasdaq"
              ? x.name === "NAS100"
              : idStrumento === "sp500"
              ? x.name === "SP500"
              : idStrumento === "dowjones"
              ? x.name === "US30"
              : x.name === idStrumento.toUpperCase()
          )
        );
        setFlagSentiment(true);
      }
    }
    getResponse();
  }, [idStrumento]);

  useEffect(() => {
    const showsUrl =
      "https://docs.google.com/spreadsheets/d/15nfozHCgDwCD1h9KHS_PC0kPheZs9NPTmi0gLmkyjLY/pub?gid=0&single=true&output=csv";

    Papa.parse(showsUrl, {
      download: true,
      header: true,
      complete: results => {
        setDataIR(results.data);
      },
    });
  }, []);

  const currencyOut = output.strumenti.filter(
    x => x.idStrumento === idStrumento.toLocaleUpperCase()
  )[0];
  const currencyOutH1 = output.strumentiH1.filter(
    x =>
      x.idStrumento === idStrumento.toLocaleUpperCase() &&
      x.hour === moment().hour()
  )[0];

  const handleChange = event => {
    // setcheckedd1h1(event.target.checked);
    setcheckedd1h1(!checkedd1h1);
  };
  console.log(okD1(output));
  return (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <BackgroundColorContext.Consumer>
          {({ color }) => (
            <>
              <div className="content">
                {currencyOut != undefined && currencyOutH1 != undefined && (
                  <Row>
                    <Col xs="12" className="items-center">
                      <p style={{ paddingTop: 13, fontWeight: "bold" }}>
                        GIORNALIERO
                      </p>
                      <div style={{ position: "relative" }}>
                        <Switch
                          checked={checkedd1h1 ?? false}
                          // onChange={handleChange}
                          // inputProps={{ "aria-label": "controlled" }}
                        />
                        <div
                          style={{
                            zIndex: 1000,
                            width: 50,
                            height: 50,
                            position: "absolute",
                            top: 0,
                            left: 0,
                          }}
                          onClick={handleChange}
                        ></div>
                      </div>

                      <p style={{ paddingTop: 13, fontWeight: "bold" }}>
                        ORARIO
                      </p>
                    </Col>
                  </Row>
                )}

                {/* STATISTICA D1*/}
                {currencyOut != undefined && !checkedd1h1 && (
                  <>
                    {/* GRAFICO */}
                    <Row>
                      <Col
                        xs="12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        {flagChart ? (
                          <YymmddChart
                            idStrumento={idStrumento.toLocaleUpperCase()}
                            dataChart={dataChart}
                          />
                        ) : (
                          <div style={{ marginTop: 50 }}>
                            <Loader />
                          </div>
                        )}
                      </Col>
                    </Row>
                    {/* DATI */}
                    <Row>
                      <Col
                        lg={window.innerWidth < 1200 ? "12" : "6"}
                        md="12"
                        style={{ padding: 25, paddingBottom: 0, paddingTop: 0 }}
                      >
                        {CardOutputStrumento(
                          currencyOut,
                          false,
                          "",
                          navigate,
                          settings,
                          user,
                          user.favorites,
                          dispatch
                        )}
                        <CardSbs output={currencyOut} userData={user} />
                        <CardSeasonality output={currencyOut} userData={user} />

                        {checkWhichStrumentHasAddFilter(idStrumento) ? (
                          <>
                            <Row>
                              <CardAddFilter output={currencyOut} />
                            </Row>
                          </>
                        ) : (
                          <></>
                        )}
                        <CardIrFilter
                          currencyOut={currencyOut}
                          dataIR={dataIR}
                          output={output.strumenti}
                        />
                      </Col>
                      <Col lg={window.innerWidth < 1200 ? "12" : "6"} md="12">
                        {user.categories.filter(
                          x =>
                            x.descrizioneCategoria === "Thousandollar" ||
                            x.descrizioneCategoria === "Milliondollar" ||
                            x.descrizioneCategoria === "TheWolfOfIndexes"
                        ).length > 0 ? (
                          <CardBes
                            idStrumento={idStrumento}
                            output={currencyOut}
                            userData={user}
                            settings={settings}
                          />
                        ) : (
                          <></>
                        )}
                        <CardBestFiltro output={currencyOut} />
                        <CardMargini output={currencyOut} />
                        <CardUlcerIndex output={currencyOut} userData={user} />
                      </Col>
                      {checkWhichStrumentHasAddFilter(idStrumento) && (
                        <ChartAddFilter
                          output={output.strumenti}
                          idStrumento={idStrumento}
                        />
                      )}
                    </Row>
                  </>
                )}
                {/* STATISTICA H1 */}
                {currencyOutH1 != undefined && checkedd1h1 && (
                  <>
                    {/* GRAFICO */}
                    <Row>
                      <Col
                        xs="12"
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <HHChart
                          idStrumento={idStrumento.toLocaleUpperCase()}
                          dataChart={output.strumentiH1
                            .filter(
                              x =>
                                x.idStrumento ===
                                idStrumento.toLocaleUpperCase()
                            )
                            .filter(x => x.hour <= moment().hour())}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col
                        lg={window.innerWidth < 1200 ? "12" : "6"}
                        md="12"
                        style={{
                          padding: 25,
                          paddingBottom: 0,
                          paddingTop: 0,
                        }}
                      >
                        {CardOutputStrumentoH1(
                          currencyOutH1,
                          false,
                          "",
                          navigate,
                          settings,
                          user,
                          user.favorites,
                          dispatch
                        )}
                        <CardSbsH1 output={currencyOutH1} userData={user} />
                        {checkWhichStrumentHasAddFilter(idStrumento) ? (
                          <CardAddFilterH1 output={currencyOutH1} />
                        ) : (
                          <></>
                        )}
                        <CardIrFilterH1
                          currencyOut={currencyOutH1}
                          dataIR={dataIR}
                          output={output.strumentiH1}
                        />
                      </Col>
                      <Col lg={window.innerWidth < 1200 ? "12" : "6"} md="12">
                        <CardBesH1
                          idStrumento={idStrumento}
                          output={currencyOutH1}
                          userData={user}
                          settings={settings}
                        />
                        <CardBestFiltroH1 output={currencyOutH1} />
                        <CardMarginiH1 output={currencyOutH1} />
                      </Col>
                    </Row>
                  </>
                )}
                {/* GENERALI */}
                <Row>
                  <Col xs="12">
                    <CardSentimentFilter outputSentiment={sentimentData} />
                  </Col>
                </Row>
                {/* CALENDARIO */}
                <Row>
                  {flagCalendar && flagOutputD1 ? (
                    <TableCalendar
                      idStrumento={idStrumento}
                      calendarData={calendarData.finalCalendarList}
                      output={currencyOut}
                    />
                  ) : (
                    <></>
                  )}
                </Row>
              </div>
            </>
          )}
        </BackgroundColorContext.Consumer>
      )}
    </ThemeContext.Consumer>
  );
}

export default StrumentoTemplate;
