/** @format */
import { getCookie } from "cookieCreator";
import httpClient from "fetchers";

export const responseCalendarData = idStrumento => {
  return httpClient(`Calendar?idStrumento=${idStrumento}`, true, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getCookie("token")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(response => response.json())
    .catch(error => console.log(error));
};
