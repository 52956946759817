/** @format */

import { PlayerComponent } from "components/ReactPlayer/PlayerComponent";

export const AntipirlaCardPlaylist = props => {
  const videoRow = (risorsa, url, titolo, isOnDb) => {
    return (
      <PlayerComponent
        risorsa={risorsa}
        url={url}
        isOnDb={isOnDb}
        isPlaylist={true}
      />
    );
  };
  const risorsa = {
    idRisorsa: 100,
    path: "https://www.youtube.com/playlist?list=PL8EVV1m3hI6nSoDIjxME9RLmjAbIv2Ekp",
    descrizione: "Playlist Antipirla",
    tipo: "Ply",
  };
  return videoRow(
    risorsa,
    risorsa.path,
    "",
    false //isOnDBb
  );
};

export const RoiPropCardPlaylist = props => {
  const videoRow = (risorsa, url, titolo, isOnDb) => {
    return (
      <PlayerComponent
        risorsa={risorsa}
        url={url}
        isOnDb={isOnDb}
        isPlaylist={true}
      />
    );
  };
  const risorsa = {
    idRisorsa: 101,
    path: "https://www.youtube.com/playlist?list=PL8EVV1m3hI6ndMHTM_gCdKaRDGlcq8_8L",
    descrizione: "PlaylistRoiProp",
    tipo: "Ply",
  };
  return videoRow(
    risorsa,
    risorsa.path,
    "",
    false //isOnDBb
  );
};

export const RoiDiscoveryCardPlaylist = props => {
  const videoRow = (risorsa, url, titolo, isOnDb) => {
    return (
      <PlayerComponent
        risorsa={risorsa}
        url={url}
        isOnDb={isOnDb}
        isPlaylist={true}
      />
    );
  };
  const risorsa = {
    idRisorsa: 102,
    path: "https://www.youtube.com/playlist?list=PL8EVV1m3hI6nq8yJs_F0jzNAHmidRTOJt",
    descrizione: "PlaylistRoiDiscovery",
    tipo: "Ply",
  };
  return videoRow(
    risorsa,
    risorsa.path,
    "",
    false //isOnDBb
  );
};

export const DaZeroATraderCardPlaylist = props => {
  const videoRow = (risorsa, url, titolo, isOnDb) => {
    return (
      <PlayerComponent
        risorsa={risorsa}
        url={url}
        isOnDb={isOnDb}
        isPlaylist={true}
      />
    );
  };
  const risorsa = {
    idRisorsa: 103,
    path: "https://www.youtube.com/playlist?list=PL8EVV1m3hI6nAibrt7FWMGGaUhe75W0fp",
    descrizione: "PlaylistDaZeroATrader",
    tipo: "Ply",
  };
  return videoRow(
    risorsa,
    risorsa.path,
    "",
    false //isOnDBb
  );
};
export const RoiboosterCardPlaylist = props => {
  const videoRow = (risorsa, url, titolo, isOnDb) => {
    return (
      <PlayerComponent
        risorsa={risorsa}
        url={url}
        isOnDb={isOnDb}
        isPlaylist={true}
        titolo={titolo}
      />
    );
  };
  const risorsa = {
    idRisorsa: 104,
    path: "https://www.youtube.com/playlist?list=PL8EVV1m3hI6nuGBX70DxSjoauATspSO1v",
    descrizione: "PlaylistRoibooster",
    tipo: "Ply",
  };
  return videoRow(
    risorsa,
    risorsa.path,
    `  PLAYLIST 
                    Pacchetto ROIBOOSTER™
                 `,
    false //isOnDBb
  );
};
export const ThousandollarCardPlaylist = props => {
  const videoRow = (risorsa, url, titolo, isOnDb) => {
    return (
      <PlayerComponent
        risorsa={risorsa}
        url={url}
        isOnDb={isOnDb}
        isPlaylist={true}
        titolo={titolo}
      />
    );
  };
  const risorsa = {
    idRisorsa: 105,
    path: "https://www.youtube.com/playlist?list=PL8EVV1m3hI6k-YGtvWa5WuNRCVV8_wsPi",
    descrizione: "PlaylistThousandollar",
    tipo: "Ply",
  };
  return videoRow(
    risorsa,
    risorsa.path,
    `  PLAYLIST 
                    Pacchetto THOUSANDOLLAR™
                 `,
    false //isOnDBb
  );
};
export const MilliondollarCardPlaylist = props => {
  const videoRow = (risorsa, url, titolo, isOnDb) => {
    return (
      <PlayerComponent
        risorsa={risorsa}
        url={url}
        isOnDb={isOnDb}
        isPlaylist={true}
        titolo={titolo}
      />
    );
  };
  const risorsa = {
    idRisorsa: 106,
    path: "https://www.youtube.com/playlist?list=PL8EVV1m3hI6mKIiMCNZyq9mGOLM2UEzim",
    descrizione: "PlaylistMilliondollar",
    tipo: "Ply",
  };
  return videoRow(
    risorsa,
    risorsa.path,
    `  PLAYLIST 
                    Pacchetto MILLIONDOLLAR™
                 `,
    false //isOnDBb
  );
};

export const RoiscalpingCardPlaylist = props => {
  const videoRow = (risorsa, url, titolo, isOnDb) => {
    return (
      <PlayerComponent
        risorsa={risorsa}
        url={url}
        isOnDb={isOnDb}
        isPlaylist={true}
        titolo={titolo}
      />
    );
  };
  const risorsa = {
    idRisorsa: 106,
    path: "https://www.youtube.com/playlist?list=PL8EVV1m3hI6m77AvakFb1X0k_N5n9Wor9",
    descrizione: "PlaylistRoiscalping",
    tipo: "Ply",
  };
  return videoRow(
    risorsa,
    risorsa.path,
    `  PLAYLIST 
                    Pacchetto ROISCALPING™
                 `,
    false //isOnDBb
  );
};

export const RoiPropMasterclassCardPlaylist = props => {
  const videoRow = (risorsa, url, titolo, isOnDb) => {
    return (
      <PlayerComponent
        risorsa={risorsa}
        url={url}
        isOnDb={isOnDb}
        isPlaylist={true}
        titolo={titolo}
      />
    );
  };
  const risorsa = {
    idRisorsa: 107,
    path: "https://www.youtube.com/playlist?list=PL8EVV1m3hI6k1IUw9GznNwp4gW-GAW7Np",
    descrizione: "PlaylistMasterclassRoiProp",
    tipo: "Ply",
  };
  return videoRow(
    risorsa,
    risorsa.path,
    `  PLAYLIST 
                    MASTERCLASS LESSONS - ROIPROP™
                 `,
    false //isOnDBb
  );
};
