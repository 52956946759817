/** @format */

export const calculateMode = array => {
  let freqMap = {};
  let maxFreq = 0;
  let mode = [];
  for (let num of array) {
    freqMap[num] = (freqMap[num] || 0) + 1;

    if (freqMap[num] > maxFreq) {
      maxFreq = freqMap[num];
      mode = [num];
    } else if (freqMap[num] === maxFreq) {
      mode.push(num);
    }
  }

  return freqMap;
};
