/** @format */

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
  Input,
  TextareaAutosize,
} from "@mui/material";
import { Button, Container, Form, Nav, NavItem, NavLink } from "reactstrap";
import emailjs from "@emailjs/browser";
import { useSelector } from "react-redux";

function SupportEmail(props) {
  const [fullName, setFullName] = useState("");
  const [emailFrom, setEmailFrom] = useState(
    useSelector(x => x.user.userInfo.email)
  );
  const [emailTo, setEmailTo] = useState("");
  const [message, setMessage] = useState("");

  const toggleModal = () => {
    props.setModalOpen(!props.open);
  };

  function handleSubmit(event) {
    const serviceId = "service_6sd70el";
    const templateId = "template_9y3nv9k";
    const publicKey = "GURJq7kBmyJGwry3I";

    sendFeedback(
      serviceId,
      templateId,
      {
        fullName: fullName,
        message_html: message,
        from_email: emailFrom,
        reply_to: "support@mtplatform.it",
      },
      publicKey
    );
  }
  function sendFeedback(serviceId, templateId, variables, publicKey) {
    emailjs.send(serviceId, templateId, variables, publicKey).then(
      function () {
        alert("EMAIL INVIATA CON SUCCESSO");
        return toggleModal();
      },
      function () {
        alert("EMAIL NON INVIATA CORRETTAMENTE");
        return toggleModal();
      }
    );
  }

  return (
    <Dialog open={props.open}>
      <DialogTitle id="title" color="black">
        Invia un email al supporto tecnico.
        <p style={{ color: "black" }}>
          PER UNA RISPOSTA PIU' IMMEDIATA SCRIVERE SU TELEGRAM AL NUMERO +39
          3803632578 <br /> (Gianluca - Reparto tecnico)
        </p>
      </DialogTitle>
      <Form style={{ padding: 30 }} id="myForm">
        {/* onSubmit={this.handleSubmit} */}
        <FormGroup>
          <FormLabel>Inserisci i tuoi dati:</FormLabel>
          <FormControl>
            <Input
              name="name"
              type="text"
              placeholder="Nome e cognome"
              value={fullName}
              onChange={e => setFullName(e.target.value)}
            />
          </FormControl>
        </FormGroup>
        <FormGroup>
          <FormLabel>Inserisci la tua email:</FormLabel>
          <FormControl>
            <Input
              name="email"
              type="email"
              placeholder="email@gmail.com"
              value={emailFrom}
              onChange={e => setEmailFrom(e.target.value)}
            />
          </FormControl>
        </FormGroup>
        <br />
        <br />
        <FormGroup>
          <FormLabel>Oggetto:</FormLabel>
          <FormControl>
            <Input
              name="subject"
              type="text"
              placeholder="es. Problemi con la piattaforma"
              value={emailTo}
              onChange={e => setEmailTo(e.target.value)}
            />
          </FormControl>
        </FormGroup>
        <FormGroup>
          <FormLabel>Message</FormLabel>
          <FormControl>
            <TextareaAutosize
              name="message"
              placeholder={`Descrivi il tuo problema... \n\n\n`}
              value={message}
              onChange={e => setMessage(e.target.value)}
            />
          </FormControl>
        </FormGroup>

        <FormGroup kind="group">
          <FormControl>
            <Button color="dark" onClick={handleSubmit}>
              Send
            </Button>
          </FormControl>
          <FormControl>
            <Button onClick={() => props.setModalOpen(false)}>Cancel</Button>
          </FormControl>
        </FormGroup>
      </Form>
    </Dialog>
  );
}

export default SupportEmail;
