/** @format */
import jwt_decode from "jwt-decode";
import { getCookie } from "cookieCreator";
import { cookieCreator } from "cookieCreator";
import { setLoginEmpty } from "actions/login/actionsLogin";
import configData from "assets/config/config.json";

export default async function httpClient(path, useToken, option) {
  const defaultHeader = {
    accept: "text/plain",
    "Content-Type": "application/json",
  };
  const now = Date.now();
  if (useToken) {
    const actualToken = getCookie("token");
    if (now >= jwt_decode(actualToken).exp * 1000) {
      const refreshToken = getCookie("refreshToken");
      const tokensBody = { accessToken: actualToken, refreshToken };
      return fetch(`${configData.SERVER_URL}/Login/refresh`, {
        method: "POST",
        headers: {
          accept: "text/plain",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(tokensBody),
      })
        .then(response => response.json())
        .then(response => {
          //   debugger;
          cookieCreator("token", response.token);
          cookieCreator("refreshToken", response.refreshToken);
          return response;
        })
        .then(() => {
          defaultHeader["Authorization"] = `Bearer ${actualToken}`;
          return fetch(`${configData.SERVER_URL}${path}`, {
            ...option,
            headers: { ...defaultHeader, ...option.headers },
          });
        })
        .catch(error => {
          console.log(error);
          setLoginEmpty();
        });
    }

    defaultHeader["Authorization"] = `Bearer ${actualToken}`;
  }

  // Aggiunta parametro di query per impedire la cache
  let noCacheURL;
  if (path.includes("?")) {
    // Se "path" ha già un parametro di query, aggiungi il nuovo parametro con "&"
    noCacheURL = `${path}&nocache=${new Date().getTime()}`;
  } else {
    // Altrimenti, usa "?"
    noCacheURL = `${path}?nocache=${new Date().getTime()}`;
  }

  return fetch(`${configData.SERVER_URL}${noCacheURL}`, {
    ...option,
    headers: { ...defaultHeader, ...option.headers },
  });
}
