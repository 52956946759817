/** @format */

export const Aforismi = () => [
  "Un obiettivo senza un piano è solo un desiderio.",
  "La chiave per raggiungere un obiettivo è la costanza nell'impegno.",
  "Un obiettivo senza una data di scadenza è solo un sogno senza una fine.",
  "Non c'è niente di più motivante che vedere i propri obiettivi diventare realtà.",
  "Non c'è obiettivo troppo grande se ci si impegna abbastanza per raggiungerlo.",
  "La disciplina è il ponte tra gli obiettivi e i risultati.",
  "Gli obiettivi non sono raggiunti dalla fortuna, ma dall'impegno costante.",
  "Se non si sa dove si vuole andare, qualsiasi strada può essere quella giusta.",
  "L'impegno nell'attuale porta ai successi del futuro.",
  "I grandi risultati nascono da piccoli passi iniziati oggi.",
  "La motivazione è l'energia che accende il fuoco dentro di noi e ci spinge a perseguire i nostri sogni.",
  "La motivazione è come una fiamma: se alimentata costantemente, brucerà luminosa e intensa.",
  "La motivazione è la chiave per sbloccare il potenziale nascosto all'interno di noi.",
  "La motivazione non nasce solo dalle circostanze, ma dalla determinazione di non arrendersi mai.",
  "La motivazione è il carburante che ci fa superare gli ostacoli lungo il cammino verso il successo.",
  "La motivazione non aspetta il momento perfetto, crea il momento perfetto.",
  "La motivazione è come un muscolo: più la si allena, più diventa forte e resistente.",
  "La libertà finanziaria è il risultato di una mente disciplinata e di una gestione saggia delle risorse.",
  "La libertà finanziaria non significa essere ricchi, ma avere il controllo delle proprie finanze e delle proprie scelte.",
  "La vera libertà risiede nell'essere liberi dagli schemi finanziari che ci imprigionano.",
  "La libertà finanziaria è il potere di fare scelte basate sulla passione e sui valori, anziché sulle necessità economiche.",
  "La libertà finanziaria non è solo questione di soldi, ma di fiducia e sicurezza nella propria capacità di creare e gestire le proprie risorse.",
  "La libertà finanziaria si raggiunge quando il denaro diventa uno strumento al servizio della nostra vita, anziché un fine in sé stesso.",
  "La libertà finanziaria è il risultato di una pianificazione intelligente, di una spesa consapevole e di una gestione oculata dei rischi.",
  "La libertà finanziaria è il regalo più prezioso che possiamo fare a noi stessi e alle persone che amiamo.",
  "La libertà finanziaria non è solo una meta da raggiungere, ma uno stile di vita basato sulla responsabilità e sull'indipendenza economica.",
  "La vera ricchezza è la libertà finanziaria, poiché ci consente di perseguire i nostri senza limitazioni materiali.",
];
