/** @format */

import {
  Card,
  CardHeader,
  Row,
  Col,
  Badge,
  CardFooter,
  CardTitle,
} from "reactstrap";
import { useSelector } from "react-redux";
import {
  greenLong,
  redShort,
} from "components/StrumentiComponents/StrumentiComponents";
import { checkFromIdStrumentoIndexes } from "variables/generalChecker";
import { checkFromIdStrumentoCommodities } from "variables/generalChecker";
import { moduloColInCardXs } from "components/Modules/ModuleComponents";
import { isLong } from "variables/generalChecker";
import { useEffect, useState } from "react";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { addFavorite } from "actions/user/userAction";
import { TitleWithFlags } from "assets/currenciesFlags/titleWithFlags";
import { moduloColInCardSm } from "components/Modules/ModuleComponents";
import { num } from "components/StrumentiComponents/StrumentiComponents";
import { filterH1ToolsByCurrentHour } from "variables/generalChecker";
import { checkFromIdStrumentoCurrenciesH1 } from "variables/generalChecker";
import { checkFromIdStrumentoH1 } from "variables/generalChecker";
import { okH1 } from "variables/activeTools";
import serviceCardImg from "assets/serviceCardImg/serviceCardImg";
import CorrelationFilter from "variables/correlationFilter";
import { checkType } from "variables/generalChecker";
import { isASymbol } from "variables/generalChecker";
import { okRoiScalpingForex } from "variables/activeTools";
import { okRoiScalpingCommodities } from "variables/activeTools";
import { okRoiScalpingIndexes } from "variables/activeTools";
import { checkFromIdStrumentoCommoditiesH1 } from "variables/generalChecker";
import { checkFromIdStrumentoIndexesH1 } from "variables/generalChecker";

export const CardBestOutputStrumentoH1 = (
  out,
  best,
  type,
  navigate,
  settings,
  favorites,
  dispatch
) => {
  async function handleFavorite() {
    await dispatch(addFavorite(out.idStrumento));
  }
  return (
    <Card className="card-user">
      {!best && (
        <i
          className={
            favorites?.filter(x => x === out.idStrumento).length > 0
              ? "fa-solid fa-heart fa-lg "
              : "fa-regular fa-heart fa-lg "
          }
          style={{
            position: "absolute",
            right: 12,
            top: 25,
            cursor: "pointer",
            color: "#ac0c0c",
          }}
          onClick={() => handleFavorite()}
        ></i>
      )}
      <CardHeader>
        {best ? (
          <>
            <h4>
              Best {type} {moment().hour()}:00 -
              {moment().add(1, "hours").hour()}:00
            </h4>
            <h5 className="card-title">
              Strumento selezionato con affidabilità più alta.
            </h5>
          </>
        ) : (
          <h5 className="card-category">
            OUTPUT STRUMENTO <br /> Ultimo aggiornamento: {"   "}
            {moment(
              settings?.filter(x => x.idStrumento === out.idStrumento)[0]
                .timeUpd
            )
              .add(2, "hours")
              .format("LLL")}
          </h5>
        )}
        <CardTitle
          tag="h2"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <i>
            {!out ? (
              <></>
            ) : (
              TitleWithFlags(out.idStrumento.toLowerCase(), best, navigate)
            )}
          </i>
        </CardTitle>

        <Row style={{ marginBottom: 20 }}>
          {moduloColInCardSm(
            "PREZZO CORRENTE",
            num(
              settings?.filter(x => x.idStrumento === out.idStrumento)[0]
                .currentPrice
            ),
            "#bfa100",
            true,
            best
          )}
          {moduloColInCardSm(
            "DIREZIONE ORARIA",
            out.filtroDirezione.trim(),
            out.filtroDirezione.trim() === "LONG" ? "#519259" : "#AE431E",
            true,
            best
          )}

          {moduloColInCardSm(
            "LIVELLO INGRESSO",
            num(out.livelloIngresso),
            "#91684A",
            true,
            best
          )}
          {moduloColInCardSm("TARGET", num(out.target), "#350B40", true, best)}

          {moduloColInCardSm(
            "AFFIDABILITÀ",
            (out.affidabilita * 100).toFixed(2) + "%",
            out.affidabilita > 0.5 ? "#228B22" : "#DE970B",
            true,
            best
          )}
          {moduloColInCardSm(
            "MARGINE CENTRALE",
            // (out.margineCentrale * 100).toFixed(0) + "%",
            "125%",
            "#008b8b",
            true,
            best
          )}
          {moduloColInCardSm(
            "MARGINE MASSIMO",
            // (out.margineMassimo * 100).toFixed(0) + "%",
            "150%",
            "#00008b",
            true,
            best
          )}
        </Row>
      </CardHeader>
      <CardFooter>
        <h6>
          Nota: Per una strategia più conservativa, vi consigliamo di
          posizionare un ordine pendente al "livello di ingresso" indicato.
          <br /> In alternativa, potete anche scegliere di entrare direttamente
          a mercato seguendo le nostre indicazioni operative.
          <br /> Optate per l'approccio che meglio si allinea alla vostra
          strategia di trading.
        </h6>
      </CardFooter>
    </Card>
  );
};

export const CardRiepilogoH1 = props => {
  const userData = useSelector(state => state.user);
  const outputH1 = useSelector(state => state.output.strumentiH1);
  const [currenciesArrayObj, setCurrenciesArrayObj] = useState([]);

  const outputFilterH1 = outputH1
    ?.filter(x => checkFromIdStrumentoH1(x.idStrumento.toLowerCase(), userData))
    .filter(x => x.hour === moment().hour());

  function groupBy(list, keyGetter) {
    const map = new Map();
    list.forEach(item => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  useEffect(() => {
    const currenciesGrouped = groupBy(outputFilterH1, outputFilterH1 =>
      outputFilterH1.idStrumento.substring(3)
    );

    currenciesGrouped.forEach((values, key, map) => {
      currenciesArrayObj.length < 7 &&
        setCurrenciesArrayObj(prevGroup => {
          // return prevGroup.concat(values);
          return prevGroup.concat({ key, values });
        });
    });
  }, []);
  return outputFilterH1.length > 0 ? (
    <Card className="card-user">
      <CardHeader>
        <h1 className="card-category">RIEPILOGO DIREZIONI</h1>
        {okRoiScalpingForex(userData) && (
          <>
            <p className="mb-4">CURRENCIES</p>
            <div>
              {currenciesArrayObj.map(obj => {
                return (
                  <div key={obj.key}>
                    <Row>
                      {isASymbol(obj.key) && (
                        <Col
                          md="12"
                          sm="12"
                          className="mt-3"
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 10,
                          }}
                        >
                          <h4 className="mt-2">{obj.key}</h4>
                          <Badge
                            style={{
                              color: "white",
                              fontSize: 12,
                              backgroundColor:
                                CorrelationFilter(obj.values, "CURRENCIES")
                                  .esito === "DA COMPRARE"
                                  ? greenLong
                                  : redShort,
                            }}
                          >
                            {CorrelationFilter(obj.values, "CURRENCIES").esito}
                          </Badge>
                          <br />
                          <Badge color="info" style={{ fontSize: 18 }}>
                            {(
                              CorrelationFilter(
                                obj.values.filter(
                                  x => x.idStrumento.substring(0, 3) != "XAU",
                                  "CURRENCIES"
                                )
                              ).coefficiente * 100
                            ).toFixed()}
                            %
                          </Badge>
                        </Col>
                      )}

                      <Col lg="12">
                        <Row
                          style={{
                            display: "flex",
                            alignItems: "center",
                            width: "90%",
                          }}
                        >
                          {obj.values
                            .filter(
                              x => checkType(x.idStrumento) === "CURRENCIES"
                            )
                            .map(x => {
                              return moduloColInCardXs(
                                x.idStrumento,
                                x.filtroDirezione,
                                x.filtroDirezione.trim() === "LONG"
                                  ? greenLong
                                  : redShort
                              );
                            })}
                        </Row>
                      </Col>
                    </Row>
                    <Row style={{ display: "flex", justifyContent: "center" }}>
                      {isASymbol(obj.key) && (
                        <Col
                          lg="11"
                          style={{
                            height: "auto",
                            padding: 10,
                            marginBottom: 10,
                            borderBottom: "2px solid white",
                            opacity: "20%",
                          }}
                        ></Col>
                      )}
                    </Row>
                  </div>
                );
              })}
            </div>
          </>
        )}
        {okRoiScalpingIndexes(userData) && (
          <>
            <br />
            <p className="mb-4">INDEXES</p>
            <Row>
              <Col
                md="12"
                sm="12"
                className="mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <>
                  <Badge
                    style={{
                      color: "white",
                      backgroundColor: isLong(
                        CorrelationFilter(
                          outputFilterH1?.filter(x =>
                            checkFromIdStrumentoIndexes(
                              x.idStrumento.toLowerCase(),
                              userData
                            )
                          ),
                          "INDEXES"
                        ).esito
                      )
                        ? greenLong
                        : redShort,
                    }}
                  >
                    {
                      CorrelationFilter(
                        outputFilterH1?.filter(x =>
                          checkFromIdStrumentoIndexes(
                            x.idStrumento.toLowerCase(),
                            userData
                          )
                        ),
                        "INDEXES"
                      ).esito
                    }
                  </Badge>
                  <br />
                  <Badge color="info" style={{ fontSize: 18 }}>
                    {CorrelationFilter(
                      outputFilterH1?.filter(x =>
                        checkFromIdStrumentoIndexes(
                          x.idStrumento.toLowerCase(),
                          userData
                        )
                      ),
                      "INDEXES"
                    ).coefficiente * 100}
                    %
                  </Badge>
                </>
              </Col>
              {outputFilterH1
                ?.filter(x =>
                  checkFromIdStrumentoIndexes(
                    x.idStrumento.toLowerCase(),
                    userData
                  )
                )
                .map(x => {
                  return moduloColInCardXs(
                    x.idStrumento,
                    x.filtroDirezione,
                    x.filtroDirezione.trim() === "LONG" ? greenLong : redShort
                  );
                })}
            </Row>
            <Row style={{ display: "flex", justifyContent: "center" }}>
              <Col
                lg="11"
                style={{
                  height: "auto",
                  padding: 10,
                  marginBottom: 10,
                  borderBottom: "2px solid white",
                  opacity: "20%",
                }}
              ></Col>
            </Row>
          </>
        )}

        {okRoiScalpingCommodities(userData) && (
          <>
            <br />
            <p className="mb-4">COMMODODITIES</p>
            <Row>
              <Col
                md="12"
                sm="12"
                className="mt-3"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 10,
                }}
              >
                <>
                  <Badge
                    style={{
                      color: "white",
                      backgroundColor: isLong(
                        CorrelationFilter(
                          outputFilterH1?.filter(x =>
                            checkFromIdStrumentoCommodities(
                              x.idStrumento.toLowerCase(),
                              userData
                            )
                          ),
                          "COMMODITIES"
                        ).esito
                      )
                        ? greenLong
                        : redShort,
                    }}
                  >
                    {
                      CorrelationFilter(
                        outputFilterH1?.filter(x =>
                          checkFromIdStrumentoCommodities(
                            x.idStrumento.toLowerCase(),
                            userData
                          )
                        ),
                        "COMMODITIES"
                      ).esito
                    }
                  </Badge>
                  <br />
                  <Badge color="info" style={{ fontSize: 18 }}>
                    {CorrelationFilter(
                      outputFilterH1?.filter(x =>
                        checkFromIdStrumentoCommodities(
                          x.idStrumento.toLowerCase(),
                          userData
                        )
                      ),
                      "COMMODITIES"
                    ).coefficiente * 100}
                    %
                  </Badge>
                </>
              </Col>
              {outputFilterH1
                ?.filter(x =>
                  checkFromIdStrumentoCommodities(
                    x.idStrumento.toLowerCase(),
                    userData
                  )
                )
                .map(x => {
                  return moduloColInCardXs(
                    x.idStrumento,
                    x.filtroDirezione,
                    x.filtroDirezione.trim() === "LONG" ? greenLong : redShort
                  );
                })}
            </Row>
          </>
        )}
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  ) : (
    <></>
  );
};

export const CardBestCurrencyH1 = props => {
  const navigate = useNavigate();
  const output = useSelector(state => state.output);
  const userData = useSelector(state => state.user);

  const outputFilterCurrencies = filterH1ToolsByCurrentHour(
    output?.strumentiH1
  )?.filter(x =>
    checkFromIdStrumentoCurrenciesH1(x.idStrumento.toLowerCase(), userData)
  );

  const bestCurrencie =
    outputFilterCurrencies.length > 0
      ? outputFilterCurrencies.sort(
          (x1, x2) => x2.affidabilita - x1.affidabilita
        )[0]
      : null;

  return bestCurrencie ? (
    CardBestOutputStrumentoH1(
      bestCurrencie,
      true,
      "Currency",
      navigate,
      props?.settings,
      userData
    )
  ) : (
    <></>
  );
};
export const CardBestIndexH1 = props => {
  const navigate = useNavigate();
  const output = useSelector(state => state.output);
  const userData = useSelector(state => state.user);
  const outputFilterIndexes = filterH1ToolsByCurrentHour(
    output?.strumentiH1
  )?.filter(x =>
    checkFromIdStrumentoIndexesH1(x.idStrumento.toLowerCase(), userData)
  );
  const bestIndex =
    outputFilterIndexes?.length > 0
      ? outputFilterIndexes.sort(
          (x1, x2) => x2.affidabilita - x1.affidabilita
        )[0]
      : null;

  return bestIndex ? (
    CardBestOutputStrumentoH1(
      bestIndex,
      true,
      "Index",
      navigate,
      props?.settings,
      userData
    )
  ) : (
    <></>
  );
};
export const CardBestCommodityH1 = props => {
  const navigate = useNavigate();
  const output = useSelector(state => state.output);
  const userData = useSelector(state => state.user);

  const outputFilterCommodities = filterH1ToolsByCurrentHour(
    output?.strumentiH1
  )?.filter(x =>
    checkFromIdStrumentoCommoditiesH1(x.idStrumento.toLowerCase(), userData)
  );
  const bestCommodity =
    outputFilterCommodities?.length > 0
      ? outputFilterCommodities.sort(
          (x1, x2) => x2.affidabilita - x1.affidabilita
        )[0]
      : null;

  return bestCommodity ? (
    CardBestOutputStrumentoH1(
      bestCommodity,
      true,
      "Commodity",
      navigate,
      props?.settings,
      userData
    )
  ) : (
    <></>
  );
};

export const CardExpertAdvisorH1 = () => {
  return (
    <Card>
      <a
        href="https://drive.google.com/drive/folders/1mOmD0BzmUG9K1x7JeChJMDhDQ4Z0kBTB?usp=sharing"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex", justifyContent: "center" }}>
            {serviceCardImg("roiscalping", "ea")}
            <div style={{ marginLeft: 40 }}>
              <h4>Download Expert Advisor ROIScalping™</h4>
              <h6>
                Software per la gestione automatica delle operazioni nella
                piattaforma MT4
              </h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};
