/** @format */

import { CardEntrateUscite } from "components/ParComponent/ParComponent";
import { CardInsertFirstValues } from "components/ParComponent/ParComponent";
import React from "react";

// reactstrap components
import { Row, Col } from "reactstrap";

function Par() {
  return (
    <>
      <div className="content">
        <Row>
          <Col lg="6" md="12">
            <CardInsertFirstValues />
          </Col>
          <Col lg="6" md="12">
            <CardEntrateUscite />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Par;
