/** @format */

import Admin from "layouts/Admin/Admin";
import Login from "layouts/Login/Login";
import Dashboard from "views/Dashboard";
import Esiti from "views/Esiti";
import Typography from "views/Typography";
import UserProfile from "views/UserProfile";
import Videocorsi from "views/Videocorsi";
import { Navigate, useLocation } from "react-router-dom";
import { getCookie } from "cookieCreator";
import Par from "views/Par";
import EtfTemplate from "views/Strumenti/EtfTemplate";
import StoreBacktest from "views/StoreBackTest";
import RoiProp from "views/RoiProp";
import Audiocorsi from "views/Audiocorsi";
import DashboardH1 from "views/DashboardH1";
import EsitiH1 from "views/EsitiH1";
import StoreBacktestH1 from "views/StoreBackTestH1";
import { useEffect, useState } from "react";
import StrumentoTemplate from "views/Strumenti/StrumentoTemplate";
import PrintMoney from "views/PrintMoney";

const PrivateRoute = ({ component: Component, path, ...props }) => {
  if (!getCookie("token")) {
    return <Navigate replace to="/login" />;
  }
  return <Admin {...props} />;
};

function withWrapper(Component) {
  return function WrappedComponent(props) {
    const location = useLocation();
    return <Component {...props} key={location.pathname} />;
  };
}
const StrumentoTemplateWrapped = withWrapper(StrumentoTemplate);

const routes2 = [
  { path: "/", element: <Navigate replace to="/login" /> },
  {
    path: "login",
    name: "login",
    element: <Login />,
  },
  {
    path: "admin",
    name: "admin",
    element: <PrivateRoute component={Admin} />,
    children: [
      { index: true, element: <Navigate replace to="dashboard" /> },
      {
        path: "dashboard",
        element: <Dashboard />,
        name: "Dashboard",
        icon: "tim-icons icon-chart-pie-36",
        layout: "/admin",
        private: false,
        header: true,
      },
      // {
      //   path: "dashboardH1",
      //   element: <DashboardH1 />,
      //   name: "Dashboard H1",
      //   icon: "tim-icons icon-chart-pie-36",
      //   layout: "/admin",
      //   private: false,
      //   header: true,
      //   h1: true,
      // },
      {
        path: "esiti",
        name: "Esiti",
        icon: "tim-icons icon-puzzle-10",
        element: <Esiti />,
        layout: "/admin",
        private: false,
        header: true,
      },
      // {
      //   path: "esitiH1",
      //   name: "Esiti H1",
      //   icon: "tim-icons icon-puzzle-10",
      //   element: <EsitiH1 />,
      //   layout: "/admin",
      //   private: false,
      //   header: true,
      //   h1: true,
      // },
      {
        path: "roiprop",
        name: "RoiProp",
        icon: "tim-icons icon-atom",
        element: <RoiProp />,
        layout: "/admin",
        private: false,
        header: true,
      },
      {
        path: "printmoney",
        name: "Print Money",
        icon: "tim-icons icon-atom",
        element: <PrintMoney />,
        layout: "/admin",
        private: false,
        header: true,
      },
      {
        path: "videocorsi",
        name: "Videocorsi",
        icon: "tim-icons icon-pin",
        element: <Videocorsi />,
        layout: "/admin",
        private: false,
        header: true,
      },
      {
        path: "audiocorsi",
        name: "Audiocorsi",
        icon: "tim-icons icon-pin",
        element: <Audiocorsi />,
        layout: "/admin",
        private: false,
        header: true,
      },
      {
        path: "storebacktest",
        name: "Store Back Test",
        icon: "tim-icons icon-pin",
        element: <StoreBacktest />,
        layout: "/admin",
        private: true,
        header: true,
      },
      {
        path: "storebacktesth1",
        name: "Store Back Test H1",
        icon: "tim-icons icon-pin",
        element: <StoreBacktestH1 />,
        layout: "/admin",
        private: true,
        header: true,
      },
      {
        path: "etf",
        name: "Etf",
        icon: "tim-icons icon-pin",
        element: <EtfTemplate />,
        layout: "/admin",
        children: [
          {
            path: "msci",
            name: "Msci",
            component: <EtfTemplate />,
            type: "etf",
            version: 3,
            layout: "/admin/etf",
          },
          {
            path: "sp500",
            name: "Sp500",
            component: <EtfTemplate />,
            type: "etf",
            version: 3,
            layout: "/admin/etf",
          },
          {
            path: "nasdaq",
            name: "Nasdaq",
            component: <EtfTemplate />,
            type: "etf",
            version: 3,
            layout: "/admin/etf",
          },
          {
            path: "gold",
            name: "Gold",
            component: <EtfTemplate />,
            type: "etf",
            version: 5,
            layout: "/admin/etf",
          },
          {
            path: "eth",
            name: "Eth",
            component: <EtfTemplate />,
            type: "etf",
            version: 10,
            layout: "/admin/etf",
          },
          {
            path: "battery",
            name: "Battery",
            component: <EtfTemplate />,
            type: "etf",
            version: 10,
            layout: "/admin/etf",
          },
          {
            path: "btc",
            name: "Btc",
            component: <EtfTemplate />,
            type: "etf",
            version: 10,
            layout: "/admin/etf",
          },
          {
            path: "blockchain",
            name: "Blockchain",
            component: <EtfTemplate />,
            type: "etf",
            version: 10,
            layout: "/admin/etf",
          },
        ],
      },
      {
        path: "strumento",
        element: <StrumentoTemplateWrapped />,
        layout: "/admin",
        name: "Strumento",
        icon: "tim-icons icon-align-center",
        children: [
          {
            path: "de30",
            name: "DE30",
            component: <StrumentoTemplateWrapped />,
            type: "index",
            layout: "/admin/strumento",
          },
          {
            path: "nasdaq",
            name: "NASDAQ",
            component: <StrumentoTemplateWrapped />,
            type: "index",
            props: { we: "qw" },
            layout: "/admin/strumento",
          },
          {
            path: "dowjones",
            name: "DOWJONES",
            component: <StrumentoTemplateWrapped />,
            type: "index",
            layout: "/admin/strumento",
          },
          {
            path: "sp500",
            name: "SP500",
            component: <StrumentoTemplateWrapped />,
            type: "index",
            layout: "/admin/strumento",
          },
          {
            path: "gas",
            name: "GAS",
            component: <StrumentoTemplateWrapped />,
            type: "commodities",
            layout: "/admin/strumento",
          },
          {
            path: "silver",
            name: "SILVER",
            component: <StrumentoTemplateWrapped />,
            type: "commodities",
            layout: "/admin/strumento",
          },
          {
            path: "xauusd",
            name: "XAUUSD",
            component: <StrumentoTemplateWrapped />,
            type: "commodities",
            layout: "/admin/strumento",
          },
          {
            path: "crudeoil",
            name: "CRUDEOIL",
            component: <StrumentoTemplateWrapped />,
            type: "commodities",
            layout: "/admin/strumento",
          },
          {
            path: "btcusd",
            name: "BTCUSD",
            component: <StrumentoTemplateWrapped />,
            type: "crypto",
            layout: "/admin/strumento",
          },
          {
            path: "audcad",
            name: "AUDCAD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "audchf",
            name: "AUDCHF",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "audnzd",
            name: "AUDNZD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "audusd",
            name: "AUDUSD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "audjpy",
            name: "AUDJPY",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "cadchf",
            name: "CADCHF",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "cadjpy",
            name: "CADJPY",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "chfjpy",
            name: "CHFJPY",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "euraud",
            name: "EURAUD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "eurcad",
            name: "EURCAD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "eurchf",
            name: "EURCHF",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "eurgbp",
            name: "EURGBP",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "eurnzd",
            name: "EURNZD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "eurusd",
            name: "EURUSD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "eurjpy",
            name: "EURJPY",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "gbpaud",
            name: "GBPAUD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "gbpcad",
            name: "GBPCAD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "gbpchf",
            name: "GBPCHF",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "gbpnzd",
            name: "GBPNZD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "gbpusd",
            name: "GBPUSD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "gbpjpy",
            name: "GBPJPY",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "nzdcad",
            name: "NZDCAD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "nzdchf",
            name: "NZDCHF",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "nzdusd",
            name: "NZDUSD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "nzdjpy",
            name: "NZDJPY",
            type: "currencies",
            component: <StrumentoTemplateWrapped />,
            layout: "/admin/strumento",
          },
          {
            path: "usdcad",
            name: "USDCAD",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "usdchf",
            name: "USDCHF",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
          {
            path: "usdjpy",
            name: "USDJPY",
            component: <StrumentoTemplateWrapped />,
            type: "currencies",
            layout: "/admin/strumento",
          },
        ],
      },
    ],
  },
];

export default routes2;

//   path: "par",
//   path: "/notifications",
//   path: "user-profile",
//   path: "typography",
