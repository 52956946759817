/** @format */

import { cookieCreator } from "cookieCreator";
import { okCurrencies } from "variables/activeTools";
import { okH1 } from "variables/activeTools";
import { checkIsAdmin } from "variables/generalChecker";
import { checkFromIdStrumentoH1 } from "variables/generalChecker";
import { checkFromIdStrumento } from "variables/generalChecker";

export const setOutput = (output, user) => {
  // cookieCreator("output", output, 60);
  output.map(x =>
    x.roi <= 0
      ? (x.roi = Math.abs(x.target / x.livelloIngresso) * x.margineCentrale)
      : x
  );

  return {
    type: "SET_OUTPUT",
    payload: output.filter(x =>
      checkFromIdStrumento(x.idStrumento.toLowerCase(), user)
    ),
  };
};

export const setOutputH1 = (outputH1, user) => {
  outputH1.map(x =>
    x.roi === 0
      ? (x.roi = Math.abs(x.target / x.livelloIngresso) * x.margineCentrale)
      : x
  );
  return {
    type: "SET_OUTPUT_H1",
    payload: outputH1.filter(x =>
      checkFromIdStrumentoH1(x.idStrumento.toLowerCase(), user)
    ),
  };
};
