/** @format */
import { getCookie } from "cookieCreator";
import { cookieCreator } from "cookieCreator";
import httpClient from "fetchers";
export const responseGetUserVariablesByIdVar = idVar => {
  return httpClient(`UserVariable/${idVar}/byIdVar`, true, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie("token")}`,
    },
  })
    .then(response => response.json())
    .catch(error => console.log(error));
};

export const responseGetUserVariablesByIdUser = idUser => {
  return httpClient(`UserVariable/${idUser}/byIdUser`, true, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${getCookie("token")}`,
    },
  })
    .then(response => response.json())
    .catch(error => console.log(error));
};

export const addOrUpdateUserVariable = (idVar, idUser, value, date) => {
  return httpClient(
    `UserVariable/InsertData?userId=${idUser}&varId=${idVar}&date=${date}`,
    true,
    {
      method: "POST",
      body: JSON.stringify(value),
      Authorization: `Bearer ${getCookie("token")}`,
    }
  )
    .then(response => response.json())
    .catch(error => console.log("Error detected: " + error));
};
