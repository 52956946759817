/** @format */

import {
  okSilver,
  okBtcusd,
  okDax,
  okSp500,
  okGas,
  okGoldMiner,
  okOilExtractor,
  okDowJones,
  okNasdaq,
  okCurrencies,
} from "variables/activeTools";
import routes2 from "routes2";
import {
  okAntipirla,
  okAntipirla10x,
  okAntipirla5x,
  okBatteryEtf,
  okBlockchainEtf,
  okBtcEtf,
  okBtcusdH1,
  okCommodities,
  okD1,
  okDaxH1,
  okDowJonesH1,
  okEthEtf,
  okGasH1,
  okGoldEtf,
  okGoldMinerH1,
  okH1,
  okIndex,
  okMsciEtf,
  okNasdaqEtf,
  okNasdaqH1,
  okOilExtractorH1,
  okPrintMoney,
  okPrintMoneyGold,
  okRoiProp,
  okRoiPropBasic,
  okRoiPropDiamond,
  okRoiPropLessons,
  okRoiPropPremium,
  okRoiScalpingForex,
  okSilverH1,
  okSp500Etf,
  okSp500H1,
} from "./activeTools";
import moment from "moment";

// export const checkActiveWithPath = (idStrumento, user) => {
//   return (
//     idStrumento &&
//     ((idStrumento === "silver" && !okSilver(user)) ||
//       (idStrumento === "nasdaq" && !okNasdaq(user)) ||
//       (idStrumento === "dowjones" && !okDowJones(user)) ||
//       (idStrumento === "crudeoil" && !okOilExtractor(user)) ||
//       (idStrumento === "xauusd" && !okGoldMiner(user)) ||
//       (idStrumento === "de30" && !okDax(user)) ||
//       (idStrumento === "gas" && !okGas(user)) ||
//       (idStrumento === "sp500" && !okSp500(user)) ||
//       (idStrumento === "btcusd" && !okBtcusd(user)) ||
//       ((idStrumento.substring(0, 3) === "eur" ||
//         idStrumento.substring(0, 3) === "cad" ||
//         idStrumento.substring(0, 3) === "aud" ||
//         idStrumento.substring(0, 3) === "chf" ||
//         idStrumento.substring(0, 3) === "gbp" ||
//         idStrumento.substring(0, 3) === "nzd" ||
//         idStrumento.substring(0, 3) === "usd") &&
//         !okCurrencies(user) &&
//         !okH1(user)))
//   );
// };

export const checkPar = user => {
  return user.categories.filter(x => x.idProdotto === 126).length > 0;
};
export const checkLibroExcel = user => {
  return user.categories.filter(e => e.idProdotto === 272).length > 0;
};

export const routesFinancialTools = () => {
  return routes2
    .filter(x => x.path === "admin")[0]
    .children.filter(x => x.path === "strumento")[0].children;
};

export const routesCurrencies = userData => {
  return routes2
    .filter(x => x.path === "admin")[0]
    .children.filter(x => x.path === "strumento")[0]
    .children.filter(x => x.type === "currencies")
    .filter(x =>
      okCurrencies(userData) || okRoiScalpingForex(userData) ? x : null
    );
};

export const sidebarHeader = (userData, output) => {
  return routes2
    .filter(x => x.path === "admin")[0]
    .children.filter(
      x => x.header && (!x.private || checkIsAdmin(userData.userInfo.username))
    );
};

export const sidebarEtf = userData => {
  return routes2
    .filter(x => x.path === "admin")[0]
    .children.filter((x, i) => x.path === "etf")[0]
    .children.filter(
      x =>
        (okAntipirla10x(userData) && x.version <= 10) ||
        (okAntipirla5x(userData) && x.version <= 5) ||
        (okAntipirla(userData) && x.version <= 3)
    );
};

export const checkWhichStrumentHasAddFilter = (idStrumento, user) => {
  return (
    idStrumento !== "de30" &&
    idStrumento !== "crudeoil" &&
    idStrumento !== "nasdaq" &&
    idStrumento !== "dowjones" &&
    idStrumento !== "gas" &&
    idStrumento !== "sp500" &&
    idStrumento !== "silver" &&
    idStrumento !== "btcusd"
  );
};

export const checkFromIdStrumento = (idStrumento, user) => {
  return !(
    (idStrumento === "silver" && !okSilver(user)) ||
    (idStrumento === "nasdaq" && !okNasdaq(user)) ||
    (idStrumento === "dowjones" && !okDowJones(user)) ||
    (idStrumento === "crudeoil" && !okOilExtractor(user)) ||
    (idStrumento === "xauusd" && !okGoldMiner(user)) ||
    (idStrumento === "de30" && !okDax(user)) ||
    (idStrumento === "gas" && !okGas(user)) ||
    (idStrumento === "sp500" && !okSp500(user)) ||
    (idStrumento === "btcusd" && !okBtcusd(user)) ||
    ((idStrumento.substring(0, 3) === "eur" ||
      idStrumento.substring(0, 3) === "cad" ||
      idStrumento.substring(0, 3) === "aud" ||
      idStrumento.substring(0, 3) === "chf" ||
      idStrumento.substring(0, 3) === "gbp" ||
      idStrumento.substring(0, 3) === "nzd" ||
      idStrumento.substring(0, 3) === "usd") &&
      !okCurrencies(user))
  );
};
export const checkFromIdStrumentoH1 = (idStrumento, user) => {
  return !(
    (idStrumento === "silver" && !okSilverH1(user)) ||
    (idStrumento === "nasdaq" && !okNasdaqH1(user)) ||
    (idStrumento === "dowjones" && !okDowJonesH1(user)) ||
    (idStrumento === "crudeoil" && !okOilExtractorH1(user)) ||
    (idStrumento === "xauusd" && !okGoldMinerH1(user)) ||
    (idStrumento === "de30" && !okDaxH1(user)) ||
    (idStrumento === "gas" && !okGasH1(user)) ||
    (idStrumento === "sp500" && !okSp500H1(user)) ||
    (idStrumento === "btcusd" && !okBtcusdH1(user)) ||
    ((idStrumento.substring(0, 3) === "eur" ||
      idStrumento.substring(0, 3) === "cad" ||
      idStrumento.substring(0, 3) === "aud" ||
      idStrumento.substring(0, 3) === "chf" ||
      idStrumento.substring(0, 3) === "gbp" ||
      idStrumento.substring(0, 3) === "nzd" ||
      idStrumento.substring(0, 3) === "usd") &&
      !okRoiScalpingForex(user))
  );
};

export const checkFromIdStrumentoCurrencies = (idStrumento, user) => {
  return (
    (idStrumento.substring(0, 3) === "eur" ||
      idStrumento.substring(0, 3) === "cad" ||
      idStrumento.substring(0, 3) === "aud" ||
      idStrumento.substring(0, 3) === "chf" ||
      idStrumento.substring(0, 3) === "gbp" ||
      idStrumento.substring(0, 3) === "nzd" ||
      idStrumento.substring(0, 3) === "usd") &&
    okCurrencies(user)
  );
};
export const checkFromIdStrumentoCurrenciesH1 = (idStrumento, user) => {
  return (
    (idStrumento.substring(0, 3) === "eur" ||
      idStrumento.substring(0, 3) === "cad" ||
      idStrumento.substring(0, 3) === "aud" ||
      idStrumento.substring(0, 3) === "chf" ||
      idStrumento.substring(0, 3) === "gbp" ||
      idStrumento.substring(0, 3) === "nzd" ||
      idStrumento.substring(0, 3) === "usd") &&
    okRoiScalpingForex(user)
  );
};
export const checkFromIdStrumentoIndexes = (idStrumento, user) => {
  return (
    (idStrumento === "nasdaq" && okNasdaq(user)) ||
    (idStrumento === "dowjones" && okDowJones(user)) ||
    (idStrumento === "de30" && okDax(user)) ||
    (idStrumento === "sp500" && okSp500(user))
  );
};
export const checkFromIdStrumentoIndexesH1 = (idStrumento, user) => {
  return (
    (idStrumento === "nasdaq" && okNasdaqH1(user)) ||
    (idStrumento === "dowjones" && okDowJonesH1(user)) ||
    (idStrumento === "de30" && okDaxH1(user)) ||
    (idStrumento === "sp500" && okSp500H1(user))
  );
};

export const checkFromIdStrumentoCommodities = (idStrumento, user) => {
  return (
    (idStrumento === "silver" && okSilver(user)) ||
    (idStrumento === "xauusd" && okGoldMiner(user)) ||
    (idStrumento === "crudeoil" && okOilExtractor(user)) ||
    (idStrumento === "gas" && okGas(user))
  );
};
export const checkFromIdStrumentoCommoditiesH1 = (idStrumento, user) => {
  return (
    (idStrumento === "silver" && okSilverH1(user)) ||
    (idStrumento === "xauusd" && okGoldMinerH1(user)) ||
    (idStrumento === "crudeoil" && okOilExtractorH1(user)) ||
    (idStrumento === "gas" && okGasH1(user))
  );
};

export const checkFromIdStrumentoEtf = (idStrumento, user) => {
  return (
    (idStrumento === "nasdaq" && okNasdaqEtf(user)) ||
    (idStrumento === "sp500" && okSp500Etf(user)) ||
    (idStrumento === "msci" && okMsciEtf(user)) ||
    (idStrumento === "gold" && okGoldEtf(user)) ||
    (idStrumento === "battery" && okBatteryEtf(user)) ||
    (idStrumento === "eth" && okEthEtf(user)) ||
    (idStrumento === "btc" && okBtcEtf(user)) ||
    (idStrumento === "blockchain" && okBlockchainEtf(user))
  );
};

//CHECKBOX FILTRI ESITI
export const checkboxFiltroAffidabilita = (x, filtroAffidabilita) => {
  return filtroAffidabilita ? x.affidabilita > 0.5 : x;
};
export const checkboxFiltroProfittabilita = (x, filtroProfittabilita) => {
  return filtroProfittabilita ? x.profittabilita + 0.2 > 0.4 : x;
};
export const checkboxFiltroWeekly = (x, filtroWeekly) => {
  return filtroWeekly
    ? (x.filtroDirezione === "LONG" && x.esitoUlcerIndexW1 === "COMPRA") ||
        (x.filtroDirezione === "SHORT" && x.esitoUlcerIndexW1 === "VENDI")
    : x;
};
export const checkboxFiltroSbs = (x, filtroSbs) => {
  return filtroSbs ? x.filtroDirezione === x.confermaIngressoSbs : x;
};
export const checkboxFiltroRoi = (x, filtroRoi, value) => {
  return filtroRoi ? x.roi > value : x;
};
export const checkboxFiltroHourDaily = (filtroDaily, outputD1, outputH1) => {
  return filtroDaily
    ? outputD1?.filtroDirezione.trim() === outputH1.filtroDirezione.trim()
    : outputH1;
};
//-------------------

export const isLong = esito => esito === "LONG";
export const isShort = esito => esito === "SHORT";

export const valutaQuotataCAD = idStrumento =>
  idStrumento.substring(3) === "CAD";
export const valutaQuotataUSD = idStrumento =>
  idStrumento.substring(3) === "USD";
export const valutaQuotataAUD = idStrumento =>
  idStrumento.substring(3) === "AUD";
export const valutaQuotataJPY = idStrumento =>
  idStrumento.substring(3) === "JPY";
export const valutaQuotataCHF = idStrumento =>
  idStrumento.substring(3) === "CHF";
export const valutaQuotataGBP = idStrumento =>
  idStrumento.substring(3) === "GBP";

// CHECK ADMIN
export const checkIsAdmin = username => {
  return (
    username === "service" ||
    username === "gianlucabuccoliero" ||
    username === "chantalsala" ||
    username === "mirkobiasi" ||
    username === "piramide1881" ||
    username === "piramide18" ||
    username === "manueltrotta87" ||
    username === "egidio1204" ||
    username === "robcolTH" ||
    username === "alleristani" ||
    username === "domeniconisi"
  );
};

// CHECK TYPE FiNANCIAL TOOL
export const checkType = idStrumento => {
  return idStrumento === "NASDAQ" ||
    idStrumento === "SP500" ||
    idStrumento === "DOWJONES" ||
    idStrumento === "DE30"
    ? "INDEX"
    : idStrumento === "SILVER" ||
      idStrumento === "XAUUSD" ||
      idStrumento === "GAS" ||
      idStrumento === "CRUDEOIL"
    ? "COMMODITIES"
    : "CURRENCIES";
};

export const isASymbol = id => {
  return (
    id === "EUR" ||
    id === "CAD" ||
    id === "AUD" ||
    id === "USD" ||
    id === "JPY" ||
    id === "CHF" ||
    id === "NZD" ||
    id === "GBP"
  );
};

export const percentCorrelation = values => {
  return values.filter;
};

export const activeUsers = hour => {
  function randomNumber(min, max) {
    return Math.floor(Math.random() * (max - min) + min);
  }
  var today = new Date();
  if (today.getDay() == 6 || today.getDay() == 0) hour = 2;

  switch (hour) {
    case 0:
      return randomNumber(500, 1000);
    case 1:
      return randomNumber(10, 100);
    case 2:
      return randomNumber(5, 20);
    case 3:
      return randomNumber(0, 15);
    case 4:
      return randomNumber(5, 15);
    case 5:
      return randomNumber(15, 50);
    case 6:
      return randomNumber(200, 1000);
    case 7:
      return randomNumber(1000, 2000);
    case 8:
      return randomNumber(3000, 4000);
    case 9:
      return randomNumber(3000, 4000);
    case 10:
      return randomNumber(3000, 4000);
    case 11:
      return randomNumber(3000, 4000);
    case 12:
      return randomNumber(3000, 4000);
    case 13:
      return randomNumber(3000, 4000);
    case 14:
      return randomNumber(3000, 4000);
    case 15:
      return randomNumber(3000, 4000);
    case 16:
      return randomNumber(3000, 4000);
    case 17:
      return randomNumber(2000, 3000);
    case 18:
      return randomNumber(2000, 2500);
    case 19:
      return randomNumber(1500, 2000);
    case 20:
      return randomNumber(1000, 1500);
    case 21:
      return randomNumber(500, 1000);
    case 22:
      return randomNumber(200, 500);
    case 23:
      return randomNumber(500, 1000);
  }
};

export const displayPropImage = props => {
  return okRoiPropPremium(props) || okRoiPropBasic(props);
};

export const checkVideocorsiForex = props => {
  return (
    okCurrencies(props) ||
    okIndex(props) ||
    okCommodities(props) ||
    okGoldMiner(props) ||
    okOilExtractor(props) ||
    okDax(props)
  );
};
export const checkMasterclassRoiProp = props => {
  return okRoiPropDiamond(props) || okPrintMoney(props);
};
export const checkEsitiSidebar = props => {
  return (
    okCurrencies(props) ||
    okIndex(props) ||
    okCommodities(props) ||
    okGoldMiner(props) ||
    okOilExtractor(props) ||
    okDax(props)
  );
};
export const checkExpertAdvisorTDI = props => {
  return (
    okCurrencies(props) ||
    okIndex(props) ||
    okCommodities(props) ||
    okGoldMiner(props) ||
    okOilExtractor(props) ||
    okDax(props)
  );
};

export const h1 = stat => stat === "hourly";

export const filterH1ToolsByCurrentHour = data =>
  data.filter(x => x.hour === moment().hour());
