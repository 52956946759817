/** @format */

export const currenciesFlags = name => {
  if (
    name === "de30" ||
    name === "crudeoil" ||
    name === "xauusd" ||
    name === "gas" ||
    name === "dowjones" ||
    name === "sp500" ||
    name === "nasdaq" ||
    name === "silver"
  )
    return (
      <div style={{ borderRadius: 0.9, marginTop: 6 }}>
        <img
          alt=""
          src={require(`assets/img/${name}.png`)}
          width={30}
          height={30}
        />
      </div>
    );
  return (
    <div>
      <div
        style={{
          position: "absolute",
          left: 0,
          top: 0,
          visibility: "show",
        }}
      >
        <img
          alt=""
          src={require(`assets/img/${name.substring(0, 3)}.png`)}
          width={20}
          height={20}
        />
      </div>
      <div
        style={{
          position: "absolute",
          right: 5,
          top: 10,
          visibility: "show",
        }}
      >
        <img
          alt=""
          src={require(`assets/img/${name.substring(3, 6)}.png`)}
          width={20}
          height={20}
        />
      </div>
    </div>
  );
};
