/** @format */
import { getCookie } from "cookieCreator";
import { cookieCreator } from "cookieCreator";
import httpClient from "fetchers";

export const responseGetPostUserVideoData = userId => {
  return httpClient(`UserVideoData?userId=${userId}`, true, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getCookie("token")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(response => response.json())
    .catch(error => console.log(error));
};

export const responsePostUserVideoData = userVideoData => {
  return httpClient("UserVideoData/InsertData", false, {
    method: "POST",
    body: JSON.stringify(userVideoData),
  })
    .then(response => response.json())
    .catch(error => console.log("Error detected: " + error));
};
