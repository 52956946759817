/** @format */

import {
  redShort,
  greenLong,
} from "components/StrumentiComponents/StrumentiComponents";

export const sentimentFilterChart = dataSentiment => {
  return {
    labels: ["LONG", "SHORT"],
    options: {
      maintainAspectRatio: false,
      indexAxis: "y",
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          suggestedMax: 1,
          ticks: {
            format: {
              style: "percent",
            },
          },
        },
      },
    },
    datasets: [
      {
        fill: true,
        backgroundColor: [greenLong, redShort],
        borderColor: [greenLong, redShort],
        borderWidth: 2,
        borderDash: [],
        borderDashOffset: 0.0,
        pointBackgroundColor: "#0C1157",
        pointBorderColor: "#0C1157",
        pointHoverBackgroundColor: "#00d6b4",
        pointBorderWidth: 0.5,
        pointHoverRadius: 4,
        pointHoverBorderWidth: 15,
        pointRadius: 2.5,
        data: [
          dataSentiment.longPercentage / 100,
          dataSentiment.shortPercentage / 100,
        ],
      },
    ],
  };
};
