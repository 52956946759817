/** @format */
// GREEN #519259 RED #AE431E

import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Row,
  Col,
  Badge,
  Button,
  CardFooter,
  Table,
  Label,
} from "reactstrap";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { ChartSentimentFilter } from "./ChartCards";
import IrFilterData from "variables/irFilterData";
import { TitleWithFlags } from "assets/currenciesFlags/titleWithFlags";
import { useNavigate } from "react-router-dom";
import TrafficLight from "assets/trafficLight/trafficLight";
import {
  moduloColInCardSm,
  moduloColInCardXs,
  moduloColInCardLg,
} from "components/Modules/ModuleComponents";
import { Gauge } from "@ant-design/plots";
import { checkIsAdmin } from "variables/generalChecker";
import CorrelationFilter from "variables/correlationFilter";
import { isLong } from "variables/generalChecker";
import Select from "react-select";
import { Checkbox } from "@mui/material";
import { Rating } from "react-simple-star-rating";
import { moduloColInCardSemaforo } from "components/Modules/ModuleComponents";
import { addOrUpdateUserClaim } from "fetchers/userClaims";
import { useSelector } from "react-redux";
import { responseGetUserClaim } from "fetchers/userClaims";
import { addFavorite } from "actions/user/userAction";
export const greenLong = "#519259";
export const redShort = "#AE431E";

export function num(number) {
  return number < 1000
    ? number?.toFixed(4).toLocaleString("it")
    : number?.toLocaleString("it");
}

export const CardOutputStrumento = (
  out,
  best,
  type,
  navigate,
  settings,
  user,
  favorites,
  dispatch
) => {
  async function handleFavorite() {
    await dispatch(addFavorite(out.idStrumento));
  }
  return (
    <Card className="card-user">
      {!best && (
        <i
          className={
            favorites?.filter(x => x === out.idStrumento).length > 0
              ? "fa-solid fa-heart fa-lg "
              : "fa-regular fa-heart fa-lg "
          }
          style={{
            position: "absolute",
            right: 12,
            top: 25,
            cursor: "pointer",
            color: "#ac0c0c",
          }}
          onClick={() => handleFavorite()}
        ></i>
      )}
      <CardHeader>
        {best ? (
          <>
            <h4>Best {type}</h4>
            <h5 className="card-title">
              <br /> Affidabilità e Profittabilità {">"} 50%.
              <br />
              Filtro direzione giornaliero concordante con lo Statistic Black
              Swan™ e con la direzione prevista dal filtro weekly.
            </h5>
          </>
        ) : (
          <h5 className="card-category">
            OUTPUT STRUMENTO <br /> Ultimo aggiornamento: {"   "}
            {moment(
              settings?.filter(x => x.idStrumento === out.idStrumento)[0]
                .timeUpd
            ).format("LLL")}
          </h5>
        )}
        <CardTitle
          tag="h2"
          style={{ display: "flex", justifyContent: "center" }}
        >
          <i>
            {!out ? (
              <></>
            ) : (
              TitleWithFlags(out.idStrumento.toLowerCase(), best, navigate)
            )}
          </i>
        </CardTitle>

        <Row style={{ marginBottom: 20 }}>
          {moduloColInCardSm(
            "PREZZO CORRENTE",
            num(
              settings?.filter(x => x.idStrumento === out.idStrumento)[0]
                .currentPrice
            ),
            "#bfa100",
            true,
            best
          )}
          {moduloColInCardSm(
            "DIREZIONE OGGI",
            out?.filtroDirezione,
            out?.filtroDirezione === "LONG" ? "#519259" : "#AE431E",
            true,
            best
          )}
          {best &&
            moduloColInCardSm(
              "LIVELLO SETUP",
              num(out.ingressoBes),
              "darkRed",
              true,
              best
            )}
          {moduloColInCardSm(
            "LIVELLO INGRESSO",
            num(out.livelloIngresso),
            "#91684A",
            true,
            best
          )}

          {moduloColInCardSm("TARGET", num(out.target), "#350B40", true, best)}
          {(checkIsAdmin(user?.userInfo.username) ||
            user?.userInfo.username === "pietro01") &&
            !best &&
            moduloColInCardSm(
              "STOP LOSS",
              num(out.distanzaEffettivaInPip),
              "#91344A",
              true,
              best
            )}
          {moduloColInCardSm(
            "ROI ATTESO",
            (out.roi * 100).toFixed(2) + "%",
            "#E6BD1A",
            true,
            best
          )}

          {moduloColInCardSm(
            "MARGINE CENTRALE",
            (out.margineCentrale * 100).toFixed(0) + "%",
            "#008b8b",
            true,
            best
          )}

          {moduloColInCardSm(
            "MARGINE MASSIMO",
            (out.margineMassimo * 100).toFixed(0) + "%",
            "#00008b",
            true,
            best
          )}

          {!best &&
            moduloColInCardSm(
              "MEDIA MOBILE 21 GIORNI",
              settings?.filter(x => x.idStrumento === out.idStrumento)[0]
                .okMedia21
                ? "IN TREND"
                : "FUORI TREND",
              settings?.filter(x => x.idStrumento === out.idStrumento)[0]
                .okMedia21
                ? "darkGreen"
                : "darkRed",
              true
            )}

          {moduloColInCardSm(
            "CHIUSURA",
            out.confermaDiUscita != "LASCIAR CORRERE"
              ? "ENTRO LE 19"
              : out.confermaDiUscita,
            out.confermaDiUscita === "LASCIAR CORRERE" ? "#519259" : "#AE431E",
            true,
            best,
            true
          )}
        </Row>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardBestFiltro = output => {
  const out = output.output;
  const x = Math.random();
  return (
    <Card className="card-user">
      <CardHeader>
        <Row
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Col lg="6">
            {DemoGauge(
              "AFFIDABILITÀ",
              out.affidabilita,
              out.affidabilita > 0.5 ? greenLong : redShort
            )}
          </Col>
          <Col lg="6">
            {DemoGauge(
              "PROFITTABILITÀ",
              out.profittabilita,
              out.profittabilita > 0.5 ? greenLong : redShort
            )}
          </Col>

          {/* {moduloColInCardSm(
              "PROFITTABILITÀ",
              (out.profittabilita * 100).toFixed(2) + "%",
              out.profittabilita > 0.5 ? greenLong : redShort
            )} */}
        </Row>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardUlcerIndex = output => {
  const out = output.output;

  return (
    <Card className="card-user">
      <CardHeader>
        <h4>Output Ulcer Index™</h4>
        <CardTitle tag="h2">
          <Row>
            {moduloColInCardLg(
              "ULCER INDEX™",
              out.esitoUlcerIndex,
              out.esitoUlcerIndex === "COMPRA"
                ? greenLong
                : out.esitoUlcerIndex === "VENDI"
                ? redShort
                : "darkgrey"
            )}
            {moduloColInCardLg(
              "UIS™ SETTIMANALE",
              out.esitoUlcerIndexSmoothedWeek,
              out.esitoUlcerIndexSmoothedWeek === "COMPRA"
                ? greenLong
                : out.esitoUlcerIndexSmoothedWeek === "VENDI"
                ? redShort
                : "darkgrey"
            )}
            {moduloColInCardLg(
              "UIS™ MENSILE",
              out.esitoUlcerIndexSmoothedMonth,
              out.esitoUlcerIndexSmoothedMonth === "COMPRA"
                ? greenLong
                : out.esitoUlcerIndexSmoothedMonth === "VENDI"
                ? redShort
                : "darkgrey"
            )}
          </Row>
        </CardTitle>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardSeasonality = output => {
  const out = output.output;

  return (
    <Card className="card-user">
      <CardHeader>
        <h4>Output SEASONALITY™</h4>
        <CardTitle tag="h2">
          <Row>
            {moduloColInCardLg(
              "CONCORDANZA MENSILE",
              out.concordanzaMonth ? out.concordanzaMonth : "NON CONCORDANTE",
              out.concordanzaMonth === "LONG"
                ? greenLong
                : out.concordanzaMonth === "SHORT"
                ? redShort
                : "darkred"
            )}
            {moduloColInCardLg(
              "CONCORDANZA TRIMESTRALE",
              out.concordanzaTrim ? out.concordanzaTrim : "NON CONCORDANTE",
              out.concordanzaTrim === "LONG"
                ? greenLong
                : out.concordanzaTrim === "SHORT"
                ? redShort
                : "darkred"
            )}
          </Row>
        </CardTitle>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardMargini = output => {
  const out = output.output;

  return (
    <Card className="card-user">
      <CardHeader>
        <h4>MARGINI & VOLATILITÀ</h4>
        <CardTitle tag="h2">
          <Row style={{ display: "flex", justifyContent: "space-around" }}>
            {moduloColInCardSm("MARGINE MINIMO", out.margineMinimo * 100 + "%")}
            {moduloColInCardSm(
              "VOLATILITÀ 4 WEEKS",
              out.volatilita4Settimane + "%"
            )}
          </Row>
          <Row style={{ display: "flex", justifyContent: "space-around" }}>
            {moduloColInCardSm(
              "MARGINE CENTRALE",
              (out.margineCentrale * 100).toFixed(0) + "%"
            )}
            {moduloColInCardSm(
              "VOLATILITÀ 25 WEEKS",
              out.volatilita25Settimane + "%"
            )}
          </Row>
          <Row style={{ display: "flex", justifyContent: "space-around" }}>
            {moduloColInCardSm(
              "MARGINE MASSIMO",
              (out.margineMassimo * 100).toFixed(0) + "%"
            )}
            {moduloColInCardSm(
              "VOLATILITÀ MEDIA",
              out.variazionePercentuale2 + "%"
            )}
          </Row>
        </CardTitle>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardBes = props => {
  const [text, setText] = useState(0);
  const [rating, setRating] = useState(4);
  const out = props.output;
  const userData = props.userData;

  if (
    !userData.categories.filter(
      x =>
        x.descrizioneCategoria === "Thousandollar" ||
        x.descrizioneCategoria === "Milliondollar" ||
        x.descrizioneCategoria === "TheWolfOfIndexes"
      // x.descrizioneCategoria === "TheWolfOfCommodities"
    ).length > 0
  )
    return;

  const getTrafficLight = type => {
    const settingsOfThis = props.settings?.filter(
      x => x.idStrumento === props.idStrumento.toUpperCase()
    )[0];
    return type === "setup" ? (
      settingsOfThis?.okSetup ? (
        <>
          <TrafficLight
            onMouseEnter={e => setText(1)}
            onMouseLeave={e => setText(0)}
            GreenOn
            Horizontal
            style={{ width: 100 }}
          />
          <p
            style={{
              opacity: text,
              position: "absolute",
              top: 2,
              left: 240,
              fontFamily: "cursive",
            }}
          >
            some dummy text
          </p>
        </>
      ) : (
        <TrafficLight YellowOn Horizontal style={{ width: 100 }} />
      )
    ) : type === "media21" ? (
      settingsOfThis?.okSetup.okMedia21 ? (
        <TrafficLight RedOn Horizontal style={{ width: 100, marginLeft: 10 }} />
      ) : (
        <>
          <TrafficLight
            onMouseEnter={e => setText(1)}
            onMouseLeave={e => setText(0)}
            GreenOn
            Horizontal
            style={{ width: 100 }}
          />
          <p
            style={{
              opacity: text,
              position: "absolute",
              top: 2,
              left: 240,
              fontFamily: "cursive",
            }}
          >
            some dummy text
          </p>
        </>
      )
    ) : (
      <TrafficLight RedOn Horizontal style={{ width: 100 }} />
    );
  };

  return (
    <Card className="card-user">
      <CardHeader>
        <Row style={{ marginLeft: 10 }}>
          <h4 style={{ marginRight: 10, marginTop: 15 }}>
            BES™ Best Entry Strategy
          </h4>
          {getTrafficLight("setup")}
        </Row>
        <Row>
          {moduloColInCardSm(
            "LIVELLO SETUP",
            out.ingressoBes.toFixed(4),
            "red"
          )}
          {moduloColInCardSm(
            "LIVELLO SUL QUALE INSERIRE",
            out.ingressoEstremo.toFixed(4),
            "blue"
          )}
        </Row>
      </CardHeader>
      <CardFooter />
    </Card>
  );
};
export const CardSbs = props => {
  const [rating, setRating] = useState(4);
  const out = props.output;
  const userData = props.userData;

  return (
    <Card className="card-user">
      <CardHeader>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 0,
          }}
        >
          <h4>SBS™ - Statistic Black Swan</h4>
          {checkIsAdmin(userData.userInfo.username) && (
            <Rating
              initialValue={rating}
              readonly
              size={15}
              style={{ position: "absolute", left: 15, top: 35 }}
            />
          )}
        </div>
        <CardTitle tag="h2">
          <Row>
            {moduloColInCardLg(
              "CONFERMA INGRESSO",
              out.confermaIngressoSbs === "FILTRO NON CONCORDANTE"
                ? "OUT"
                : out.confermaIngressoSbs,
              out.confermaIngressoSbs === "LONG"
                ? greenLong
                : out.confermaIngressoSbs === "SHORT"
                ? redShort
                : out.confermaIngressoSbs === "FILTRO NON CONCORDANTE"
                ? "darkred"
                : redShort
            )}
            {moduloColInCardLg(
              "CONCORDANZA",
              out.confermaIngressoSbs === out?.filtroDirezione ? "IN" : "OUT",
              out.confermaIngressoSbs === out?.filtroDirezione
                ? greenLong
                : "darkred"
            )}
          </Row>
        </CardTitle>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardAddFilter = output => {
  const out = output.output;
  return (
    <Card className="card-user">
      <CardHeader>
        <h4>ADD FILTER™ - Analisi Della Direzionalità</h4>
        <CardTitle tag="h2">
          <Row>
            {moduloColInCardSm("VALUTA 1", out.idStrumento.substring(0, 3))}
            {moduloColInCardSm("VALUTA 2", out.idStrumento.substring(3, 6))}
          </Row>
          <Row>
            {moduloColInCardSm(
              `ESITO ${out.idStrumento.substring(0, 3)}`,
              out.esitoNum,
              out.esitoNum === "LONG" ? greenLong : redShort
            )}
            {moduloColInCardSm(
              `ESITO ${out.idStrumento.substring(3, 6)}`,
              out.esitoDen,
              out.esitoDen === "LONG" ? greenLong : redShort
            )}
          </Row>
          <Row>
            {moduloColInCardSm(
              "ESITO DIREZIONALITÀ",
              out.esitoDirezionalita === ""
                ? "NON CONCORDANTE"
                : out.esitoDirezionalita,
              out.esitoDirezionalita === "LONG" ? greenLong : redShort
            )}
            {moduloColInCardSm(
              "CONCORDANZA CON DIREZIONE",
              out.esitoDirezionalita === out?.filtroDirezione ? "IN" : "OUT",
              out.esitoDirezionalita === out?.filtroDirezione
                ? greenLong
                : redShort
            )}
          </Row>
        </CardTitle>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardIrFilter = props => {
  if (props.currencyOut) return;
  const out = props.currencyOut;
  const irDataThis = IrFilterData(
    props.dataIR,
    props.output,
    props.currencyOut.idStrumento,
    false
  );

  return (
    <Card className="card-user">
      <CardHeader>
        <h4>IR FILTER™ - INTEREST RATE FILTER</h4>
        <CardTitle tag="h2">
          <Row>
            {moduloColInCardLg(
              "CONCORDANZA IR FILTER™",
              irDataThis ? "CONCORDANTE" : "NON CONCORDANTE",
              irDataThis ? greenLong : redShort
            )}
          </Row>
        </CardTitle>
      </CardHeader>
      <CardFooter></CardFooter>
    </Card>
  );
};

export const CardSentimentFilter = props => {
  const out = props.outputSentiment[0];
  if (
    out === undefined ||
    (out.longPercentage < 10 && out.shortPercentage < 10)
  )
    return;

  return (
    <Card className="card-user">
      <CardHeader>
        <h4>SENTIMENT FILTER™</h4>
        <CardTitle tag="h2">
          <Row>
            {moduloColInCardSm(
              "PERCENTUALE LONG",
              out.longPercentage + "%",
              out.longPercentage > 50 ? "#0C1157" : "grey"
            )}
            {moduloColInCardSm(
              "PERCENTUALE SHORT",
              out.shortPercentage + "%",
              out.shortPercentage > 50 ? "#0C1157" : "grey"
            )}
          </Row>
          <Row>
            {moduloColInCardSm("VOLUME LONG", out.longVolume, greenLong)}
            {moduloColInCardSm("VOLUME SHORT", out.shortVolume, redShort)}
          </Row>
          <Row>
            {moduloColInCardSm("POSIZIONI LONG", out.longPositions, greenLong)}
            {moduloColInCardSm("POSIZIONI SHORT", out.shortPositions, redShort)}
          </Row>

          <ChartSentimentFilter sentimentData={out} />
        </CardTitle>
      </CardHeader>
      <CardFooter>
        Dati offerti da{" "}
        <a href="https://www.myfxbook.com/" target="_blank">
          Myfxbook.com
        </a>
        <br />
        Per gli strumenti NON FOREX, alcuni valori come volume e posizioni
        aperte possono risultare non attendibili.
      </CardFooter>
    </Card>
  );
};

export const CardCalendario = props => {
  const [rowToView, setRowToView] = useState(10);
  const [fromToday, setFromToday] = useState(true);
  const [selectedFilter, setSelectedFilter] = useState([]);
  const [displayFilter, setDisplayFilter] = useState(false);

  const textColor = value => {
    switch (value) {
      case "LONG":
      case "COMPRA":
        return greenLong;

      case "BASSA":
        return "#988e75";

      case "MEDIA":
        return "#c3aa5b";

      case "ALTA":
        return "#ebc834";

      case "ALTISSIMA":
        return "#ffd700";
      default:
        return redShort;
    }
  };

  const concordanza = (a, b, c, d, e) => {
    let countLong = 0;
    let countShort = 0;
    if (a === "SHORT") countShort++;
    else if (a === "LONG") countLong++;
    if (b === "SHORT") countShort++;
    else if (b === "LONG") countLong++;
    if (c === "SHORT") countShort++;
    else if (c === "LONG") countLong++;
    if (d === "SHORT") countShort++;
    else if (d === "LONG") countLong++;
    if (e === "SHORT") countShort++;
    else if (e === "LONG") countLong++;

    const concordanza =
      countLong > countShort
        ? countLong === 5
          ? "ESTREMA"
          : countLong === 4
          ? "ALTISSIMA"
          : countLong === 3
          ? "ALTA"
          : countLong === 2
          ? "MEDIA"
          : "BASSA"
        : countShort === 5
        ? "ESTREMA"
        : countShort === 4
        ? "ALTISSIMA"
        : countShort === 3
        ? "ALTA"
        : countShort === 2
        ? "MEDIA"
        : "BASSA";

    return concordanza;
  };
  const concordanzaEtf = (a, b, c, d, e) => {
    let countCompra = 0;
    if (a === "COMPRA") countCompra++;
    if (b === "COMPRA") countCompra++;
    if (c === "COMPRA") countCompra++;
    if (d === "COMPRA") countCompra++;
    if (e === "COMPRA") countCompra++;

    const concordanza =
      countCompra === 5
        ? "ESTREMA"
        : countCompra === 4
        ? "ALTISSIMA"
        : countCompra === 3
        ? "ALTA"
        : countCompra === 2
        ? "MEDIA"
        : "BASSA";

    return concordanza;
  };

  const filters = [
    "UI™ Daily",
    "SBS™ Daily",
    "UI™ Weekly",
    "UIS™ Weekly",
    "UIS™ Monthly",
    "BES™",
    "Seasonality Mens.",
    "Seasonality Trim.",
  ];

  const headerWithFilter = (title, type) => {
    const labels = ["BASSA", "MEDIA", "ALTA", "ALTISSIMA", "ESTREMA"];
    return (
      <Row className="text-center" style={{ justifyContent: "center" }}>
        <Col lg="12">{title}</Col>
        <Col lg="4" style={{ width: 150 }}>
          {displayFilter ? (
            <Select
              defaultValue={labels.map(opt => ({
                label: opt,
                value: opt,
              }))}
              isMulti
              options={labels.map(opt => ({
                label: opt,
                value: opt,
              }))}
              placeholder={"-"}
              onChange={opt => {
                setSelectedFilter(opt.label);
              }}
              styles={{
                option: base => ({
                  ...base,
                  color: `black`,
                  height: "100%",
                }),
              }}
            />
          ) : (
            <img
              alt="telegram"
              src={require("assets/img/search.png")}
              width={30}
              height={30}
              style={{ cursor: "pointer" }}
              onClick={() => setDisplayFilter(true)}
            />
          )}
        </Col>
      </Row>
    );
  };

  return (
    <Card className="card-user">
      <CardHeader>
        <CardTitle tag="h2" className="text-center">
          CALENDARIO
          <button
            className="btn btn-info btn-sm"
            style={{ position: "absolute", left: 4, top: 10 }}
            onClick={() => setFromToday(!fromToday)}
          >
            {fromToday ? "Da oggi" : "Dall'inizio"}
          </button>
        </CardTitle>
        {/* <Row>
          {filters.map(filter => (
            <Col lg="2" md="3" xs="4">
              <Checkbox defaultChecked onClick={() => {}} />
              <Label style={{ marginTop: 12 }}>{filter}</Label>
            </Col>
          ))}
        </Row> */}
      </CardHeader>
      <CardBody>
        <Table
          responsive={window.innerWidth < 800}
          striped
          style={{ width: "100%" }}
        >
          <thead>
            <tr>
              <th>Data</th>
              <th className="text-center">UI™ Daily</th>
              <th className="text-center">SBS™ Daily</th>
              <th className="text-center">UI™ Weekly</th>
              <th className="text-center">UIS™ Weekly</th>
              <th className="text-center">UIS™ Monthly</th>
              <th className="text-center">{headerWithFilter("Concordanza")}</th>
            </tr>
          </thead>
          <tbody>
            {props.calendarData
              ?.filter(x =>
                fromToday ? moment(x.date) >= moment().subtract(1, "days") : x
              )
              .filter(
                x =>
                  selectedFilter === "-" ||
                  selectedFilter ===
                    concordanza(
                      x.valueDaily,
                      x.valueSbs,
                      x.valueWeekly,
                      x.valueUISWeekly,
                      x.valueUISMonthly
                    )
              )
              .filter((x, i) => i < rowToView)
              .map(row => {
                return (
                  <tr key={row.date}>
                    <td>{moment(row.date).format("DD/MM/YY")}</td>
                    <td
                      className="text-center"
                      style={{
                        textShadow: `1px 1px 10px ${textColor(row.valueDaily)}`,
                      }}
                    >
                      {row.valueDaily}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        textShadow: `1px 1px 10px ${textColor(row.valueSbs)}`,
                      }}
                    >
                      {row.valueSbs}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        textShadow: `1px 1px 10px ${textColor(
                          row.valueWeekly
                        )}`,
                      }}
                    >
                      {row.valueWeekly}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        textShadow: `1px 1px 10px ${textColor(
                          row.valueUISWeekly
                        )}`,
                      }}
                    >
                      {row.valueUISWeekly}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        textShadow: `1px 1px 10px ${textColor(
                          row.valueUISMonthly
                        )}`,
                      }}
                    >
                      {row.valueUISMonthly}
                    </td>
                    <td
                      className="text-center"
                      style={{
                        textShadow: `2px 2px 10px ${textColor(
                          row.concordanza
                        )}`,
                      }}
                    >
                      {props.strumento === "etf"
                        ? concordanzaEtf(
                            row.valueDaily,
                            row.valueSbs,
                            row.valueUISWeekly,
                            row.valueUISMonthly,
                            row.valueWeekly
                          )
                        : concordanza(
                            row.valueDaily,
                            row.valueSbs,
                            row.valueUISWeekly,
                            row.valueUISMonthly,
                            row.valueWeekly
                          )}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </CardBody>
      <CardFooter style={{ display: "flex", justifyContent: "center" }}>
        <Button onClick={() => setRowToView(rowToView + 10)}>
          Mostra altri
        </Button>
      </CardFooter>
    </Card>
  );
};

export const DemoGauge = (title, value, color) => {
  const config = {
    percent: Number(value),
    range: {
      color: color,
    },
    indicator: {
      pointer: {
        style: {
          stroke: "#D0D0D0",
        },
      },
      pin: {
        style: {
          stroke: "#D0D0D0",
        },
      },
    },
    axis: {
      label: {
        formatter(v) {
          return Number(v) * 100;
        },
      },
      subTickLine: {
        count: 3,
      },
    },
    statistic: {
      content: {
        formatter: ({ percent }) =>
          `${title}: ${(percent * 100).toFixed(2)}`.split(".00")[0] + "%",
        style: {
          fontSize: 16,
          color: "color",
        },
      },
    },
  };
  return <Gauge {...config} animation />;
};
