/** @format */

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  FormLabel,
  Input,
  TextareaAutosize,
} from "@mui/material";
import {
  Button,
  Col,
  Container,
  Form,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";

function YoutubeLinksDialog(props) {
  return (
    <Dialog open={props.open}>
      <DialogTitle id="title" color="black">
        <h2 style={{ color: "black" }}>
          PROBLEMI NELLA VISUALIZZAZIONE DEI VIDEOCORSI?
        </h2>
        <h4 style={{ color: "black" }}>Puoi guardarli anche su Youtube!</h4>
      </DialogTitle>
      <DialogContent>
        <Row>
          <Col lg="8">
            <a target="_blank" href="https://youtu.be/VQKOla9VUGQ">
              Presentazione MTplatform™
            </a>
            <br />
            <br />
            <a target="_blank" href="https://youtu.be/bQmTq_F33-4">
              Caricare e rimuovere l'Expert Advisor
            </a>
            <br />
            <br />
            <a target="_blank" href="https://youtu.be/6GGFHTyTzQ8">
              Calcolo margine richiesto
            </a>
            <br />
            <br />
            <a target="_blank" href="https://youtu.be/0_-clApLqkE">
              Simulazione inserimento operazione
            </a>
            <br />
            <br />
            <a target="_blank" href="https://youtu.be/owFcoOan8yM">
              Come eseguire un back-test
            </a>
          </Col>

          <Col>
            <img
              alt=""
              src={require("assets/img/youtube.png")}
              width={150}
              height={150}
            />
          </Col>
        </Row>
      </DialogContent>
      <Button onClick={() => props.setModalOpen(!props.open)}>Chiudi</Button>
    </Dialog>
  );
}

export default YoutubeLinksDialog;
