/** @format */
import { getCookie } from "cookieCreator";
import { cookieCreator } from "cookieCreator";
import httpClient from "fetchers";

export const responseGetUserClaim = userId => {
  return httpClient(`UserClaim?userId=${userId}`, true, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${getCookie("token")}`,
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(response => response.json())
    .catch(error => console.log(error));
};

export const addOrUpdateUserClaim = (userId, claimId, value) => {
  return httpClient(
    `UserClaim/InsertData?userId=${userId}&claimId=${claimId}`,
    true,
    {
      Authorization: `Bearer ${getCookie("token")}`,
      method: "POST",
      body: JSON.stringify(value),
    }
  )
    .then(response => response.json())
    .catch(error => console.log("Error detected: " + error));
};
