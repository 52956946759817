/** @format */

export default function CorrelationFilter(outputStrumenti, type, position) {
  const countLong = outputStrumenti?.filter(
    x => x.filtroDirezione.trim() === "LONG"
  ).length;
  const countShort = outputStrumenti?.filter(
    x => x.filtroDirezione.trim() === "SHORT"
  ).length;

  const total = outputStrumenti?.length;
  if (countLong > countShort)
    if (type === "INDEXES" || type === "COMMODITIES")
      return {
        esito: "DA COMPRARE",
        coefficiente: (countLong / total).toFixed(2),
        total: total,
        countRes: countLong,
      };
    else
      return {
        esito: position ? "DA COMPRARE" : "DA VENDERE",
        coefficiente: (countLong / total).toFixed(2),
        total: total,
        countRes: countLong,
      };
  else if (type === "INDEXES" || type === "COMMODITIES")
    return {
      esito: "DA VENDERE",
      coefficiente: (countShort / total).toFixed(2),
      total: total,
      countRes: countLong,
    };
  else
    return {
      esito: position ? "DA VENDERE" : "DA COMPRARE",
      coefficiente: (countShort / total).toFixed(2),
      total: total,
      countRes: countShort,
    };
}

export const assetsClasses = [
  "AUD",
  "CAD",
  "CHF",
  "GBP",
  "JPY",
  "NZD",
  "USD",
  "INDEX",
  "COMMODITIES",
];
