/** @format */
import serviceCardImg from "assets/serviceCardImg/serviceCardImg";
import { PlayerComponent } from "components/ReactPlayer/PlayerComponent";
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardTitle,
  Col,
  Row,
} from "reactstrap";
import { okRoiPropDiamond } from "variables/activeTools";
import { displayPropImage } from "variables/generalChecker";

export const RoiPropHeader = props => {
  return (
    <Row className="mb-5">
      <Col
        lg="4"
        xs="12"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <img
          alt={"logo"}
          src={require(`assets/img/roiprop_logo.png`)}
          width={300}
          height={200}
        />
      </Col>

      <Col lg="8" xs="12">
        <a
          href="https://www.tradingstatistico.it/area-riservata/signup/m8xR1ohr"
          target="_blank"
        >
          <img
            alt={"roiprop"}
            src={require(`assets/img/roiprop_image1.jpg`)}
            width={"100%"}
            height={"100%"}
          />
        </a>
      </Col>
    </Row>
  );
};

export const RoiProp1 = props => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col
            xs="12"
            md="3"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h4>PROP 1</h4>
            <h3>FTMO</h3>
          </Col>
          <Col xs="12" md="9">
            <a
              href="https://trader.ftmo.com/?affiliates=cvdyOjUcBlWMpiztwFUa"
              target="_BLANK"
              rel="nofollow"
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <img src={require(`assets/img/prop1.jpg`)} />
            </a>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export const RoiProp2 = props => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col
            xs="12"
            md="3"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h4>PROP 2</h4>
            <h3>APEX TRADER FUNDING</h3>
          </Col>
          <Col xs="12" md="9">
            <a
              href="https://apextraderfunding.com/?c=BMKNNGUG"
              target="_BLANK"
              rel="nofollow"
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <img src={require(`assets/img/prop2.jpg`)} />
            </a>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export const RoiProp3 = props => {
  return (
    <Card>
      <CardBody>
        <Row>
          <Col
            xs="12"
            md="3"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h4>PROP 3</h4>
            <h3>THE TRADING PIT</h3>
          </Col>
          <Col xs="12" md="9">
            <a
              href="https://www.thetradingpit.com/become-an-affiliate?tap_a=127375-00d232&ref=ywjhyjy"
              rel="nofollow"
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <img src={require(`assets/img/prop3.jpg`)} />
            </a>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export const RoiPropCardLinkFacebook = props => {
  return (
    <Card>
      <a
        href="https://www.facebook.com/groups/roiprop/"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex" }}>
            <img
              alt={"facebook"}
              src={require(`assets/img/facebook.png`)}
              width={100}
              height={100}
              style={{ alignSelf: "center" }}
            />
            <div style={{ marginLeft: 90 }}>
              <h4>GRUPPO FACEBOOK ROIPROP™</h4>
              <h6>
                Gruppo di supporto e sostegno con tutti gli studenti per
                condividere esperienze e considerazioni!
              </h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const RoiPropCardLinkTelegram = props => {
  return (
    <Card>
      <a href="https://t.me/+6P2OyCz_Tms5OGM0" target="_blank" rel="noreferrer">
        <CardHeader>
          <CardTitle style={{ display: "flex" }}>
            <img
              alt={"telegram"}
              src={require(`assets/img/telegram.png`)}
              width={100}
              height={100}
              style={{ alignSelf: "center" }}
            />
            <div style={{ marginLeft: 90 }}>
              <h4>GRUPPO TELEGRAM ROIPROP™</h4>
              <h6>
                Gruppo Telegram esclusivo con tutti gli studenti per condividere
                operatività e riuscire a superare le challenge!
              </h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const RoiPropCardLinkExpert = props => {
  return (
    <Card>
      <a
        href="https://drive.google.com/drive/folders/1pnodVw5MU7y5uY7py2vxBCAp7JXVh3MP?usp=share_link"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex" }}>
            <img
              alt={"facebook"}
              src={require(`assets/img/expertadvisor.png`)}
              width={150}
              height={100}
              style={{ alignSelf: "center" }}
            />
            <div style={{ marginLeft: 40 }}>
              <h4>EXPERT ADVISOR ROIPROP™</h4>
              <h6>
                Expert studiato appositamente per operare sulle Prop,
                utilizzabile su MetaTrader 4!
              </h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const RoiPropCardLinkIndicatori = props => {
  return (
    <Card>
      <a
        href="https://drive.google.com/drive/folders/1Qw3KULS8oahzvq8SjTeX891NoLBvcNrc?usp=drive_link"
        target="_blank"
        rel="noreferrer"
      >
        <CardHeader>
          <CardTitle style={{ display: "flex" }}>
            <img
              alt={"facebook"}
              src={require(`assets/img/roiprop_strategy.png`)}
              width={120}
              height={100}
              style={{ alignSelf: "center" }}
            />
            <div style={{ marginLeft: 70 }}>
              <h4>INDICATORI ROIPROP™ STRATEGY</h4>
              <h6>Indicatori di trend e order block per strategia ROIPROP™</h6>
            </div>
          </CardTitle>
        </CardHeader>
      </a>
    </Card>
  );
};

export const RoiPropCardLinkMasterClass = props => {
  return (
    <Row className="mb-5">
      {okRoiPropDiamond(props.user) && (
        <a href="https://streamyard.com/watch/A8yZ6FRfduDx" target="_blank">
          <img
            alt={"roiprop_master"}
            src={require(`assets/img/roiprop_masterclass.png`)}
            width={"100%"}
            height={"100%"}
          />
        </a>
      )}
    </Row>
  );
};
