/** @format */

import { greenLong } from "components/StrumentiComponents/StrumentiComponents";
import { redShort } from "components/StrumentiComponents/StrumentiComponents";
import { BackgroundColorContext } from "contexts/BackgroundColorContext";
import { ThemeContext } from "contexts/ThemeContext";
import { responseEtfData } from "fetchers/etfData";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Card, CardFooter, CardHeader, CardTitle, Col, Row } from "reactstrap";
import GaugeChart from "react-gauge-chart";
import { moduloColInCardLg } from "components/Modules/ModuleComponents";
import TableCalendar from "components/Tables/TableCalendar";

function EtfTemplate(props) {
  const [etfData, setEtfData] = useState([]);
  const [flagCalendar, setFlagCalendar] = useState(false);
  const idStrumento = useLocation().pathname.split("/")[3];
  const navigate = useNavigate();
  const [todayValue, setTodayValue] = useState(null);

  function transformName(frontendName) {
    switch (frontendName) {
      case "msci":
        return "AMS_IWDA";
      case "nasdaq":
        return "BIT_CSNDX";
      case "sp500":
        return "FRA_SXR8";
      case "battery":
        return "FRA_BATE";
      case "gold":
        return "BIT_SGLD";
      case "eth":
        return "EPA_ZETH";
      case "btc":
        return "AMS_DE000A27Z304";
      case "blockchain":
        return "LON_BCHN";
      default:
        return "error";
    }
  }
  function transformToFullname(frontendName) {
    switch (frontendName) {
      case "msci":
        return "iShares Core MSCI World UCITS ETF USD (Acc)";
      case "nasdaq":
        return "iShares NASDAQ 100 UCITS ETF USD (Acc)";
      case "sp500":
        return "iShares Core S&P 500 UCITS ETF USD (Acc)";
      case "battery":
        return "L&G Battery Value-Chain UCITS ETF USD Acc";
      case "gold":
        return "Invesco Physical Gold ETC";
      case "eth":
        return "ETC Issuance ETHetc - Physical Ethereum ETN";
      case "btc":
        return "BTCetc Bitcoin Exchange Traded Crypto (BTCE) ETN";
      case "blockchain":
        return "Ivz Coinshares Blockchain Ucits ETF GBX";
      default:
        return "error";
    }
  }
  const concordanzaLevel = (a, b, c, d, e) => {
    let countCompra = 0;
    if (a === "COMPRA") countCompra++;
    if (b === "COMPRA") countCompra++;
    if (c === "COMPRA") countCompra++;
    if (d === "COMPRA") countCompra++;
    if (e === "COMPRA") countCompra++;
    return countCompra / 5;
  };

  useEffect(() => {
    async function getResponse() {
      const responseEtf = await responseEtfData(transformName(idStrumento));

      if (responseEtf) {
        setEtfData(responseEtf);
        const lastDayObj = responseEtf.finalCalendarList.filter(
          x => moment(x.date) < moment()
        );
        setTodayValue(lastDayObj[lastDayObj.length - 1]);
        setFlagCalendar(true);
      }
    }

    getResponse();
  }, [idStrumento]);

  const textColor = value => {
    switch (value) {
      case "LONG":
      case "COMPRA":
        return greenLong;

      case "BASSA":
        return "#988e75";

      case "MEDIA":
        return "#c3aa5b";

      case "ALTA":
        return "#ebc834";

      case "ALTISSIMA":
        return "#ffd700";
      default:
        return redShort;
    }
  };

  return false ? (
    <></>
  ) : (
    <ThemeContext.Consumer>
      {({ theme }) => (
        <BackgroundColorContext.Consumer>
          {({ color }) => (
            <div className="content">
              {flagCalendar ? (
                <>
                  <Row>
                    <Col>
                      <Card>
                        <CardTitle tag="h1">
                          {transformToFullname(idStrumento)}
                        </CardTitle>
                        <CardTitle tag="h3">
                          {transformName(idStrumento)}
                        </CardTitle>
                      </Card>
                      <Card>
                        <CardHeader>
                          <CardTitle>RISULTATO DIREZIONALITÀ</CardTitle>
                          <Row>
                            {moduloColInCardLg(
                              "DIREZIONE GIORNALIERA",
                              todayValue.valueDaily,
                              textColor(todayValue.valueDaily)
                            )}
                            {moduloColInCardLg(
                              "DIREZIONE SETTIMANALE",
                              todayValue.valueWeekly,
                              textColor(todayValue.valueWeekly)
                            )}
                            {moduloColInCardLg(
                              "DIREZIONE SBS",
                              todayValue.valueSbs,
                              textColor(todayValue.valueSbs)
                            )}
                            {moduloColInCardLg(
                              "DIREZIONE UIS MENSILE",
                              todayValue.valueUISMonthly,
                              textColor(todayValue.valueUISMonthly)
                            )}
                            {moduloColInCardLg(
                              "DIREZIONE UIS SETTIMANALE",
                              todayValue.valueUISWeekly,
                              textColor(todayValue.valueUISWeekly)
                            )}
                          </Row>
                        </CardHeader>
                        <CardFooter />
                      </Card>
                    </Col>
                    <Col lg="6" sm="12">
                      <GaugeChart
                        id="gauge-chart5"
                        nrOfLevels={10}
                        arcsLength={[0.2, 0.2, 0.2, 0.2, 0.2]}
                        colors={["#988e75", "#c3aa5b", "#ebc834", "#ffd700"]}
                        percent={concordanzaLevel(
                          todayValue.valueDaily,
                          todayValue.valueSbs,
                          todayValue.valueUISWeekly,
                          todayValue.valueUISMonthly,
                          todayValue.valueWeekly
                        )}
                        arcPadding={0.02}
                        cornerRadius={10}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <TableCalendar
                      idStrumento={idStrumento}
                      calendarData={etfData.finalCalendarList}
                      type="etf"
                    />
                  </Row>
                </>
              ) : (
                <></>
              )}
            </div>
          )}
        </BackgroundColorContext.Consumer>
      )}
    </ThemeContext.Consumer>
  );
}

export default EtfTemplate;
