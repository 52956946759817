/** @format */

import React, { useState, useEffect } from "react";
import { StatisticsContext, studies } from "contexts/StatisticsContext";

export default function StatisticsContextWrapper(props) {
  const [statistics, setStatistics] = useState(studies.d1);

  function changeStudies(statistics) {
    setStatistics(statistics);
  }

  useEffect(() => {
    switch (statistics) {
      case studies.d1:
        document.body.classList.remove("hourly");
        document.body.classList.remove("daily");
        document.body.classList.add("daily");
        break;
      case studies.h1:
      default:
        document.body.classList.remove("hourly");
        document.body.classList.remove("daily");
        document.body.classList.add("hourly");
        break;
    }
  }, [statistics]);

  return (
    <StatisticsContext.Provider
      value={{ statistics: statistics, changeStudies: changeStudies }}
    >
      {props.children}
    </StatisticsContext.Provider>
  );
}
