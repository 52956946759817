/** @format */

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Switch,
} from "@mui/material";
import { StrumentoFrontCard } from "components/EsitiComponent/EsitiComponent";
import { StrumentoFrontCardH1 } from "components/EsitiComponent/EsitiComponentH1";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "react-redux";

// reactstrap components
import { Card, CardHeader, CardTitle, Row, Col, Label } from "reactstrap";
import { okH1 } from "variables/activeTools";
import { okD1 } from "variables/activeTools";
import { checkboxFiltroRoi } from "variables/generalChecker";
import { checkFromIdStrumentoCurrenciesH1 } from "variables/generalChecker";
import { checkFromIdStrumentoH1 } from "variables/generalChecker";
import { checkboxFiltroProfittabilita } from "variables/generalChecker";
import { checkboxFiltroHourDaily } from "variables/generalChecker";
import { checkboxFiltroAffidabilita } from "variables/generalChecker";
import { checkboxFiltroSbs } from "variables/generalChecker";
import { checkboxFiltroWeekly } from "variables/generalChecker";
import { checkFromIdStrumento } from "variables/generalChecker";

function Esiti(props) {
  const output = useSelector(state => state.output);
  const user = useSelector(state => state.user);
  const [orderType, setOrderType] = useState("Affidabilita");
  const [filtroSbs, setFiltroSbs] = useState(true);
  const [filtroWeekly, setFiltroWeekly] = useState(true);
  const [filtroRoi, setFiltroRoi] = useState(true);
  const [filtroAffidabilita, setFiltroAffidabilita] = useState(true);
  const [filtroProfittabilita, setFiltroProfittabilita] = useState(true);
  const [filtroDaily, setFiltroDaily] = useState(false);
  const [checkedd1h1, setCheckedD1H1] = React.useState(false); //false per d1

  React.useEffect(() => {
    if (okD1(output) && !okH1(output)) setCheckedD1H1(false);
    else if (!okD1(output) && okH1(output)) setCheckedD1H1(true);
  }, []);

  const handleChange = event => {
    setCheckedD1H1(event.target.checked);
  };

  return (
    <>
      <div className="content">
        <Row>
          <Col lg="12" md="12">
            <Card>
              {okD1(output) && okH1(output) && (
                <Row>
                  <Col xs="12" className="items-center">
                    <p style={{ paddingTop: 13 }}>GIORNALIERO</p>
                    <Switch
                      checkedd1h1={checkedd1h1 ?? false}
                      onChange={handleChange}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <p style={{ paddingTop: 13 }}>ORARIO</p>
                  </Col>
                </Row>
              )}
              {/* D1 */}
              {!checkedd1h1 && (
                <CardHeader>
                  <CardTitle tag="h2">ESITI GIORNALIERI</CardTitle>
                  <Row>
                    <Col lg="3" md="6">
                      <Row>
                        <Checkbox
                          defaultChecked
                          onClick={() =>
                            setFiltroAffidabilita(!filtroAffidabilita)
                          }
                        />
                        <Label style={{ marginTop: 12 }}>
                          Affidabilità e Profittabilità superiore al 50%
                        </Label>
                      </Row>
                      <Row>
                        <Checkbox
                          defaultChecked
                          onClick={() => setFiltroWeekly(!filtroWeekly)}
                        />
                        <Label style={{ marginTop: 12 }}>
                          Concordanti con la direzione settimanale
                        </Label>
                      </Row>
                      <Row>
                        <Checkbox
                          defaultChecked
                          onClick={() => setFiltroSbs(!filtroSbs)}
                        />
                        <Label style={{ marginTop: 12 }}>
                          Concordanti con lo Statistic Black Swan™
                        </Label>
                      </Row>
                      <Row>
                        <Checkbox
                          defaultChecked
                          onClick={() => setFiltroRoi(!filtroRoi)}
                        />
                        <Label style={{ marginTop: 12 }}>
                          ROI atteso superiore al 100%
                        </Label>
                      </Row>
                    </Col>
                    <Col lg="3" md="6">
                      <FormControl>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{ color: "darkGrey" }}
                        >
                          Ordina per
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="Affidabilità"
                            control={<Radio />}
                            label="Affidabilità"
                            onClick={() => setOrderType("Affidabilita")}
                            checked={orderType === "Affidabilita"}
                          />
                          <FormControlLabel
                            value="Roi atteso"
                            control={<Radio />}
                            label="Roi atteso"
                            onClick={() => setOrderType("Roi")}
                            checked={orderType === "Roi"}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                  </Row>
                </CardHeader>
              )}
              {/* H1 */}
              {checkedd1h1 && (
                <CardHeader>
                  <CardTitle tag="h2">
                    ESITI ORARI - ORARIO DI RIFERIMENTO: {moment().hour()}:00 -{" "}
                    {moment().add(1, "hours").hour()}:00
                  </CardTitle>
                  <h6>
                    Si consiglia di attendere 5 minuti all'inizio di ogni ora
                    per evitare qualsiasi mancato aggiornamenti degli esiti.
                  </h6>
                  <h6>
                    Nota: Per una strategia più conservativa, vi consigliamo di
                    posizionare un ordine pendente al "livello di ingresso"
                    indicato.
                    <br /> In alternativa, potete anche scegliere di entrare
                    direttamente a mercato seguendo le nostre indicazioni
                    operative.
                    <br /> Optate per l'approccio che meglio si allinea alla
                    vostra strategia di trading.
                  </h6>
                  <Row style={{ marginTop: 20 }}>
                    <Col lg="3" md="6">
                      <Row>
                        <Checkbox
                          defaultChecked
                          onClick={() =>
                            setFiltroAffidabilita(!filtroAffidabilita)
                          }
                        />
                        <Label style={{ marginTop: 12 }}>
                          Affidabilità superiore al 50%
                        </Label>
                      </Row>
                      <Row>
                        <Checkbox
                          defaultChecked
                          onClick={() =>
                            setFiltroProfittabilita(!filtroProfittabilita)
                          }
                        />
                        <Label style={{ marginTop: 12 }}>
                          Profittabilità superiore al 40%
                        </Label>
                      </Row>
                      {okD1(output) && (
                        <Row>
                          <Checkbox
                            onClick={() => setFiltroDaily(!filtroDaily)}
                          />
                          <Label style={{ marginTop: 12 }}>
                            Concordanti con il filtro Daily
                          </Label>
                        </Row>
                      )}
                    </Col>
                    <Col lg="3" md="6">
                      <FormControl>
                        <FormLabel
                          id="demo-radio-buttons-group-label"
                          style={{ color: "darkGrey" }}
                        >
                          Ordinati per
                        </FormLabel>
                        <RadioGroup
                          aria-labelledby="demo-radio-buttons-group-label"
                          defaultValue="female"
                          name="radio-buttons-group"
                        >
                          <FormControlLabel
                            value="Affidabilità"
                            control={<Radio />}
                            label="Affidabilità"
                            onClick={() => setOrderType("Affidabilita")}
                            checked={orderType === "Affidabilita"}
                          />
                          <FormControlLabel
                            value="Profittabilità"
                            control={<Radio />}
                            label="Profittabilità"
                            onClick={() => setOrderType("Profittabilita")}
                            checked={orderType === "Profittabilita"}
                          />
                        </RadioGroup>
                      </FormControl>
                    </Col>
                  </Row>
                </CardHeader>
              )}
            </Card>
          </Col>
        </Row>

        {/* D1 */}
        {!checkedd1h1 && (
          <Row>
            {output.strumenti
              .filter(x =>
                checkFromIdStrumento(x.idStrumento.toLowerCase(), user)
              )
              .filter(x => checkboxFiltroAffidabilita(x, filtroAffidabilita))
              .filter(x => checkboxFiltroWeekly(x, filtroWeekly))
              .filter(x => checkboxFiltroSbs(x, filtroSbs))
              .filter(x => checkboxFiltroRoi(x, filtroRoi, 1))
              .sort((x1, x2) =>
                orderType === "Affidabilita"
                  ? x2.affidabilita - x1.affidabilita
                  : x2.roi - x1.roi
              )
              .map(x => {
                return (
                  <Col lg="12" md="6" sm="12" key={x.idStrumento}>
                    <StrumentoFrontCard output={x} />
                  </Col>
                );
              })}
          </Row>
        )}

        {/* H1 */}
        {checkedd1h1 && (
          <Row>
            {output.strumentiH1
              .filter(x => x.hour === moment().hour())
              .filter(x =>
                checkFromIdStrumentoH1(x.idStrumento.toLowerCase(), user)
              )
              .filter(x => checkboxFiltroAffidabilita(x, filtroAffidabilita))
              .filter(x =>
                checkboxFiltroProfittabilita(x, filtroProfittabilita)
              )
              .filter(x =>
                checkboxFiltroHourDaily(
                  filtroDaily,
                  output?.strumenti?.filter(
                    d => d.idStrumento === x.idStrumento
                  )[0],
                  x
                )
              )
              .sort((x1, x2) =>
                orderType === "Affidabilita"
                  ? x2.affidabilita - x1.affidabilita
                  : x2.profittabilita - x1.profittabilita
              )
              .map(x => {
                return (
                  <Col lg="12" md="6" sm="12" key={x.idStrumento}>
                    <StrumentoFrontCardH1 output={x} user={user} />
                  </Col>
                );
              })}
          </Row>
        )}
      </div>
    </>
  );
}

export default Esiti;
