/** @format */

const SET_OUTPUT = "SET_OUTPUT";
const SET_OUTPUT_H1 = "SET_OUTPUT_H1";

const initialstate = {
  strumenti: {},
  strumentiH1: {},
};

export const setFinancialOutput = (state = initialstate, action) => {
  switch (action.type) {
    case SET_OUTPUT:
      return {
        ...state,
        strumenti: action.payload,
      };
    case SET_OUTPUT_H1:
      return {
        ...state,
        strumentiH1: action.payload,
      };
    default:
      return state;
  }
};
